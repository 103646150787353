import React from "react";
import ForexBrokerSpreads from "../containers/ForexBrokerSpreads";
import { SpreadCalculationInfo } from "../containers/SpreadCalculationInfo";
import SpreadInForex from "../containers/SpreadInForex";
import SpreadsAffect from "../containers/SpreadsAffect";
import WhatIsSpread from "../containers/WhatIsSpread";
import {HighAndLowForexSpread}  from "../containers/HighAndLowForexSpread";
import { LowForexSpread } from "../containers/LowForexSpread";
import { MonstradeForexTradingAccount } from "../containers/MonstradeForexTradingAccount";
import { EcnCommissionCharges } from "../containers/EcnCommissionCharges";
import { ForexSpreadsFAQ } from "../containers/ForexSpreadsFAQ";
import { ForexSpreadSearch } from "../containers/ForexSpreadSearch";
import { StartTrading } from "../containers/StartTrading";
//component
import { PricingContainer } from "../components/Containers/PricingContainer";
//images
import bgImage from "../assets/images/banners/spreads-banner.png"
import indicesIcon from '../assets/images/indices.svg'
import forexIcon from '../assets/images/forex-icon.svg'
import cryptoIcon from '../assets/images/cryptocurrencies-icon.svg'
import comonditiesIcon from '../assets/images/comondities-icon.png'
import metalsIcon from '../assets/images/metals-icon.svg'
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";

const Spreads = () => {
    const defaultStyle = {
        composition: {
            margin: "center",
            alignment : "center",
            lgW: "lg:w-full"
        },
        header : {
            size: "5"
        },
        text : {

        },
        container: {
            h : "md:h-[46.5vw]",
            maxH : "max-h-[474px]"
        },
        image: {

        },
        button: {
            color: "white",
            textColor: "monstrade-blue",
            border: true,
            borderColor: "monstrade-blue"
        }
    }
    const content = {
        header : "Forex Spreads",
        text : "A forex spread is the difference between the bid price and ask price. The spread cost is measured in 'pips'\
        and is the cost of trading. Popular currency pairs such as the EUR/GBP and AUD/USD have lower spreads as a result\
        of higher levels of liquidity. Monstrade offers you among the lowest spreads in the market",
        button : "Start Trading"
    }

    const cards = [
        { header: 'Indices', icon: indicesIcon, link: '', btnText : 'VIEW SPREADS' },
        { header: 'Forex', icon: forexIcon, link: '', btnText : 'VIEW SPREADS' },
        { header: 'Cryptocurrencies', icon: cryptoIcon, link: '', btnText : 'VIEW SPREADS' },
        { header: 'Commodities', icon: comonditiesIcon, link: '', btnText : 'VIEW SPREADS' },
        { header: 'Metals', icon: metalsIcon, link: '', btnText : 'VIEW SPREADS' }
    ]

    return (
        <>
            <PricingContainer 
                image={bgImage} 
                defaultStyle={defaultStyle} 
                content={content}/>
            <SimpleCardListContainer 
                cards={cards}
                header={'Click on a Product to View Our Typical Spreads'} />
            <SpreadsAffect />
            <WhatIsSpread />
            <SpreadInForex />
            {/* <ForexBrokerSpreads /> */}
            <SpreadCalculationInfo />
            <HighAndLowForexSpread />
            <LowForexSpread />
            <MonstradeForexTradingAccount />
            <EcnCommissionCharges />
            <ForexSpreadsFAQ />
            <ForexSpreadSearch />
            <StartTrading />
        </>
    )
}

export default Spreads;