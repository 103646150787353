import React from "react";

const CashbackTerms = () => {
    return(
        <>
        <div className="p-8">
            <div>
                <p className="text-lg font-bold text-lime-500">Cashback Terms:</p>
            </div>
            <br/>
            <ul className="list-disc font-light">
                <li>Only Standard and Fixed USD and EUR accounts in the MT5 platforms can participate in the loyalty program.</li>
                <li>Cashback is calculated based on the trading activity in currency pairs and metals.</li>
                <li>To stay at the first level of the program, you need to make a trading turnover of two lots within 30 days.</li>
                <li>The position holding time for each trade should be at least 3 minutes.</li>
                <li>The price change in each trade should be at least 10 pips.</li>
                <li>The cashback is credited daily to the trading account participating in the loyalty program in the form of a trading bonus after the position is closed. Cashback accrual for the current trading session occurs the next day before 17:00 EET (trading terminal time). Please note that on MT5 trading accounts, cashback is credited to the “Credit” line, while on MT5 trading accounts, cashback is credited to the “Balance” line.</li>
                <li>To advance to the next level, you need to reach a required trading volume of 5 lots within 30 days.</li>
                <li>If a Client withdraws funds from his trading account or fails to achieve the required trading volume during the period of 30 days, the progress will be lost, and the account will return to level 1.</li>
                <li>The minimum monthly trading turnover for a subscription to the first level is 2 lots.</li>
            </ul>
        </div>
        </>
    )
}

export default CashbackTerms;