import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import Table from "../components/Table"
import PhoneMockup from '../components/PhoneMockup'
import AvailableAccountTypes from "../containers/AvailableAccountTypes";
import Faq from "../components/Faq"
import Security from "../components/Security";
import MetalsImg from "../assets/images/metals_img.png"
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import WalletIco from "../assets/icons/wallet2.svg"


const mainTopic = "Start investing in Metals"
const subText = "Trade Gold (XAU/USD), Silver (XAG/USD), and ETFs on gold, palladium, platinum, and silver."

const topic1 = "Fast order execution from 0.1 seconds."
const topic2 = "Low spreads"
const topic3 = "Start trading with 10 USD"

const text1 = "Highest order execution speed, from just 0.1 seconds."
const text2 = "Trading with lowest spreads from 0 pips."
const text3 = "You can invest with only 10 USD on your trading account."

const faqTitle1 = "What is metal trading?"
const faqText1 = "Precious metals have always been important elements of "+
                "the financial system. One of the most valuable assets is Gold. "+
                "Investing in metals allows traders to hedge risks of the USD “weakness” "+
                "and possible political turmoil."+
                "Monstrade provides reliable access to trading XAU/USD and XAG/USD pairs through"+
                " its own terminal and MetaTrader 5 terminal. Using Monstrade, you have an opportunity "+
                "to invest in more than 10 ETFs on such metals as palladium, aluminum, copper, nickel and platinum."

const MetalsPage = () => {
    return(
        <>
            <TopBanner firstIcon={BalanceIco} secondIcon={ForexIco} thirdIcon={WalletIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} text1={text1} text2={text2} text3={text3} bgimage={MetalsImg}/> 
            <Button content="TRADE METALS" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={true} />
            <Table/>
            <PhoneMockup/>
            <AvailableAccountTypes/>
            {/* <Faq faqTitle1={faqTitle1} faqText1={faqText1} isTwoItems={false}/> */}
            {/* <Security/> */}
        </>
    )
}

export default MetalsPage;