import React from 'react'
import styles from './AffiliateHowItWork.module.css'
export const AffiliateHowItWork = () => {
    return (
        <div className='container-full' >
            <div className='container-max mx-auto text-center py-16' >
                <div className='mb-10' >
                    <h3 className='text-3xl' >How It Works</h3>
                </div>
                <div className='grid grid-cols-3 gap-24'>
                    <div>
                        <div className='border-2 border-mons-blue-1 rounded-full h-[75px] w-[75px] flex mx-auto mb-5' >
                            <p className='m-auto text-xl font-bold' >1</p>
                        </div>
                        <div>
                            <p className='mb-3 font-semibold text-lg' >A partnerrefers clients</p>
                            <p>
                                by any means and however he sees fit, using promo
                                materials privded by MonsTrade
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className='border-2 border-mons-blue-1 rounded-full h-[75px] w-[75px] flex mx-auto mb-5' >
                            <p className='m-auto text-xl font-bold' >2</p>
                        </div>
                        <div>
                            <p className='mb-3 font-semibold text-lg' >Clients deposit</p>
                            <p>
                                their accounts and start performing trading or investment operations
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className='border-2 border-mons-blue-1 rounded-full h-[75px] w-[75px] flex mx-auto mb-5' >
                            <p className='m-auto text-xl font-bold' >3</p>
                        </div>
                        <div>
                            <p className='mb-3 font-semibold text-lg' >The partner receives</p>
                            <p>
                                his remuneration based on the partnership model
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
