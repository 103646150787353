import React from 'react'

export const AccountTypeCompareCard = ({ item}) => {
    return (
        <div className='max-w-[255px] rounded-2xl overflow-hidden' >
            <div className='grid grid-cols-2 bg-[#292929] py-2 px-5'>
                <div className='flex' >
                    <p className='text-white my-auto font-semibold' >{item.header}</p>
                </div>
                <div className='justify-self-end' >
                    <div className='max-h-[29px]' >
                        <img src={item.icon} alt='monstrade' />
                    </div>
                </div>
            </div>
            <div className='bg-[#D9D9D9] px-5 py-3 text-xs grid grid-flow-row auto-rows-max gap-3 leading-5'>
                <div className=''>
                    <p>Min. Deposit</p>
                </div>
                <div>
                    <p className='text-4xl font-bold' >$ {item.minDeposit}</p>
                </div>
                <div className='font-light' >
                    <p>(USD)</p>
                </div>
                <div className='grid grid-cols-2 auto-rows-max gap-4'>
                    <div>
                        <p className='font-bold'>Max. Deposit</p>
                        <p className='font-light' >NA</p>
                    </div>
                    <div>
                        <p className='font-bold' >Min.Trade Size</p>
                        <p className='font-light' >0.01 lots</p>
                    </div>
                    <div>
                        <p className='font-bold'>Spreads</p>
                        <p className='font-light'>From 1.3 pips</p>
                    </div>
                    <div>
                        <p className='font-bold'>Leverage</p>
                        <p className='font-light' >Up to 500</p>
                    </div>
                    <div>
                        <p className='font-bold'>Platform</p>
                        <p className='font-light' >MT5</p>
                    </div>
                    <div>
                        <p className='font-bold' >Commision</p>
                        <p className='font-light' >0</p>
                    </div>
                </div>
                <div>
                    <p className='font-bold' >Base Currency</p>
                    <p>USD, GBP, CAD, AUD, EUR, SGD, NZD, HKD, JPY</p>
                </div>
                <div>
                    <p className='font-bold' >What can you trade</p>
                    <p>Forex, Indices, Spot Metals, Commodities, Shares, Crypto</p>
                </div>
                <div>
                    <p className='font-bold' >Other features</p>
                    <ul className='list-disc' >
                        <li>Expert Advisors (EA) trading tool</li>
                        <li>Negative balance protection</li>
                        <li>Autochartist</li>
                        <li>Trading signals</li>
                        <li>Daily news update</li>
                        <li>Market analysis (daily live-broadcast)</li>
                        <li>Economic calendar</li>
                    </ul>
                </div>
                <div className='flex' >
                    <a href='/open-account' className='border-2 border-mons-blue-1 rounded-full 
                                px-6 py-2
                                text-base font-bold text-monstrade-blue m-auto
                                hover:bg-mons-blue-1
                                hover:text-white
                                ease-in duration-300'>
                        Open Account
                    </a>
                </div>
            </div>
        </div>
    )
}
