import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const TweetCard = ({ item: item }) => {

    return (
        <div className="border-b-2 px-2 py-1 mb-2" >
            <div className="flex relative" >
                <div className="mr-2" >
                    <div className="max-w-[40px] rounded-full" >
                        <img src="https://pbs.twimg.com/profile_images/1593961796959870976/t3wL4JHp_400x400.jpg" alt="" />
                    </div>
                </div>
                <div>
                    <div>
                        <p className="font-bold text-sm" >{item.userName}</p></div>
                    <div>
                        <p className="text-xs text-gray-500" >@{item.account} - {item.date}</p></div>
                </div>
                <div className="absolute right-0" >
                    <div>
                        <FontAwesomeIcon icon={["fab", "twitter"]} size="lg" className="text-[#2c88d1]" />
                    </div>
                </div>
            </div>
            <div>
                <div className="mb-2" >
                    <p className="text-sm text-gray-500" >{item.text}</p>
                </div>
            </div>
            <div>
                <div className="" >
                    <img src="https://pbs.twimg.com/card_img/1590626474977300480/KjMm_-Vz?format=jpg&name=small" alt="" />
                </div>
            </div>
            <div className="bg-gray-100 py-3" >
                <div className="" >
                    <p className="text-xs text-gray-500" >{item.source}</p>
                </div>
                <div className="" >
                    <p className="text-sm text-gray-500" >{item.text}</p>
                </div>
            </div>
            <div className="flex relative py-3" >
                <div className="mr-2">
                    <p className="text-gray-400 text-sm" >
                        <FontAwesomeIcon icon="fa-solid fa-comment" className="mr-1" />
                        {item.like}
                    </p>
                </div>
                <div className="mr-2">
                    <p className="text-gray-400 text-sm" >
                    <FontAwesomeIcon icon="fa-solid fa-retweet" className="mr-1" />
                        {item.like}
                    </p>
                </div>
                <div className="mr-2">
                    <p className="text-gray-400 text-sm" >
                        <FontAwesomeIcon icon="fa-solid fa-heart" className="mr-1" />
                        {item.like}
                    </p>
                </div>
                <div className="absolute right-0" >
                    <FontAwesomeIcon icon="fa-solid fa-circle-info" className="text-gray-400" />
                </div>
            </div>
        </div>
    )
}

export default TweetCard;