import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
//images
import deviceImgPc from '../assets/images/device-pc.png'
import deviceImgMac from '../assets/images/device-mac.png'
import deviceImgWeb from '../assets/images/device-web.png'
import deviceImgIpad from '../assets/images/device-ipad.png'
import deviceImgTablet from '../assets/images/device-tablet.png'
import deviceImgIphone from '../assets/images/device-iphone.png'
import deviceImgAndroid from '../assets/images/device-android.png'

export const PlatformDeviceContainer = () => {
    return (
        <div className='container-full' >
            <div className='container-max mx-auto'>
                <div className='flex justify-center my-10' >
                    <div className='mr-4' >
                        <a href='/open-account' className='bg-monstrade-green px-4 py-2 rounded text-white font-semibold'>Open Real Account</a>
                    </div>
                    <div>
                        <a href='/open-account' className='bg-monstrade-blue px-4 py-2 rounded text-white font-semibold'  >Open Demo Account</a>
                    </div>
                </div>
                <div className='grid grid-cols-3 my-20 gap-10'>
                    <div className='border-r-[1px] px-4'>
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgPc} alt="" className='max-h-[100%]' />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3'>MT5 For Pc</h3>
                            <div className='mb-2' >
                                <FontAwesomeIcon icon="fa-brands fa-windows" className='text-mons-gray-4' size='lg' />
                            </div>
                            <p className='text-sm mb-2' >Turn your Android device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2'>
                                <a href='/open-account' className='bg-mons-blue-1 text-white font-semibold py-2 rounded text-center '>START NOW</a>
                                <a href='/' className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >DOWNLOAD</a>
                            </div>
                        </div>
                    </div>
                    <div className='border-r-[1px] px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgMac} alt="" className='max-h-[100%]' />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3'>MT5 For Mac</h3>
                            <div className='mb-2' >
                                <FontAwesomeIcon icon="fa-brands fa-apple" className='text-mons-gray-4' size='lg' />
                            </div>
                            <p className='text-sm mb-2' >Turn your Android device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2'>
                                <a href='/open-account' className='bg-mons-blue-1 text-white font-semibold py-2 rounded text-center '>START NOW</a>
                                <a href='/' className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >DOWNLOAD</a>
                            </div>
                        </div>
                    </div>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgWeb} alt="" className='max-h-[100%]' />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3'>MT5 For Web Trader</h3>
                            <div className='mb-2' >
                                <FontAwesomeIcon icon="fa-solid fa-globe" className='text-mons-gray-4 mr-2' />
                                <FontAwesomeIcon icon="fa-brands fa-chrome" className='text-mons-gray-4 mr-2' />
                                <FontAwesomeIcon icon="fa-brands fa-internet-explorer" className='text-mons-gray-4' />
                            </div>
                            <p className='text-sm mb-2' >Turn your Android device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2'>
                                <a href='/open-account' className='bg-mons-blue-1 text-white font-semibold py-2 rounded text-center '>START NOW</a>
                                <a href='/' className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >DOWNLOAD</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 px-24 gap-10 py-10'>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgIpad} alt="" />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3'>MT5 For Ipad</h3>
                            <div className='mb-2' >
                                <FontAwesomeIcon icon="fa-brands fa-apple" className='text-mons-gray-4' size='lg' />
                            </div>
                            <p className='text-sm mb-2' >Turn your Android device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2'>
                                <a href='/open-account' className='bg-mons-blue-1 text-white font-semibold py-2 rounded text-center '>START NOW</a>
                                <a href='/' className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >DOWNLOAD</a>
                            </div>
                        </div>
                    </div>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgTablet} alt="" />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3'>MT5 For Tablet</h3>
                            <div className='mb-2' >
                                <FontAwesomeIcon icon="fa-brands fa-android" className='text-mons-gray-4' size='lg' />
                            </div>
                            <p className='text-sm mb-2' >Turn your Android device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2'>
                                <a href='/open-account' className='bg-mons-blue-1 text-white font-semibold py-2 rounded text-center '>START NOW</a>
                                <a href='/' className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >DOWNLOAD</a>
                            </div>
                        </div>
                    </div>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgIphone} alt="" />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3'>MT5 For Iphone</h3>
                            <div className='mb-2' >
                                <FontAwesomeIcon icon="fa-brands fa-apple" className='text-mons-gray-4' size='lg' />
                            </div>
                            <p className='text-sm mb-2' >Turn your Android device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2'>
                                <a href='/open-account' className='bg-mons-blue-1 text-white font-semibold py-2 rounded text-center '>START NOW</a>
                                <a href='/' className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >DOWNLOAD</a>
                            </div>
                        </div>
                    </div>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgAndroid} alt="" />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3'>MT5 For Android</h3>
                            <div className='mb-2' >
                                <FontAwesomeIcon icon="fa-brands fa-android" className='text-mons-gray-4' size='lg' />
                            </div>
                            <p className='text-sm mb-2' >Turn your Android device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2'>
                                <a href='/open-account' className='bg-mons-blue-1 text-white font-semibold py-2 rounded text-center '>START NOW</a>
                                <a href='/' className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >DOWNLOAD</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
