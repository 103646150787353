import React from 'react'

export const FixedHeaderContainer = ({children : children}) => {
  return (
    <div className='container-full fixed z-[70]' >
      <div className='relative' >
        {children}
      </div>
    </div>
  )
}
