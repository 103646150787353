import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import AccountTypeCard from "../Cards/AccountTypeCard";

const defaultStyle = [
    {
        marginLeft: "-55%",
        zIndex: "2",
        scale: "0.8",
        transitionDuration: "2s",
        transitionProperty: "margin-left scale"
    },
    {
        zIndex: "3",
        scale: "1",
        transitionDuration: "2s",
        transitionProperty: "margin-left scale"
    },
    {
        marginRight: "-55%",
        zIndex: "1",
        scale: "0.8",
        transitionDuration: "2s",
        transitionProperty: "margin-left scale"
    }
]

const AccountTypeCardSlider = ({ accountTypes: accountTypes }) => {
    const [styles, setStyles] = useState(defaultStyle)
    const [selectedItem, setSelectedItem] = useState(1)

    const selectHandler = (id) => {
        setSelectedItem(id)
    }

    useEffect(() => {
        if (selectedItem < 1) {
            setStyles([
                {
                    zIndex: "3",
                    marginLeft: "0",
                    scale: "1",
                    transitionDuration: "2s",
                    transitionProperty: "margin-left scale"
                },
                {
                    marginRight: "-55%",
                    zIndex: "2",
                    scale: "0.8",
                    transitionDuration: "2s",
                    transitionProperty: "margin-right scale"
                },
                {
                    marginLeft: "-55%",
                    zIndex: "1",
                    scale: "0.8",
                    transitionDuration: "2s",
                    transitionProperty: "margin-left scale"
                },
            ])
        }
        if (selectedItem > 1) {
            setStyles([
                {
                    marginRight: "-55%",
                    scale: "0.8",
                    zIndex: "2",
                    transitionDuration: "2s",
                    transitionProperty: "margin-left scale"
                },
                {
                    marginLeft: "-55%",
                    scale: "0.8",
                    zIndex: "1",
                    transitionDuration: "2s",
                    transitionProperty: "margin-left scale"
                },
                {
                    zIndex: "3",
                    marginLeft: "0",
                    transitionDuration: "2s",
                    transitionProperty: "margin-left scale"
                },
            ])
        }
        if (selectedItem === 1) {
            setStyles(defaultStyle)
        }
    }, [selectedItem])

    return (
        <>
            <div className='md:hidden flex' >
                <div className='mx-auto flex text-gray-500' >
                    <div className='mr-5 mb-5' >
                        <FontAwesomeIcon icon="fa-solid fa-chevron-left" size='lg' onClick={() => { selectHandler(selectedItem > 0 ? selectedItem - 1 : 2) }} />
                    </div>
                    <div>
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" size='lg' onClick={() => { selectHandler(selectedItem > 1 ? 0 : selectedItem + 1 ) }}  />
                    </div>
                </div>
            </div>
            <div className='flex h-[486px] w-full relative justify-center' >
                {
                    accountTypes.map((item, index) => {
                        return (
                            <AccountTypeCard item={item} styles={styles[index]} selectHandler={selectHandler} key={item.id} />
                        )
                    })
                }
            </div>
        </>
    )
}

export default AccountTypeCardSlider;