

const menuContent = () => {
    return (
        [
            {   id: 0, 
                name: 'Trading',
                header: 'TRADING',
                image: '', icon:'',
                description: `At Monstrade we offer both Micro and Standard Accounts \
                                            that can match the needs of novice and experienced\
                                            traders with flexible trading conditions and leverage\
                                            up to 1000:1. <br><br> \
                                            We offer a range of over 50 currency \
                                            pairs and CFDs on cryptocurrencies, precious metals,\
                                            energies and equity indices, with the most competitive\
                                            spreads and with the no rejection of orders and re-quotes\
                                            execution of Monstrade. <br><br>\
                                            Risk Warning: Trading on margin products\
                                            involves a high level of risk.`,
                status : true, link: '/trading', subItems : [
                    {   id: 6, 
                        name: 'TRADING INSTRUMENTS',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/trading/trading-instrument', subItems: [
                            {   id: 7, 
                                name: 'Forex',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/forex', subItems: [
                                    
                                ]
                            },
                            {   id: 8, 
                                name: 'Indices',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/indices', subItems: [
                                    
                                ]
                            },
                            {   id: 9, 
                                name: 'Metals',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/metals', subItems: [
                                    
                                ]
                            },
                            {   id: 10, 
                                name: 'Energies',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/energies', subItems: [
                                    
                                ]
                            },    
                            {   id: 11, 
                                name: 'Cryptocurrencies',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/crypto', subItems: [
                                    
                                ]
                            },  
                            {   id: 12, 
                                name: 'Stocks',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-instrument/stocks', subItems: [
                                    
                                ]
                            },                  
                        ]
                    },
                    {   id: 13, 
                        name: 'TRADING TOOLS',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/trading/trading-tools', subItems: [
                            {   id: 15, 
                                name: 'Economic Calendar',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-tools/economic-calender', subItems: [
                                    
                                ]
                            },
                            {   id: 16, 
                                name: 'Forex News',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-tools/forex-news', subItems: [
                                    
                                ]
                            },
                            {   id: 17, 
                                name: 'Leverage',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-tools/leverage', subItems: [
                                    
                                ]
                            },
                            {   id: 18, 
                                name: 'Calculator',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/trading-tools/calculator', subItems: [
                                    
                                ]
                            }                
                        ]
                    },
                    {   id: 14, 
                        name: 'PRICING',
                        image: '', icon:'', 
                        description: '',
                        status : true, link: '/trading/pricing', subItems: [
                            {   id: 21, 
                                name: 'Spreads',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/pricing/spreads', subItems: [
                                    
                                ]
                            },
                            {   id: 22, 
                                name: 'Swap Rates',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/pricing/swap-rates', subItems: [
                                    
                                ]
                            },
                            {   id: 23, 
                                name: 'Trading Hours',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/pricing/trading-hours', subItems: [
                                    
                                ]
                            },
                            {   id: 24, 
                                name: 'Holiday Trading Hours',
                                image: '', icon:'', 
                                description: '',
                                status : true, link: '/trading/pricing/holiday-trading-hours', subItems: [
                                    
                                ]
                            }                
                        ]
                    }
                ]},
                {   id: 1, 
                    name: 'Accounts',
                    header: 'ACCOUNTS',
                    image: '', icon:'',
                    description: `At Monstrade we offer both Micro and Standard Accounts that \
                    can match the needs of novice and experienced traders with flexible \
                    trading conditions and leverage up to 1000:1.<br><br>\
                    We offer a range of over 50 currency pairs and CFDs on cryptocurrencies, \
                    precious metals, energies and equity indices, with the most competitive \
                    spreads and with the no rejection of orders and re-quotes execution of \
                    Monstrade.<br><br>\
                    Risk Warning: Trading on margin products involves a high level of risk.`,
                    status : true, link: '/accounts', subItems : [
                        {   id: 25, 
                            name: 'TRADING',
                            image: '', icon:'', 
                            description: '',
                            status : true, link: '/accounts/trading', subItems: [
                                {   id: 26, 
                                    name: 'Demo Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/demo-account', subItems: [
                                        
                                    ]
                                },
                                {   id: 27, 
                                    name: 'Cent Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/cent-account', subItems: [
                                        
                                    ]
                                },
                                {   id: 28, 
                                    name: 'Classic Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/classic-account', subItems: [
                                        
                                    ]
                                },
                                {   id: 29, 
                                    name: 'Prime Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/prime-account', subItems: [
                                        
                                    ]
                                },    
                                {   id: 30, 
                                    name: 'ECN Account',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/ecn-account', subItems: [
                                        
                                    ]
                                },  
                                {   id: 31, 
                                    name: 'Islamic Account ',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/trading/islamic-account', subItems: [
                                        
                                    ]
                                },                  
                            ]
                        },
                        {   id: 32, 
                            name: 'EDUCATION',
                            image: '', icon:'', 
                            description: '',
                            status : true, link: '/accounts/education', subItems: [
                                {   id: 33, 
                                    name: 'Video Tutorials',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/education/video-tutorials', subItems: [
                                        
                                    ]
                                },
                                {   id: 34, 
                                    name: 'E-book',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/accounts/education/e-books', subItems: [
                                        
                                    ]
                                }             
                            ]
                        }
                    ]},
                    {   id: 2, 
                        name: 'Platforms',
                        header: 'MY APP',
                        image: '', 
                        icon:'BeakerIcon',
                        description: `MT5 Compatible <br><br>\
                        Trade on the go with thenew all-in-one Monstrade Mobile App`,
                        status : true, link: '/platforms', subItems : [
                            {   id: 35, 
                                name: 'COMPUTER',
                                image: '', 
                                icon:'faDisplay', 
                                description: '',
                                status : true, link: '/platforms/computer', subItems: [
                                    {   id: 36, 
                                        name: 'MT 5 for PC',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/computer/mt5-for-pc', subItems: [
                                            
                                        ]
                                    },
                                    {   id: 37, 
                                        name: 'MT5 for Mac',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/computer/mt5-for-mac', subItems: [
                                            
                                        ]
                                    },
                                    {   id: 38, 
                                        name: 'MT5 Web Trader',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/computer/mt5-for-web', subItems: [
                                            
                                        ]
                                    }              
                                ]
                            },
                            {   id: 39, 
                                name: 'PHONE',
                                image: '', 
                                icon:'faMobileButton',
                                description: '',
                                status : true, link: '/platforms/phone', subItems: [
                                    {   id: 40, 
                                        name: 'MT 5 for Apple',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/phone/mt5-for-apple', subItems: [
                                            
                                        ]
                                    },
                                    {   id: 41, 
                                        name: 'MT5 for Android',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/phone/mt5-for-android', subItems: [
                                            
                                        ]
                                    }             
                                ]
                            },
                            {   id: 42, 
                                name: 'TABLET',
                                image: '', 
                                icon:'faTabletButton', 
                                description: '',
                                status : true, link: '/platforms/tablet', subItems: [
                                    {   id: 43, 
                                        name: 'MT 5 for Ipad',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/tablet/mt5-for-ipad', subItems: [
                                            
                                        ]
                                    },
                                    {   id: 44, 
                                        name: 'MT5 for Android',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/platforms/tablet/mt5-for-android', subItems: [
                                            
                                        ]
                                    }             
                                ]
                            }
                        ]},
                        {   id: 3, 
                            name: 'Promotion',
                            header: 'PROMOTIONS',
                            image: '', icon:'',
                            description: `At Monstrade we offer both Micro and Standard Accounts that \
                            can match the needs of novice and experienced traders with flexible \
                            trading conditions and leverage up to 1000:1.<br><br>\
                            We offer a range of over 50 currency pairs and CFDs on cryptocurrencies, \
                            precious metals, energies and equity indices, with the most competitive \
                            spreads and with the no rejection of orders and re-quotes execution of \
                            Monstrade.<br><br>\
                            Risk Warning: Trading on margin products involves a high level of risk.`,
                            status : true, link: '/', subItems : [
                                {   id: 45, 
                                    name: 'BONUSES',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/bonuses', subItems: [
                                        {   id: 46, 
                                            name: 'Cashback',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/bonuses/cashback', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 47, 
                                            name: 'Trading Bonus',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/bonuses/trading-bonus', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 48, 
                                            name: 'Broker Bonus',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/bonuses/broker-bonus', subItems: [
                                                
                                            ]
                                        }              
                                    ]
                                },
                                {   id: 49, 
                                    name: 'PROMOTIONS',
                                    image: '', icon:'', 
                                    description: '',
                                    status : true, link: '/promotions', subItems: [
                                        {   id: 50, 
                                            name: 'Zero Commission',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/zero-commission', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 51, 
                                            name: 'Trade Demo',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/trade-demo', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 52, 
                                            name: 'Invite a  Friend',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/invite-a-friend', subItems: [
                                                
                                            ]
                                        },
                                        {   id: 53, 
                                            name: 'Account Transfer',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/account-transfer', subItems: [
                                                
                                            ]
                                        } ,
                                        {   id: 66, 
                                            name: 'VIP',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/promotions/vip', subItems: [
                                                
                                            ]
                                        }               
                                    ]
                                }
                            ]},
                            {   id: 4, 
                                name: 'About',
                                header: 'ABOUT US',
                                image: '', icon:'',
                                description: `At Monstrade we offer both Micro and Standard Accounts that \
                                can match the needs of novice and experienced traders with flexible \
                                trading conditions and leverage up to 1000:1.<br><br>\
                                We offer a range of over 50 currency pairs and CFDs on cryptocurrencies, \
                                precious metals, energies and equity indices, with the most competitive \
                                spreads and with the no rejection of orders and re-quotes execution of \
                                Monstrade.<br><br>\
                                Risk Warning: Trading on margin products involves a high level of risk.`,
                                status : true, link: '/about', subItems : [
                                    {   id: 54, 
                                        name: 'COMPANY',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/about/company', subItems: [
                                            {   id: 55, 
                                                name: 'About Us',
                                                image: '', icon:'', 
                                                description: '',
                                                status : true, link: '/about/company/about-us', subItems: [
                                                    
                                                ]
                                            },
                                            {   id: 56, 
                                                name: 'License and Registration',
                                                image: '', icon:'', 
                                                description: '',
                                                status : false, link: '/about/company/license-and-registration', subItems: [
                                                    
                                                ]
                                            },
                                            {   id: 57, 
                                                name: 'Documents',
                                                image: '', icon:'', 
                                                description: '',
                                                status : false, link: '/about/company/documentation', subItems: [
                                                    
                                                ]
                                            }              
                                        ]
                                    },
                                    {   id: 58, 
                                        name: 'CONTACT US',
                                        image: '', icon:'', 
                                        description: '',
                                        status : true, link: '/contact-us', subItems: [
                                            {   id: 59, 
                                                name: 'Live Support',
                                                image: '', icon:'', 
                                                description: '',
                                                status : false, link: '/', subItems: [
                                                    
                                                ]
                                            },
                                            {   id: 60, 
                                                name: 'FAQ',
                                                image: '', icon:'', 
                                                description: '',
                                                status : false, link: '/about/contact-us/faq', subItems: [
                                                    
                                                ]
                                            },
                                            {   id: 61, 
                                                name: 'Contact Us',
                                                image: '', icon:'', 
                                                description: '',
                                                status : true, link: '/about/contact-us/contact', subItems: [
                                                    
                                                ]
                                            }              
                                        ]
                                    }
                                ]},
                                {   id: 5, 
                                    name: 'Partnerships',
                                    header: 'PARTNERSHIPS',
                                    image: '', icon:'',
                                    description: `Earn money by choosing to partner with us. \
                                                    Choose the option that’s right for you.`,
                                    status : true, link: '/partnerships', subItems : [
                                        {   id: 62, 
                                            name: 'FOR PARTNERS',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/partnerships/for-partners', subItems: [
                                                {   id: 63, 
                                                    name: 'Partner',
                                                    image: '', icon:'', 
                                                    description: '',
                                                    status : true, link: '/partnerships/for-partners/partner', subItems: [
                                                        
                                                    ]
                                                }           
                                            ]
                                        },
                                        {   id: 64, 
                                            name: 'SERVICES',
                                            image: '', icon:'', 
                                            description: '',
                                            status : true, link: '/partnerships/services', subItems: [
                                                {   id: 65, 
                                                    name: 'IB Commission',
                                                    image: '', icon:'', 
                                                    description: '',
                                                    status : true, link: '/partnerships/services/ib-commission', subItems: [
                                                        
                                                    ]
                                                }          
                                            ]
                                        }
                                    ]}
        ]
    )
}

export default menuContent;