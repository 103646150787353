import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import image from '../assets/images/banners/ib-commission-banner.png';
import { PartnerReceive } from '../containers/PartnerReceive';
import { LoyalityProgram } from '../containers/LoyalityProgram';
import { AffiliateCommissionCalculatorContainer } from '../containers/AffiliateCommissionCalculatorContainer';
import { AffiliateAccounts } from '../containers/AffiliateAccounts';
import { MonstradePartneredReceive } from '../containers/MonstradePartneredReceive';

export const IBCommission = () => {
    const landing = {
        h1 : 'Affiliate commission',
        p: 'Receive affiliate commission every day without any limitations on maximum payouts.',
        btn : {
            text : 'Beacome a Partner',
            link : '/'
        }
    }
    return (
    <div>
        <ContainerWithBGImage image={image} landing={landing} itemAlingment={'left'} textAlingment={'left'} />
        <PartnerReceive />
        <LoyalityProgram />
        <AffiliateCommissionCalculatorContainer />
        <AffiliateAccounts />
        <MonstradePartneredReceive />
    </div>
  )
}
