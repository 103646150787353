import React from "react";
import TopBanner from "../components/TopBanner";
import IndicesImg from "../assets/images/indices_img.png"
import Button from "../components/Button"
import Table from "../components/Table"
import IndicesBanner from "../components/IndicesBanner";
import TextArea from "../components/TextArea";
import PhoneMockup from '../components/PhoneMockup'
import StockContentTable from "../components/StockContentTable" 
import Security from "../components/Security";
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import HourglassIco from "../assets/icons/hourglass.svg"

const mainTopic = "Indices trading Unique conditions"
const subText = "Invest in indices with tight spreads and commission of 4 USD for the trading volume of 1 million USD."

const text1 = "Fast order execution from 0.1 seconds."
const text2 = "Average spread for DE40Cash on ECN and Prime accounts – 0.5 EUR."
const text3 = "Minimum lot and step of trading volume for all indices – 0.01."

const textAreaTitle = "What is indices trading?"
const textAreaContent = "Indices are trading tools, which consist of a group of bonds and show changes"+
                        " in their prices. For example, an index includes several stocks and its price "+
                        "is calculated based on the total cost of bonds or capitalization of issuers. A "+
                        "set of bonds, which the index consists of, shows what information relating to the "+
                        "current economic situation may be received when analyzing it. Thanks to using indices "+
                        "in trading, traders have an opportunity to diversify their investment portfolio, collect "+
                        "and analyze information relating to the global economy or one of its sectors."+
                        "Monstrade offers its clients access to some of the best conditions for investing in indices in the industry."

const IndicesPage = () => {
    return(
        <>
            <TopBanner firstIcon={BalanceIco} secondIcon={ForexIco} thirdIcon={HourglassIco} mainTopic={mainTopic} subText={subText} text1={text1} text2={text2} text3={text3} bgimage={IndicesImg} /> 
            <Button content="TRADE INDICES" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={true} />
            <Table/>
            <IndicesBanner/>
            <TextArea textAreaTitle = {textAreaTitle} textAreaContent = {textAreaContent}/>
            <PhoneMockup/>
            <StockContentTable/>
            {/* <Security/> */}
        </>
    )
}

export default IndicesPage;