import React from 'react'
import { SimpleCard } from '../Cards/SimpleCard';

export const SimpleCardListContainer = ({ cards, header, text }) => {
    return (
        <>
            <div className="container-max block m-auto">
                {header &&
                    <div className="py-1 w-full" >
                        <h2 dangerouslySetInnerHTML={{__html : header}} className="md:text-3xl text-2xl text-center font-semibold " ></h2>
                    </div>
                }
                {text && 
                    <div className="w-full" >
                        <p dangerouslySetInnerHTML={{__html : text}} className='text-center text-xl font-light'></p>
                    </div>
                }
                <div className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-${cards.length} m-auto md:py-10 py-5 lg:gap-4 gap-2`}>
                    {
                        cards.map((item, index) => {
                            return (
                                <SimpleCard header={item.header}
                                    link={item.link}
                                    description={item.description}
                                    icon={item.icon}
                                    btnText={item.btnText}
                                    key={index} />
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
