import React from "react";

const LandingBannerEconomicCalender = ({ image: image, defaultStyles : defaultStyles}) => {
    return (
        <>
            <div className="w-full flex max-h-[514px] h-[100vw] md:h-[40.5vw] relative overflow-hidden" >
                <div className="absolute z-[-1] h-full" >
                    <img className="lg:w-full lg:min-w-full md:min-w-[120%] min-w-[250%]" src={image} alt="" />
                </div>
                <div className="container-max mx-auto flex text-center text-white">
                    <div className="lg:w-10/12  w-full my-auto mx-auto" >
                        <div className="mb-4 md:mb-12" >
                            <h1 className="xl:text-6xl lg:text-4xl md:text-2xl text-2xl font-bold" >Economic Calendar</h1>
                        </div>
                        <div className="mb-4 md:mb-12" >
                            <p className="lg:text-2xl md:text-xl" >
                                Economic events are a vital part of your trading plan and strategy. 
                                With Monstrade’s economic calendar, you can look ahead to see upcoming 
                                economic releases, reports, decisions and events that can potentially 
                                affect your trading or increase your opportunities.
                            </p>
                        </div>
                        <div>
                            <div>
                                <a href="/" className="bg-monstrade-green hover:bg-monstrade-blue text-white lg:py-4 lg:px-10 py-2 px-6 rounded lg:text-2xl md:text-xl font-semibold">Trade Economic Events</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingBannerEconomicCalender;