import React from "react";
import monstradeLogo from '../assets/images/monstrade-logo.png'
import menuContent from '../features/content/menu.content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const navigation = menuContent()

const socialMedias = [
    { logo: "instagram", link: "https://www.instagram.com/monstradefx/" },
    { logo: "youtube", link: "https://www.youtube.com/@monstradefx" },
    { logo: "twitter", link: "https://twitter.com/MonsTradeFX" },
]

const Footer = () => {
    return (
        <>
            <div className="container-full">
                <div className="container-max mx-auto">
                    <footer className=" text-left  text-gray-600">
                        <div className="md:mx-6 py-6 lgtext-center md:text-left">
                            <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-8 gap-1">
                                <div className="border-r-2 pr-2 md:col-span-2 col-span-3">
                                    <h6 className="uppercase font-semibold flex 
                                        items-center justify-center md:justify-start">
                                        <div>
                                            <img src={monstradeLogo} alt="" />
                                        </div>
                                    </h6>
                                    <p className="lg:mb-10 mb-2 text-center md:text-left md:text-md text-sm" >support@monstrade.com<br/><br/>+389 7226 7118</p>
                                    <p className="lg:mb-10 mb-2 text-center md:text-left md:text-md text-sm" >
                                        Str. Kozara No:68-2/6 1000 Skopje / North Macedonia
                                    </p>
                                    <div className="grid grid-cols-5 mb-3">
                                        {socialMedias.map((item, index) => {
                                            return (
                                                <div key={index} className="bg-monstrade-blue rounded-full w-[38px] h-[38px] flex" >
                                                    <a href={item.link} className="m-auto" >
                                                        <FontAwesomeIcon icon={["fab", item.logo]} size="lg" className="text-white" />
                                                    </a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {navigation.map((item, index) => {
                                    if (item.status === false) return <></>
                                    return (
                                        <div className="" key={index}>
                                            <h6 className="uppercase font-semibold mb-3 flex justify-center md:justify-start">
                                                {item.header}
                                            </h6>
                                            {item.subItems.map((subItem, sindex) => {
                                                if (subItem.status === false) return <></>
                                                return (
                                                    <div key={sindex} >
                                                        <p className="mb-1 text-gray-800 font-semibold text-sm">
                                                            {subItem.name.charAt(0).toUpperCase() + subItem.name.slice(1).toLowerCase()}

                                                        </p>
                                                        {subItem.subItems.map((subItem2, sindex2) => {
                                                            if (subItem2.status === false) return <></>
                                                            return (
                                                                <a key={sindex2} href={subItem2.link} className="text-gray-600 text-xs"> {subItem2.name} </a>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="text-center md:p-6">
                            <div className="flex text-xs" >
                                <div className="m-auto mb-3">
                                    <p className="text-xs pb-4">
                                        "Monstrade Dooel Skopje" does not issue advice, recommendations or opinions in relation to acquiring, holding or disposing of a CFD. "Monstrade Dooel Skopje" is not a financial advisor and all services are provided on an execution-only basis. This communication is not an offer or solicitation to enter into a transaction and shall not be construed as such.
                                        This website is not directed at any jurisdiction and is not intended for any use that would be contrary to local law or regulation. Services are not available for residents of USA, Canada, Iran, and North Korea
                                        By using "monstrade.com" you agree to use our cookies to enhance your experience.
                                        "Monstrade Dooel Skopje", all right reserved 2022.
                                        Registered and Operating from: Str. Kozara no.68/2-6 Skopje Center, Center North Macedonia
                                    </p>
                                    <a href="/privacy-policy" className="font-semibold md:px-3 px-1 border-r-[1px]" >Privacy Policy</a>
                                    <a href="/aml-policy" className="font-semibold md:px-3 px-1 border-r-[1px]" >AML Policy</a>
                                    <a href="/terms-and-conditions" className="font-semibold md:px-3 px-1 border-r-[1px]" >Terms&Conditions</a>
                                    <a href="/cookie-policy" className="font-semibold md:px-3 px-1" >Risk Warning</a>
                                </div>
                            </div>
                            <p className="text-sm text-gray-400 " href="https://monstrade.com/">©2022 This website is owned and operated by Monstrade Dooel Skopje.</p>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
}

export default Footer;