import React from "react";
import landingImage from '../assets/images/landing-img.png';

const Landingbanner = () => {
    return (
            <div className="w-full flex max-h-[666px] h-[100vw] md:h-[40.5vw] relative overflow-hidden" >
                <div className="absolute z-[-1] h-full" >
                    <img className="lg:w-full md:min-w-[120%] min-w-[250%]" src={landingImage} alt="" />
                </div>
                <div className="container-max mx-auto flex text-center md:text-left">
                    <div className="lg:w-5/12 md:w-6/12 w-8/12 my-auto mx-auto md:ml-0" >
                        <div className="mb-4 md:mb-12" >
                            <h1 className="xl:text-6xl lg:text-4xl md:text-2xl text-2xl font-bold" >Trade Hundred of Pairs</h1>
                        </div>
                        <div className="mb-4 md:mb-12" >
                            <p className="lg:text-2xl md:text-xl" >Trade many types of assets such as commodities, bonds, metals, digital currencies on Monstrade!</p>
                        </div>
                        <div>
                            <div>
                                <a href="/open-account" className="bg-monstrade-blue hover:bg-monstrade-green text-white py-2 px-4 rounded-xl lg:text-3xl md:text-xl font-semibold">Let’s Start</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Landingbanner;