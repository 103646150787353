import React from "react";
import ContentLeverage from "../containers/Content.Leverage";
import LandingBannerLeverage from "../containers/LandingBanner.Leverage";

const Leverage = () => {
    return (
        <>
            <LandingBannerLeverage />
            <ContentLeverage />
        </>
    )
}

export default Leverage