import React from "react";
import LandingBanner from "../components/Banners/LandingBanner";
import bgImage from "../assets/images/banners/leverage-banner.png";

const LandingBannerLeverage = () => {
    const defaultStyle = {
        composition: {
            margin: "left",
            alignment : "left",
            lgW: "lg:w-full"
        },
        header : {
            size: "5"
        },
        container: {
            h : "md:h-[27vw]",
            maxH : "max-h-[248px]"
        },
        image: {

        }
    }
    const content = {
        header : "LEVERAGE INFORMATION",
    }
    return(
        <LandingBanner image={bgImage} defaultStyle={defaultStyle} content={content}/>
    )
}

export default LandingBannerLeverage;