import React from 'react';

const CookiePolicy = () => {
    return(
        <>
            <div className="container-full py-6">
                <div className="container-max mx-auto">
                    <span className='block font-bold text-3xl py-4'>Cookie Policy</span>
                    <span className='block font-bold text-3xl py-4'>What are cookies?</span>
                    <p>
                        Cookies are small pieces of text stored on your computer when you visit a website.
                        They are not programs and cannot be executed as code or deliver viruses – instead cookies act more like a passport, which the site checks and updates whenever you visit.
                        Almost every site on the internet uses cookies and their functions include: <br/> <br/>
                    </p>
                    <ul className='list-disc'>
                        <li>verifying your identity for security purposes</li>
                        <li>determining the type of browser and settings you are using</li>
                        <li>allowing site owners and third-party advertisers to tailor content to your preferences</li>
                    </ul>
                    <span className='block font-bold text-3xl py-4'>Our policy</span>
                    We respect your privacy and are committed to providing you with the information and tools you need to manage your cookies.
                    There are three types of cookies on this site:
                    <p>
                        <b>Essential cookies</b> are necessary for the vital functions of our site and trading platform. If these are disabled, you won’t be able to access the platform or other important parts of the site. <br /> <br />
                        <b>Behavioural and analytical cookies</b> help us provide you with a better service. They can tailor site content to your personal preferences and past behaviour, or remember your saved settings. We use "Google Analytics" and "Adobe Analytics" to collect and analyze information about how the Monstrade.com website is used. <br /> <br />
                        <b>Marketing cookies</b> enable you to experience more relevant online content and see adverts which are aligned with your interests, as indicated by your activity on our site. Additionally, we may use third party technology providers such as Google and Adobe to place a cookie on your device in order for us, and our affiliate companies, to display content and advertisements which may be of interest to you while visiting the Monstrade.com website and across the internet after leaving the Monstrade.com site. Cookies do not contain personal identification information. You may disable the use of cookies by updating your ad settings through your web browser.
                    </p>

                    For general information, visit aboutcookies.org. This site includes instructions on how to amend your cookie settings for a variety of browsers and how to delete existing cookies from your computer. 
                    If you have any specific questions or concerns about cookies, please contact support@mostrade.com. 
                </div>
            </div>
        </>
    )
}

export default CookiePolicy;