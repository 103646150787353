import React from "react";

const CashbackAccordion = () => {
    return(
        <>
        <div class="accordion" id="accordionExample">
            <div class="accordion-item bg-gray-100 border border-gray-200">
                <h2 class="accordion-header mb-0" id="headingOne">
                <button class="
                    accordion-button
                    relative
                    flex
                    items-center
                    w-full
                    py-4
                    px-5
                    text-base text-gray-800 text-left
                    bg-white
                    border-0
                    rounded-none
                    transition
                    focus:outline-none
                " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                    aria-controls="collapseOne">
                    Calculation examples
                </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body py-4 px-5 visible">
                    <div className="p-6">
                        <div>
                            <p className="font-bold">Calculation of cashback from Monstrade</p> 
                            <br/>
                            <p className="font-bold">Example 1</p>
                            <br/>
                            <p>
                                Instruments: EUR/USD, USD/JPY
                                Trading volume in EUR/USD per trading session: 3 lots
                                Trading volume in USD/JPY per trading session: 0.9 lot
                                Current cashback level: 3 (cashback rate $13 per lot)
                                Holding period for each transaction is more than 3 minutes
                                The difference between the opening and closing prices of each transaction was at least 10 pips.
                            </p>
                            <br/>
                            <ul className="list-disc">
                                <li>EUR/USD: open price 1.06925, close price 1.07052 (12.7 pips difference),</li><br/>
                                <li>USD/JPY: open price 131.632, close price 131.52 (11.2 pips difference),</li>
                            </ul>
                            <br/>
                            <strong>Cashback amount:</strong> 3 lots * $13 + 0.9 lot * $13 = $50.7 
                        </div>
                        <br/>
                        <div>
                            <p className="font-bold">Example 2</p>
                            <br/>
                            <p>
                                Instruments: GBP/USD, NZD/JPY, XAU/USD (gold)<br/>
                                Trading volume in GBP/USD per trading session: 4.2 lots<br/>
                                Trading volume in NZD/JPY per trading session: 0.05 lot<br/>
                                Trading volume in XAU/USD per trading session: 0.2 lotCurrent cashback level: 5 (cashback rate $17 per lot)<br/>
                                Holding period for each transaction is more than 3 minutes<br/>
                                The difference between the opening and closing prices of each transaction:
                            </p>
                            <br/>
                            <ul className="list-disc">
                                <li>
                                    GBP/USD: <br/>
                                    – for 4 lots: open price 1.25297, close price 1.2519 (10.7 pips difference), <br/>
                                    – for 0.2 lot: open price 1.25297, close price 1.2520 (9.7 pips difference) – doesn’t meet the cashback terms as it’s less than 10 pips
                                </li>
                                <li>NZD/JPY: open price 85.495, close price 85.70 (20.5 pips difference),</li>
                                <li>XAU/USD: open price $1842.26, close price $1842.50 (24 pips difference)</li>
                            </ul>
                            <br/>
                            <strong>Cashback Amount:</strong> 4 lots * $17 + 0.05 lot * $17 + 0.2 lot * $17 = $72.25 
                        </div>
                        <br/>
                        <div>
                            <p className="font-bold">Example 3</p>
                            <br/>
                            <p>
                                Instruments: EUR/USD, Nasdaq100 <br/>
                                Trading volume in EUR/USD per trading session: 0.5 lot <br/>
                                Trading volume in Nasdaq100 per trading session: 0.1 lot – trading activity in indices is not taken into account when calculating cashback <br/>
                                Current cashback level: 4 (cashback rate $15 per lot)Holding period for each transaction is more than 3 minutes <br/>
                                The difference between the opening and closing prices of each transaction:
                            </p>
                            <br/>
                            <ul className="list-disc">
                                <li>EUR/USD: open price 1.06925, close price 1.07052 (12.7 pips difference),</li>
                            </ul>
                            <br/>
                            <strong>Cashback amount:</strong> 0.5 lots * $15 = $7.5
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CashbackAccordion;