import React from "react";

const InviteAFriendContent = () => {
    return(
        <>
            <div className="p-8 text-gray-700">
                <p className="text-gray-400">August 04, 2022</p>
                <br/>
                <p>Are you already trading in the financial markets with Monstrade? Don’t hide your light under a bushel. Share your success with others! Tell your friends about trading conditions at Monstrade and get $10 for every friend you refer. All newly invited clients will get a 15% trading bonus on every deposit over $100.</p>
                <br/>
                <p className="font-semibold text-2xl">Promotion terms:</p>
                <ul className="list-disc">
                    <li>participants must be at least 18 years old;</li>
                    <li>minimum deposit amount for a newly referred client is $100 USD (€100, 0.03 BTC);</li>
                    <li>only verified accounts can participate in the promotion;</li>
                    <li>a client and a friend referred by that client must contact a manager and confirm that they meet the promotion terms;</li>
                    <li>the client will receive $10 for every friend he referred;</li>
                    <li>the referred friend will get a 15% trading bonus on every deposit over $100 or its equivalent in other currency, valid for a month.</li>
                </ul>
                <br/>
                <p>Please note, that this promotion is for Monstrade’ clients only. Partners are not eligible to participate in this promotion.</p>
                <br/>
                <p className="font-semibold text-2xl">Bonus terms and conditions:</p>
                <ul className="list-disc">
                    <li>the 15% bonus on every deposit over $100 is paid in the form of non-withdrawable credits, which are added to the balance and can be used in all trading operations;</li>
                    <li>the promotion is available for trading forex instruments on Standard and Fixed accounts, in MetaTrader 5 platform only;</li>
                    <li>the maximum amount of bonus funds per client – $5,000;</li>
                    <li>this bonus offer is not cumulative with any other Monstrade’ bonuses and promotions;</li>
                    <li>bonus for the reporting period will be canceled, if a client fully or partially withdraws his initial deposit, the above-mentioned bonus has been calculated from;</li>
                    <li>profit received from trading using the bonus funds can be withdrawn without limitations;</li>
                    <li>other bonus terms and conditions are governed by the Monstrade’ general Bonus Terms and Conditions regulations.Open accountDeposit account</li>
                </ul>
            </div>
        </>
    )
}

export default InviteAFriendContent;