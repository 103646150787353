import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import Table from "../components/Table"
import PhoneMockup from '../components/PhoneMockup'
import AvailableAccountTypes from "../containers/AvailableAccountTypes";   
import StockContentTable from "../components/StockContentTable" 
import Faq from "../components/Faq"
import Security from "../components/Security";
import StocksImg from "../assets/images/stocks_img.png"
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import WalletIco from "../assets/icons/wallet2.svg"


const mainTopic = "Stocks"
const subText = "Invest in stocks at Monstrade:get an access to thousands of instruments right now."

const topic1 = "Fast order execution from 0.1 seconds."
const topic2 = "Low spreads"
const topic3 = "Start trading with 100 USD"

const text1 = "Highest order execution speed, from just 0.1 seconds."
const text2 = "Trading with lowest spreads from 0 pips."
const text3 = "Start trading with 100 USD"

const faqTitle1 = "What is stocks trading?"
const faqText1 = "Stocks are securities, which give their owners the right for a part "+
                "of a company’s profit and assets. Over the last several decades, popularity "+
                "of stocks trading has significantly increased: traders started switching from "+
                "usual online investments to a more comfortable environment for trading stocks. "+
                "Monstrade provides you with a reliable access to trading CFDs on stocks of the "+
                "most essential companies from Germany, the USA, and Switzerland."+
                "In order to make efficient investments, a trader needs to see an overall picture of "+
                "what is happening on the market or what may have impact on prices. This approach becomes "+
                "even more important when it comes to trading stocks: a trader not only has to monitor the current "+
                "market behavior, but also keep track of the news published by the company, stocks of which "+
                "they invest in, along with its development and stability. To consider all these various indicators, "+
                "a trader needs to perfectly combine different tools of technical and fundamental analysis."



const StocksPage = () => {

    return(
        <>
            <TopBanner firstIcon={BalanceIco} secondIcon={ForexIco} thirdIcon={WalletIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} text1={text1} text2={text2} text3={text3} bgimage={StocksImg} /> 
            <Button content="Invest In Metals" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={true} /> 
            <Table/>
            <PhoneMockup/>
            <AvailableAccountTypes/>
            <StockContentTable/>
            {/* <Faq faqTitle1={faqTitle1} faqText1={faqText1} isTwoItems={false} isThreeItems={false}/> */}
            {/* <Security/> */}
        </>
    )
}

export default StocksPage;