import React from "react";

const CashbackContent = () => {
    return(
        <>
            <div className="p-8">
                <p className="text-gray-400">September 07, 2022</p>
                <br/>
                <p className="font-semibold text-lg">Dear clients!</p>
                <br/>
                <p>
                    We are pleased to inform you of yet another improvement in our “Cashback from Monstrade” loyalty program: the cashback rate per lot will be increased by $3 starting from 
                    the 2nd level. The increased cashback rate is valid indefinitely and is available to all participants of the loyalty program.Trade more, get more cashback from Monstrade 
                    to your account.
                </p>
                <br/>
                <p>
                    Trade more, get more cashback from Monstrade 
                    to your account.
                </p>
                <br/>
                <p>
                    Receive up to $17 for every lot traded!
                </p>
                <br/>
                <p className="font-semibold text-lg">How it works</p>
                <br/>
                <p>
                    As part of the cashback program, Monstrade returns part of the spread to active clients. Everything is simple: the more you trade, the bigger part of the spread is 
                    returned to your trading account!
                </p>
                <br/>
                <p>
                    The program has 5 levels. The higher your level, the more cashback per lot you receive. To advance to the next level, you need to 
                    reach a monthly trading volume of 5 lots. This is the required trading turnover and it remains unchanged for each level. Clients perform trades and get cashback for 
                    their trading activity depending on their level in the program.
                </p>


            </div>
        </>
    )
}

export default CashbackContent;