import React from 'react'
import img from '../assets/images/spread-img.png'

export const SpreadCalculationInfo = () => {
    return (
        <div className='container-full'>
            <div className='container-max mx-auto py-12'>
                <div className='grid grid-cols-2 my-12'>
                    <div>
                        <img src={img} alt="" />
                    </div>
                    <div className='flex'>
                        <div className='my-auto' >
                            <p className='leading-5 font-medium' >
                                For example, if the bid/ask rate for the EUR/USD is 1.1251/1.1252.
                                Here, EUR is the base currency and USD is the quote currency. This
                                means that you can buy the EUR at a higher ask price of 1.1252 and
                                sell it lower at the bid of 1.1251.<br /><br />
                                The difference between ask and bid price is the forex spread. In the
                                above example, the spread in pips would be (1.1252-1.1251) = 0.0001.
                                The pip value on USD-based pairs is identified on the 4th digit, after
                                the decimal. This means that the final forex spread is 0.1 pips.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 bg-[#F5F5F5] p-10 rounded-xl gap-10'>
                    <div className='px-12 border-r-2 border-[#9F9F9F] '>
                        <p className='mb-8 font-medium ' >
                            To calculate the total spread cost, multiply this pip value by the total
                            number of lots traded. So, if you are trading a EUR/USD trading lot of 10,000
                        </p>
                        <div className='bg-[#424242] rounded-lg p-8 text-center' >
                            <p>
                                your total cost will be <br />
                                0.0001 (0.1pips) x 10000= $0.1
                            </p>
                        </div>
                    </div>
                    <div>
                        <p className='mb-8 font-medium ' >
                            To calculate the total spread cost, multiply this pip value by the total
                            number of lots traded. So, if you are trading a EUR/USD trading lot of 10,000
                        </p>
                        <div className='bg-[#424242] rounded-lg p-8 text-center' >
                            <p>
                                your total cost will be <br />
                                0.0001 (0.1pips) x 10000= $0.1
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
