import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import bgImage from '../assets/images/banners/calculator-banner.png'
import { Calculators } from '../containers/Calculators'

export const Calculator = () => {
    const landing = {
                      h1: 'FOREX CALCULATORS', 
                      p: 'Use Monstrade’s pratical annd effcient calculators to '+
                          'check your trading costs and follow your trading strategy. ' +
                          'Scroll down and choose your calculator.'}
  return (
    <div>
        <ContainerWithBGImage image={bgImage} landing={landing}></ContainerWithBGImage>
        <Calculators />
    </div>
  )
}
