import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import Table from "../components/Table"
import TextArea from "../components/TextArea";
import CryptoContent from "../components/CryptoContent";
import Crypto3Content from "../components/Crypto3Content";
import AccountsForInvests from "../components/AccountsForInvests";
import Security from "../components/Security";
import CryptoImg from "../assets/images/crypto_img.png"
import CryptoIco from "../assets/icons/cryptocurrency.svg"
import Service247Ico from "../assets/icons/247service.svg"
import WalletIco from "../assets/icons/wallet2.svg"

const mainTopic = "Cryptocurrencies"
const subText = "Trade at Monstrade on the best conditions similar to cryptoexchanges!"

const topic1 = "Invest in 33 crypto instruments"
const topic2 = "Trade at any time convenient for you"
const topic3 = "Start trading with 10 USD"

const text1 = "Reliable access to trading CFDs on Bitcoin, Binance Coin, Solana, Stellar, Ethereum, Ripple and crypto-associated ETFs."
const text2 = "Monstrade clients have an opportunity to invest in cryptocurrencies 24/7."
const text3 = "To start investing, the minimum deposit of 10 USD on your trading account will be enough."

const textAreaTitle = "What is Cryptocurrency?"
const textAreaContent = "Cryptocurrencies are digital (virtual) blockchain-based currencies. Security of transactions performed at Monstrade is provided by the application of encryption methods."

const Crypto = () => {
    return(
        <>
            <TopBanner firstIcon={CryptoIco} secondIcon={Service247Ico} thirdIcon={WalletIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} text1={text1} text2={text2} text3={text3} bgimage={CryptoImg} is4Columns={false} /> 
            <Button content="TRADE in CRYPTOMARKET" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={true} />
            <Table/>
            <TextArea textAreaTitle = {textAreaTitle} textAreaContent = {textAreaContent}/>
            <CryptoContent/>
            <Crypto3Content/>
            <AccountsForInvests/>
            {/* <Security/> */}
        </>
    )
}

export default Crypto;