const tradingStock = () => {
    return(
        {
            meta : [
                "Deposit from",
                "Leverage",
                "Commision",
                "Min. commision",
                "Min. order volume",
                "Max. order volume",
                "Financing fee (leverage more than 1:1)",
                "Financing fee (leverage 1:1)",
                "Spread",
                "Trading Time (execution)",
                "Trading Time (requests)",
                "Stop out",
                "Corporate actions (mandatory)",
                "Trading Time (voluntary)",
                "Number of trading instruments",
                "Swap Free (Only 1:1 Leverage)"
            ],
            data : [
                {
                    header : "US Stocks Pro",
                    lines : [
                        "10.000 USD",
                        "Up to 1:20 (or by request)",
                        "0.0045 USD per share",
                        "0.25 USD",
                        "1 share",
                        "10,000 shares",
                        "- 7%",
                        "0%",
                        "from 0.01 USD",
                        "13:30 - 20:00 (UTC)",
                        "24/7",
                        "20%",
                        "V",
                        "-",
                        "More than 3,000",
                        "V"
                    ]
                },
                {
                    header : "US Stocks Pro",
                    lines : [
                        "10.000 USD",
                        "Up to 1:20 (or by request)",
                        "0.0045 USD per share",
                        "0.25 USD",
                        "1 share",
                        "10,000 shares",
                        "- 7%",
                        "0%",
                        "from 0.01 USD",
                        "13:30 - 20:00 (UTC)",
                        "24/7",
                        "20%",
                        "V",
                        "-",
                        "More than 3,000",
                        "V"
                    ]
                },
                {
                    header : "US Stocks Pro",
                    lines : [
                        "10.000 USD",
                        "Up to 1:20 (or by request)",
                        "0.0045 USD per share",
                        "0.25 USD",
                        "1 share",
                        "10,000 shares",
                        "- 7%",
                        "0%",
                        "from 0.01 USD",
                        "13:30 - 20:00 (UTC)",
                        "24/7",
                        "20%",
                        "V",
                        "-",
                        "More than 3,000",
                        "V"
                    ]
                }
            ]
        }
    )
}

export default tradingStock;