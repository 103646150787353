import React from 'react'

export const DocumentationCard = ({header, text, link}) => {
    return (
        <div className='bg-white p-16 border-[1px] border-gray-400 rounded'>
            <p className='text-mons-blue-1 font-bold text-lg mb-3'>{header}</p>
            <p className='mb-24' >{text}</p>
            <a href={link} className='text-mons-blue-1 underline text-sm' >Open in new tab</a>
        </div>
    )
}
