import React from 'react'
import { TradingTimeCollapse } from '../components/Collapse/TradingTimeCollapse'

export const TradingTimeTables = () => {
  return (
    <div className='container-full'>
        <div className='container-max mx-auto'>
            <TradingTimeCollapse />
        </div>
    </div>
  )
}
