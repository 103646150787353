import React from 'react'
import { PricingContainer } from '../components/Containers/PricingContainer'
//images
import bgImage from '../assets/images/banners/trading-hours-banner.png'
import { ContainerWithFlat } from '../components/ContainerLayout/ContainerWithFlat'
import { PublicHolidayHours } from '../containers/PublicHolidayHours'
import { StartTrading } from '../containers/StartTrading'

export const HolidayTradingHours = () => {
    const defaultStyle = {
        composition: {
          margin: "center",
          alignment: "center",
          lgW: "lg:w-full"
        },
        header: {
          size: "5"
        },
        text: {
    
        },
        container: {
          h: "md:h-[46.5vw]",
          maxH: "max-h-[474px]"
        },
        image: {
    
        },
        button: {
          color: "white",
          textColor: "monstrade-blue",
          border: true,
          borderColor: "monstrade-blue"
        }
      }
      const content = {
        header: "Holiday Trading Hours",
        text: "Please be aware of special trading hours during public holiday periods.",
        button: "Start Trading"
      }
  return (
    <div>
        <PricingContainer image={bgImage} defaultStyle={defaultStyle} content={content}/>
        <ContainerWithFlat>
            <PublicHolidayHours />
        </ContainerWithFlat>
        <StartTrading />
    </div>
  )
}
