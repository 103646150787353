import React, { useRef } from "react";
import { Transition } from 'react-transition-group';
import './TradeMarketCard.component.css';

const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};



const TradeMarketCard = ({ image: image }) => {
    const nodeRef = useRef(null);

    return (
        <>
                <div className="h-full">
                        <img className="h-full px-1" src={image} alt="" />
                </div>
        </>
    )
}

export default TradeMarketCard;