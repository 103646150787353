import React from 'react'

export const CompetitiveForexSwapRates = () => {
    return (
        <div className='container-full bg-mons-gray-1 ' >
            <div className='container-max mx-auto py-16'>
                <div className='text-center mb-3'>
                    <h2 className='text-3xl font-medium' >Get Competitive Forex Swap Rates</h2>
                </div>
                <div className='text-center flex mb-6'>
                </div>
                <div className='grid grid-cols-2'>
                    <div className='pr-12' >
                    <p className='' >At Monstrade, we offer among the most competitive swap rates in the industry.
                        What this means is that when you keep positions open overnight, you don’t need
                        to worry about the overnight/rollover fee eating into your earnings.<br /><br />
                        To find out the rollover fee, just use the handy forex swap rates calculator,
                        on MetaTrader 5. Simply choose the financial instrument which
                        you intend to hold an overnight position, fill in the currency and trade size
                        details, and click on “Calculate.”</p>
                    </div>
                    <div className='pl-12' >
                        <p className='mb-3' >Monstrade all-in-one FX calculator allows you to calculate all the important
                            parametres of your trade including:
                        </p>
                        <ul className='list-disc mb-3' >
                            <li className='mb-2' >Pip value</li>
                            <li className='mb-2'>Contract size</li>
                            <li className='mb-2'>Swap rate</li>
                            <li className='mb-2'>Margin and potential gain across all trading instruments.</li>
                        </ul>
                        <p>Streamline your process and manage your strategies. Leave the manual calculations to us.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
