import React from 'react'

export const ContactUsForm = () => {
    return (
        <div className="block  rounded-lg">
            <form>
                <div className="form-group mb-3 grid grid-cols-2 gap-3">
                    <input type="text"
                        className="form-control block w-full px-3 py-1.5 
                                        text-base font-normal text-gray-700 bg-white bg-clip-padding 
                                        border border-solid border-gray-300 rounded 
                                        transition ease-in-out m-0 
                                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="exampleInput7"
                        placeholder="First Name" />
                    <input type="text"
                        className="form-control block w-full px-3 py-1.5 
                                        text-base font-normal text-gray-700 bg-white bg-clip-padding 
                                        border border-solid border-gray-300 rounded 
                                        transition ease-in-out m-0 
                                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="exampleInput7"
                        placeholder="Last Name" />
                </div>
                <div className="form-group mb-3 grid grid-cols-2 gap-3">
                    <input type="email"
                        className="form-control block w-full px-3 py-1.5 
                                        text-base font-normal text-gray-700 
                                        bg-white bg-clip-padding border border-solid border-gray-300 rounded 
                                        transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                                        focus:border-blue-600 focus:outline-none"
                        id="exampleInput8"
                        placeholder="Email address" />
                    <input type="phone"
                        className="form-control block w-full px-3 py-1.5 
                                        text-base font-normal text-gray-700 
                                        bg-white bg-clip-padding border border-solid border-gray-300 rounded 
                                        transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                                        focus:border-blue-600 focus:outline-none"
                        id="exampleInput8"
                        placeholder="Phone Number" />
                </div>
                <div className="form-group mb-3 grid grid-cols-2 gap-3">
                    
                    <input type="email"
                        className="form-control block w-full px-3 py-1.5 
                                        text-base font-normal text-gray-700 
                                        bg-white bg-clip-padding border border-solid border-gray-300 rounded 
                                        transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                                        focus:border-blue-600 focus:outline-none"
                        id="exampleInput8"
                        placeholder="Select Country" />
                    <input type="phone"
                        className="form-control block w-full px-3 py-1.5 
                                        text-base font-normal text-gray-700 
                                        bg-white bg-clip-padding border border-solid border-gray-300 rounded 
                                        transition ease-in-out m-0 focus:text-gray-700 focus:bg-white 
                                        focus:border-blue-600 focus:outline-none"
                        id="exampleInput8"
                        placeholder="Upload File" />
                </div>
                <div className="form-group mb-3">
                    <textarea
                        className=" form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="exampleFormControlTextarea13"
                        rows="4"
                        placeholder="Message"
                    ></textarea>
                </div>
                <button type="submit" className=" w-full px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                    Send
                </button>
            </form>
        </div>
    )
}
