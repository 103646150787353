import React, { Fragment, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag"
import Moment from 'moment';
import economicCalenderContent from '../../features/content/economicCalender.content'


const data = economicCalenderContent();

const EconomicEvents = () => {
    const prevDate = useRef('1900-01-01 22:45:00');
    // const [prevDate, setPrevDate] = useState('1900-01-01 22:45:00')
    return (
        <>
            <div className="container-full">
                <div className="container-max mx-auto py-10">
                    <table className="table-auto w-full">
                        <thead className="text-left" >
                            <tr className="bg-[#f2f2f4] text-sm font-light text-gray-400" >
                                {Object.keys(data[0]).map((item, index) => {
                                    return (
                                        <th key={index} >{item}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody className="text-left text-gray-400 text-sm border-2" >
                            {data.map((item, index) => {
                                console.log(Moment(item.data).format('DD.MM.YYYY'))

                                if (Moment(item.data).format('DD.MM.YYYY') != Moment(prevDate.current).format('DD.MM.YYYY')) {
                                    let currentDate = new Date(item.data);
                                    let nameOfMont = currentDate.toLocaleString('en-EN', { month: 'long' });
                                    let nameOfDay = currentDate.toLocaleDateString('en-EN', { weekday: 'long' });

                                    prevDate.current = item.data;
                                    return (
                                        <Fragment key={index}>
                                            <tr className="text-center text-medium font-semibold text-black bg-[#f2f2f4]" > <td colSpan={Object.keys(item).length}> {nameOfDay}, {nameOfMont} {Moment(item.data).format('DD')} </td> </tr>
                                            <tr key={index} className="border-[1px]">
                                                <td> {Moment(item.data).format('hh:mm')} </td>
                                                <td> <ReactCountryFlag
                                                    countryCode={item.country} title={item.country} /> <span className="text-bold text-black" >{item.country}</span> </td>
                                                <td> {item.name} </td>
                                                <td>
                                                    <div className="w-[45px] border-2" >
                                                    <div className={`bg-${item.impact == 3 ? "red-700" : item.impact == 2 ? "orange-600" : "orange-400"} h-[15px]`} style={{width:(item.impact * 15)}}></div>
                                                    </div>
                                                </td>
                                                <td> {item.actual} </td>
                                                <td> {item.forecast == "" ? "-" : item.forecast} </td>
                                                <td> {item.previous} </td>
                                            </tr>
                                        </Fragment>
                                    )
                                } else {
                                    return (
                                        <Fragment key={index}>
                                            <tr key={index} className="border-[1px]">
                                                <td> {Moment(item.data).format('hh:mm')} </td>
                                                <td> <ReactCountryFlag
                                                    countryCode={item.country} title={item.country} /> <span className="text-bold text-black" >{item.country}</span> </td>
                                                <td> {item.name} </td>
                                                <td>
                                                    <div className="w-[45px] border-2" >
                                                        <div className={`bg-${item.impact == 3 ? "red-700" : item.impact == 2 ? "orange-600" : "orange-400"} h-[15px]`} style={{width:(item.impact * 15)}} ></div>
                                                    </div>
                                                </td>
                                                <td> {item.actual} </td>
                                                <td> {item.forecast == "" ? "-" : item.forecast} </td>
                                                <td> {item.previous} </td>
                                            </tr>
                                        </Fragment>
                                    )
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default EconomicEvents;