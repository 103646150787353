import React from 'react';
import CryptoIco from '../assets/icons/cryptoball.svg'
import Devices from '../assets/icons/devices.svg'
import CryptoBg from '../assets/images/crypto_bg.png'

const CryptoContent = () => {
    return(
        <>
            <div className='container-full relative py-16 min-h-screen'>
                <div className='absolute z-[-1] bottom-0'>
                    <img src={CryptoBg} alt="" />
                </div>
                <div className='container-max mx-auto'>
                    <div className='grid lg:grid-cols-2 lg:text-left text-center'>
                        <div className='grid rows-3 w-4/5'>
                            <div className='mx-auto pb-5'>
                                <img src={CryptoIco} alt="" />
                            </div>
                            <div>
                                <p>The first cryptocurrency, Bitcoin, appeared in 2008 and was developed by Satoshi Nakamoto.</p>
                            </div>
                            <div className='text-gray-600 pt-6'>
                                <p>Since the moment of their appearance, cryptocurrencies have been quickly growing in popularity and are currently considered some of the most highly-demanded trading instruments. There are over 1,500 active cryptocurrencies in the world and this number is on the rise.</p>
                            </div>
                        </div>
                        <div className='grid rows-3 w-4/5'>
                            <div className='mx-auto'>
                                <img src={Devices} alt="" />
                            </div>
                            <div>
                                <p>At Monstrade, you can invest in the 20+ most popular crypto assets through its own terminal and MetaTrader 5.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CryptoContent;