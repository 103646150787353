import React from 'react'
import { ContainerWithFlat } from '../components/ContainerLayout/ContainerWithFlat'

export const CalculationOfSwapRates = () => {
    return (
        <ContainerWithFlat bgColor={'bg-white'}>
            <div className='m-auto py-10' >
                <div className='mb-10'>
                    <h3 className='text-4xl text-center mb-8' ><span className='font-bold' >Calculation of Forex</span> Swap Rates</h3>
                    <p className='text-xl text-center mb-8' >To calculate the forex rollover rates:</p>
                    <ul className='list-disc text-xl px-20' >
                        <li className='mb-5'>Subtract the interest rate of the base currency from the interest rate of the quote currency</li>
                        <li>Then, divide that amount by 365 times the base exchange rate</li>
                    </ul>
                </div>
                <div className='grid grid-cols-2 bg-gray-100 p-10 rounded-xl mb-16'>
                    <div className='pr-10 text-lg leading-6 font-light'>
                        <p className='mb-14' >
                            Let’s say that you are trading EUR/USD. Now, the interest rate of the European
                            Central Bank (ECB) is 0.25% and that of the US Fed is 1.75%. Suppose you want
                            to go long on the EUR/USD, which means that you will buy Euro and sell USD.<br /><br />
                            Now, the interest rate of the currency you are buying (EUR: 0.25%) is lower than
                            the currency you are selling (USD: 1.75%). So, storage will be deducted from
                            your trading account. However, the broker will charge a fee or mark-up for overnight swaps.
                        </p>
                        <p>
                            When your long position is rolled over to the next day, $5.30 will be deducted
                            from your trading account.
                        </p>
                    </div>
                    <div className='pl-10' >
                        <div className='bg-mon bg-mons-blue-1 rounded-lg p-5 text-white font-bold leading-7 mb-5' >
                            <p>
                                Here:<br />
                                Contract = 1 lot = €100,000<br />
                                Price of EUR/USD = 1.1058<br />
                                Interest rate differential = (1.75-0.25) = 1.5%<br />
                                Markup = 0.25%(the broker’s commission)<br />
                                Days per year = 365<br />
                                Swap = (100,000x(1.5+0.25)/ 100)x<br />
                                1.1058/365 = $5.30<br />
                            </p>
                        </div>
                        <div className='bg-mons-gray-3 p-5 rounded-lg text-white leading-7 font-bold' >
                            <p>
                                Swap = <br />
                                (Contract x (Interest Rate Differential +<br />
                                Markup) / 100) x Price / Days per year<br />
                            </p>
                        </div>
                    </div>
                </div>
                <div className='text-center flex' >
                    <div className='w-2/3 mx-auto' >
                        <p className='text-lg leading-6 font-light' >
                            You can also use a forex swap rollover calculator to estimate the value of your overnight
                            trading positions. Simply select the instrument you are trading, your account currency
                            and trade size, and the tool will calculate the rest for you.
                        </p>
                    </div>
                </div>
            </div>
        </ContainerWithFlat>
    )
}
