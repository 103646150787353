import React from 'react'
import youtubeIcon from '../../assets/icons/youtube-play-icon.png'

export const VideoCard = () => {
    return (
        <div>
            <div>
                <h3 className='text-2xl mb-2 font-semibold' >How to Change Your Password</h3>
                <p className='mb-3' >Have you forgotten your password...</p>
            </div>
            <div>
                <div className='w-[344px] h-[198px] bg-[#D9D9D9] relative flex' >
                    <div className='m-auto' >
                        <img src={youtubeIcon} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}
