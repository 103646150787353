import React from 'react'
import { AccountTypeCompareCard } from '../components/Cards/AccountTypeCompareCard';
//images
import icon1 from '../assets/icons/personWithOneStar.svg';
import icon2 from '../assets/icons/account-double-start.svg';
import icon3 from '../assets/icons/account-third-start.svg';
import icon4 from '../assets/icons/account-king.svg';
import icon5 from '../assets/icons/account-circle.svg';
import icon6 from '../assets/icons/account-islamic.svg';

export const CompareAccountTypeContainer = () => {
    const cards = [
        {header: "DEMO", minDeposit : "100", icon: icon1},
        {header: "Cent", minDeposit : "100", icon: icon2},
        {header: "Classic", minDeposit : "100" , icon: icon3},
        {header: "Prime", minDeposit : "1000", icon: icon4},
        {header: "ECN", minDeposit : "2500", icon: icon5},
        {header: "Islamic", minDeposit : "10000", icon: icon6},
    ]
    return (
        <div className='container-full  bg-[#2f67f0] font-roboto'>
            <div className='container-max mx-auto'>
                <div className='pt-16 mb-12 text-center' >
                    <h2 className='text-3xl text-white font-semibold' >Compare Our Account Types</h2>
                </div>
                <div className='grid-cols-4 grid gap-2 pb-16' >
                    {cards.map((item, index) => {
                        return (
                            <div className={`${index > 3 ? 'col-span-2' : ''}
                                            ${index === 4 ? 'justify-self-end' : ''}
                                            ${index === 5 ? 'justify-self-start' : ''}`} >
                                <AccountTypeCompareCard item={item} />
                            </div>
                        )
                    })}
                </div>
                <div className='grid grid-cols-2 text-white m-auto gap-24'>
                    <div className='flex' >
                        <div className='my-auto' >
                            <h3 className='text-4xl font-semibold' >
                                A Step-by-Step <br/>
                                Guide to Choosing <br/>
                                Your Trading <br/>
                                Account <br/>
                            </h3>
                        </div>
                    </div>
                    <div className='grid grid-flow-row gap-16 pb-10 relative'>
                        <div className='absolute h-full py-20 ml-14'>
                            <div className='border-l-4 border-dashed h-full'></div>
                        </div>
                        <div className='flex z-50 mb-4' >
                            <div className='w-[114px] h-[114px] flex bg-white rounded-full p-8 mr-4'>
                                <div className='text-mons-blue-1 text-center font-bold'>
                                    <p className='text-xl leading-3' >STEP</p>
                                    <p className='text-4xl'>1</p>
                                </div>
                            </div>
                            <div className='flex' >
                                <div className='my-auto' >
                                    <p className='font-bold' >Understand the Products</p>
                                    <p className='text-sm font-light' >Is trading Forex or CFDs appropriate for you? How far does your experience and knowledge go?</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex z-50 mb-4' >
                            <div className='w-[114px] h-[114px] flex bg-white rounded-full p-8 mr-4'>
                                <div className='text-mons-blue-1 text-center font-bold'>
                                    <p className='text-xl leading-3' >STEP</p>
                                    <p className='text-4xl'>2</p>
                                </div>
                            </div>
                            <div className='flex' >
                                <div className='my-auto' >
                                    <p className='font-bold' >Measure Your Risk Level</p>
                                    <p className='text-sm font-light' >
                                        Is trading Forex or CFDs appropriate for you? How far does your 
                                        experience and knowledge go?
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex z-50' >
                            <div className='w-[114px] h-[114px] flex bg-white rounded-full p-8 mr-4'>
                                <div className='text-mons-blue-1 text-center font-bold'>
                                    <p className='text-xl leading-3' >STEP</p>
                                    <p className='text-4xl'>3</p>
                                </div>
                            </div>
                            <div className='flex' >
                                <div className='my-auto' >
                                    <p className='font-bold' >Choose the Right Trading Account and Products</p>
                                    <p className='text-sm font-light' >
                                                                        Consider how much time you can spend on trading. Leaving a position 
                                                                        overnight will incur rollover fees. Setting up an EA will automate 
                                                                        your trades, for which you might require a Virtual Private Server. 
                                                                        Some account types offer this for free!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
