import React from 'react'

export const PricingContainer = ({ image: image, defaultStyle: defaultStyle, content: content }) => {
    let img = new Image();
    img.src = image
    return (
                <div className={`w-full 
                            flex 
                            h-[100vw] 
                            relative 
                            overflow-hidden`}
                            style={{height: `${((img.height/1440)*100).toFixed(2)}vw`}} >
                    <div className="absolute z-[-1] h-full" >
                        <img className="lg:w-full lg:min-w-full md:min-w-[120%] min-w-[250%]" 
                            src={image} 
                            alt="monstrade" />
                    </div>
                    <div className={`container-max mx-auto flex text-${defaultStyle.composition.alignment} text-white`}>
                        <div className={`${defaultStyle.composition.lgW}  
                                    w-full 
                                    my-auto 
                                    ${defaultStyle.composition.margin == "center" ? "mx-auto" : ""}`} >
                            <div className="mb-4 md:mb-12" >
                                <h1 className={`xl:text-${defaultStyle.header.size}xl lg:text-4xl md:text-2xl text-2xl font-bold`} >
                                    {content.header}
                                </h1>
                            </div>
                            {content.text &&
                                <div className="mb-4 md:mb-12" >
                                    <p className="lg:text-2xl md:text-xl" >
                                        {content.text}
                                    </p>
                                </div>
                            }
                            {content.button &&
                                <div>
                                    <div>
                                        <a href="/open-account" className={`bg-${defaultStyle.button.color} 
                                                                            hover:bg-monstrade-blue 
                                                                            text-${defaultStyle.button.textColor}
                                                                            lg:py-4 lg:px-8 py-2 px-6 
                                                                            rounded-lg lg:text-2xl md:text-xl 
                                                                            font-semibold
                                                                            ${defaultStyle.button.border ? "border-2 border-monstrade-blue" : ""} `}>
                                            {content.button}
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
    )
}
