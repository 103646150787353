import React from 'react';
import Wallet from '../assets/icons/wallet.svg'
import Gold from '../assets/icons/gold.svg'
import Piechart from '../assets/icons/piechart.svg'

const IndicesBanner = () => {
    return(
        <>
            <div className='container-full bg-monstrade-blue flex'>
                <div className='container-max mx-auto lg:py-10 py-5 flex'>
                    <div className='lg:grid-cols-3 lg:grid-rows-1 grid-rows-3 lg:justify-start justify-center py-5 grid w-full mx-auto gap-24 text-white'>
                        <div className='grid grid-cols-3'>
                            <div className='col-span-1 flex max-w-[79px]'>
                                <img src={Wallet} alt="" />
                            </div>
                            <div className='flex ml-3 col-span-2'>
                                <p className='my-auto'>Minimum deposit – 10 USD.</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-3'>
                            <div className='col-span-1 flex max-w-[79px]'>
                                <img src={Gold} alt="" />
                            </div>
                            <div className='flex ml-3 col-span-2'>
                                <p className='my-auto'>More than 10 trading<br/>instruments.</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-3'>
                            <div className='col-span-1 flex max-w-[79px]'>
                                <img src={Piechart} alt="" />
                            </div>
                            <div className='flex ml-3 col-span-2'>
                                <p className='my-auto'>Exclusive conditions for<br/>investing in indices.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IndicesBanner;