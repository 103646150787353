import React from 'react';
import { CountrySessionCard } from '../components/Cards/CountrySessionCard';
import styles from './ForexMarketHours.module.css';

export const ForexMarketHours = () => {
    return (
        <>
            <div className='container-full'>
                <div className='container-max mx-auto text-center flex py-16'>
                    <div className='text-center m-auto'>
                        <h3 className='text-3xl mb-6' >Forex Market Hours</h3>
                        <p className='text-xl mb-6 '>
                            One of the unique features of the foreign exchange market is
                            that it is accessible 24 hours a day. What makes this possible
                            is an overlap in the trading times of the major sessions. By the
                            time one major market closes, another market opens. There are 4 major
                            Forex trading sessions:
                        </p>
                    </div>
                </div>
            </div>
            <div className='container-full bg-mons-gray-1 '>
                <div className='container-max mx-auto text-center flex py-16'>
                    <div className='w-full'>
                        <div className='grid grid-cols-5'>
                            <CountrySessionCard />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-full bg-white' >
                <div className='container-max flex mx-auto' >
                    <div className='m-auto w-2/3 py-16' >
                        <p className='text-center' >
                            All time are listed in Eastern Standard Time (EST) as per New York time
                            for uniformity. Traders should note that countries such as United States,
                            United Kingdom and Australia all observe daylight saving time. Be sure
                            to make note of the impact of such on the local time in your relevant time zone.
                        </p>
                    </div>
                </div>
                <div className='container-max mx-auto h-[300px]' >
                    <div className='m-auto w-full mb-16'>
                        <h3 className='text-center text-3xl font-bold' >Become a Part of Forex in 5 Simple Steps</h3>
                    </div>
                    <div className='w-full'>
                        <div className='relative'>
                            <div className='h-[125px] w-[125px] bg-mons-gray-1 rounded-full relative flex'>
                                <div className='m-auto' >
                                    <p className='text-mons-blue-1 text-6xl'>1</p>
                                </div>
                                <div className={styles.arrowContainer} >
                                    <div class={styles.arrow}>
                                        <div class={styles.curve}></div>
                                        <div class={styles.point}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-max mx-auto text-gray-500'>
                    <div className='text-center mb-12' >
                        <h3 className='text-3xl' >Forex Liquidity and Volatility</h3>
                    </div>
                    <div className='grid grid-cols-2 gap-24'>
                        <div>
                            <p>
                                This slight delay and small breaks in the day have been 
                                implemented to help prevent the widening of spreads during 
                                this times when liquidity providers go offline. The liquidity 
                                of particular currency pairs is also dependant on the time of 
                                the day. This is due to the fact that trading volume plays a 
                                pivotal role in the liquidity of currency markets.<br/><br/>
                                For instance, the EUR/USD pair will experience high trading 
                                volume and liquidity during the confluence of the London and 
                                New York sessions, that is, when the two sessions overlap. 
                                This is the point in time when the highest number of traders 
                                in the United States and Europe are online.
                            </p>
                        </div>
                        <div>
                            <p>
                            Similarly, the ideal time to trade the Australian dollar against 
                            the Hong Kong dollar or Singapore dollar would be when the Tokyo 
                            and Sydney sessions and both open. Not only will Australian and 
                            Asian traders be most active during this period, it is also the 
                            time when critical economic data for these regions is released. 
                            As a result, this is the best time to conduct both fundamental 
                            and technical analysis to avoid high risk trading scenarios and 
                            identify trading opportunities.<br/><br/>
                            The best time to trade the US dollar is when the North American 
                            session is busiest, the Australian dollar during the Sydney Session 
                            and so forth. Monstrade is a forex broker that caters for forex 
                            traders of all skill levels.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
