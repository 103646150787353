import React from "react";

const SpreadInForex = () => {
    return(
        <div className="container-full bg-[#F0F0F0] ">
            <div className="container-max mx-auto">
                <h2 className="my-12 text-center text-4xl" >What is Spread in Forex?</h2>
                <p className="text-center leading-5 text-xl my-10">Spread in Forex is the difference between the bid price and the ask price. 
                    The Spread cost is measured in 'pips' and is the cost of trading. Popular 
                    currency pairs such as the EUR/GBP and USD/AUD have lower spreads as a 
                    result of higher levels of liquidity. An in-depth explanation can be found 
                    in our Beginner's Guide To Forex Trading.<br/><br/>
                    Spread in Forex is the difference between the two prices of a currency pair. 
                    The Bid is the quote price at which a trader is willing to buy an asset, and 
                    the Ask level is the quote price at which a trader is willing to sell an asset. 
                    Organised by way of a two-way quote, signify willing buyers and ask prices 
                    determine willing sellers. This concept is fundamental for traders to comprehend 
                    as they are the primary cost of trading forex and currency pairs.<br/><br/>
                    For instance, if the bid/ask rate for the EUR/USD is 1.1251/1.1252. Here, EUR 
                    is the base currency and USD is the quote currency. This means that you can 
                    buy the EUR at a higher ask price of 1.1252 and sell it lower at the bid of 1.1251.<br/><br/>
                    The difference between ask and bid price in forex is known as the spread. In 
                    the above example, the spread in pips would be (1.1252-1.1251) = 0.0001. The pip 
                    value on USD-based pairs is identified on the 4th digit, after the decimal. This 
                    means that the final forex spread is 0.1 pips.<br/><br/>
                    To calculate the total spread cost, multiply this pip value by the total number 
                    of lots traded. So, if you are trading a EUR/USD trading lot of 10,000. In case 
                    you are trading a standard lot (100,000 units of the currency). Now, if your account 
                    is denominated in another currency, say GBP, you will need to convert that from US Dollars.<br/><br/>
                    Given the bid and ask prices traders can engage with the market immediately or on 
                    the spot. The ask price is slightly higher than the underlying market price, 
                    whereas the bid price is slightly below the underlying market price. Traders 
                    sell the bid and buy the ask. A narrower bid-ask spread translated to lower trading costs.<br/><br/>
                    The size of the spread plays a pivotal part in forex trading. This is particularly 
                    the case for those using trading strategies that conduct a large number of transactions 
                    in a single trading session. Trading volume, liquidity, market volatility, news, and 
                    time can all have an impact on spreads.<br/><br/>
                    The spread affects profit, given that a currency pair reveals information about market 
                    conditions such as time, volatility and liquidity. For instance, emerging currency pairs 
                    have a greater spread than major currency pairs. Currency pairs involving major currencies 
                    have lower spreads.<br/><br/>
                    Traders should also consider peak trading times for particular currencies. For instance, 
                    the cost of trading the Australian Dollar (AUD) will be higher during nighttime in Australia. 
                    This is because there are not as many market participants actively trading at this time. 
                    Similarly, other Australian financial markets that may influence forex are also closed at 
                    this time. A wider currency pair spread means that a trader would pay more when buying and 
                    receive less when selling.<br/><br/>
                    High spread usually occurs during periods of low liquidity or high market volatility. 
                    For instance, forex pairs that include the Canadian dollar (CAD) will have lower liquidity 
                    during overnight hours in Canada. The same applies to exotic currency pairs such as the 
                    NZD/MXN which have a significantly lower trading volume.<br/><br/>
                    Low spread in forex is the difference between the bid and the ask price. Traders prefer 
                    to place their traders when spreads are low like during the major forex sessions. Spreads 
                    are likely to be low when volatility is low and liquidity is high. When there is a bigger 
                    difference between the bid price (buy) and the ask price (sell), it means that traders are 
                    likely to spend more on wider spreads because of high volatility and lower market liquidity.
                </p>
                <p className=" text-lg mt-10" >
                    Spreads can widen considerably when the financial markets are volatile: a phenomenon known as 
                    slippage. It is also important to understand the difference between fixed and variable spreads. 
                    Fixed spreads remain the same, no matter what the market conditions are. Variable spreads keep 
                    on changing, based on the supply and demand of the instruments and the overall market volatility. 
                    Choosing the optimal spread type is important to keeping trading costs to a minimum. Retail traders 
                    who trade less frequently could benefit from fixed spreads while those who trade frequently and 
                    during peak market hours (when the spreads are the tightest), might prefer variable spreads. 
                    Variable spreads tend to be lower than fixed spreads, especially in calmer markets. Explore our 
                    wide range of forex account types.<br/><br/>
                    The spread of a given currency pair reveals information about market conditions such as time, 
                    volatility and liquidity. Emerging currency pairs have a greater spread than major currency pairs. 
                    There are 8 major currencies which account for approximately 85% of the forex market turnover. 
                    With more market makers, real-time pricing and higher trade volumes, pairs involving major 
                    currencies have lower spreads. In addition, there are peak trading times for particular currencies. 
                    For instance, the cost of trading the AUD will be higher when it is night time in Australia. 
                    This is as there are not as many market participants actively trading at this time. Similarly, 
                    other Australian financial markets that may influence forex are also closed at this time.<br/><br/>
                </p>
            </div>
        </div>
    )
}

export default SpreadInForex;