import React from 'react'

export const AffiliateCommissionCalculatorContainer = () => {
    return (
        <div className='container-full'>
            <div className='container-max mx-auto py-16'>
                <div className='text-center' >
                    <h2 className='text-2xl mb-4' >Calculate your affiliate commission</h2>
                    <p>More your clients invest in forex and other assets, more affiliate commission you get</p>
                </div>
                <div className='flex-col mb-10' >
                    <div className='w-full'>
                        <div className='bg-mons-blue-1 max-w-[200px] mx-auto rounded-xl p-3'>
                            <p className='text-white font-bold text-5xl' >3800$</p>
                        </div>
                    </div>
                    <div className='w-full flex justify-center text-center font-bold'>
                        <div className='mr-10'>
                            <p>VIP Program</p>
                            <p>5,200 <span className='font-normal' >USD</span></p>
                        </div>
                        <div>
                            <p>Loyalty Program</p>
                            <p>5,200 <span className='font-normal' >USD</span></p>
                        </div>
                    </div>
                </div>
                <div className='mb-10' >
                    <div className='bg-mons-gray-1 grid grid-cols-3 text-center rounded-full'>
                        <div className='bg-mons-blue-1 rounded-full text-white p-3'>
                            <p className='font-bold' >Prime Accounts</p>
                            <p>Revenue share up to 50%</p>
                        </div>
                        <div className='p-3' >
                            <p className='font-bold' >Prime Accounts</p>
                            <p>Revenue share up to 50%</p>
                        </div>
                        <div className='p-3' >
                            <p className='font-bold' >Prime Accounts</p>
                            <p>Revenue share up to 50%</p>
                        </div>
                    </div>
                </div>
                <div className='text-center' >
                    <p className='text-xs' >
                    Affiliate commission is calculated based on broker's revenue on MT5 Pro.
                    The data is provided for illustrative purposes only. The amount of broker's 
                    revenue on certain types of accounts or for certain instruments is a subject to change.
                    </p>
                </div>
            </div>
        </div>
    )
}
