import React from "react";
import TopBanner from "../components/TopBanner";
import CashbackImg from "../assets/images/trading-bonus_img.png"
import ZeroCommisionIco from "../assets/icons/zero_commision.svg"
import HandMoneyIco from "../assets/icons/hand_money.svg"
import CardIco from "../assets/icons/card_ico.svg"
import PercentIco from "../assets/icons/circle_percent.svg"
import TradingBonusContent from "../components/PromotionComps/TradingBonusContent";
import PromotionButtons from "../components/PromotionComps/PromotionButtons";
import PromotionFooter from "../components/PromotionComps/PromotionFooter";

const text1 = "Zero fees on credit card/electronic wallet deposits and withdrawals"
const text2 = "Zero fees on deposits / withdrawals made by wire transfer over 200 USD, or currency equivalent"
const text3 = "Instant account funding via all credit cards/electronic wallets"
const text4 = "No hidden commissions"

const TradingBonusPage = () => {
    return(
        <>
            <TopBanner firstIcon={ZeroCommisionIco} secondIcon={HandMoneyIco} thirdIcon={CardIco} forthIcon={PercentIco} text1={text1} text2={text2} text3={text3} text4={text4} bgimage={CashbackImg} is4Columns={true} /> 
            <div className="container-full bg-slate-50 py-8">
                <div className="container-max mx-auto bg-white">
                    <TradingBonusContent/>
                    <PromotionButtons/>
                </div>
            </div>
            <PromotionFooter/>
        </>
    )
}

export default TradingBonusPage;