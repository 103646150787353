import React from "react";
import CardBg from "../../assets/images/energies_bg.png"

const EnergiesCard = () => {
    return(
        <>

            <div className="container-full">
                <div className='absolute z-[-1]'>
                    <img src={CardBg} alt="" />
                </div>
                <div className="container-max mx-auto">
                    <div class="flex justify-center py-32">
                        <div class="block p-6 rounded-lg shadow-lg bg-white max-w-lg">
                            <h5 class="text-gray-900 text-xl leading-tight font-medium mb-2">Brent</h5>
                            <p class="text-gray-700 text-base mb-4">
                            Some quick example text to build on the card title and make up the bulk of the card's
                            content.
                            </p>
                            <div>
                                <div class="flex flex-col">
                                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full">
                                                <tbody>
                                                    <tr class="border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Account type
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            Prime, ECN, Classic, Islamic
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            1 Pip Size
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Size of 1 lot
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            1,000 barrels
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Minimum contract size (lot)
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Term Currency
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            USD
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center monstrade-blue-color">
                                    <p>
                                        <a href="#">All instruments</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="block p-6 rounded-lg shadow-lg bg-white max-w-lg">
                            <h5 class="text-gray-900 text-xl leading-tight font-medium mb-2">WTI</h5>
                            <p class="text-gray-700 text-base mb-4">
                            The grade of Oil, which is produced in Texas, the USA. This grade is used to produce petrol, that’s why it is in good demand.
                            </p>
                            <div>
                                <div class="flex flex-col">
                                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full">
                                                <tbody>
                                                    <tr class="border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Account type
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            Prime, ECN, Classic, Islamic
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            1 Pip Size
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Size of 1 lot
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            1,000 barrels
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Minimum contract size (lot)
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Term Currency
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            USD
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center monstrade-blue-color">
                                    <p>
                                        <a href="#">All instruments</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container-full">
                <div className="container-max mx-auto">
                    <div class="flex justify-center">
                        <div class="block p-6 rounded-lg shadow-lg bg-white max-w-lg">
                            <h5 class="text-gray-900 text-xl leading-tight font-medium mb-2">Brent</h5>
                            <p class="text-gray-700 text-base mb-4">
                            Some quick example text to build on the card title and make up the bulk of the card's
                            content.
                            </p>
                            <div>
                                <div class="flex flex-col">
                                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full">
                                                <tbody>
                                                    <tr class="border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Account type
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            Prime, ECN, Classic, Islamic
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            1 Pip Size
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Size of 1 lot
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            1,000 barrels
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Minimum contract size (lot)
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Term Currency
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            USD
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center monstrade-blue-color">
                                    <p>
                                        <a href="#">All instruments</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <div class="block p-6 rounded-lg shadow-lg bg-white max-w-lg">
                            <h5 class="text-gray-900 text-xl leading-tight font-medium mb-2">WTI</h5>
                            <p class="text-gray-700 text-base mb-4">
                            The grade of Oil, which is produced in Texas, the USA. This grade is used to produce petrol, that’s why it is in good demand.
                            </p>
                            <div>
                                <div class="flex flex-col">
                                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full">
                                                <tbody>
                                                    <tr class="border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Account type
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            Prime, ECN, Classic, Islamic
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            1 Pip Size
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Size of 1 lot
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            1,000 barrels
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Minimum contract size (lot)
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            0.01
                                                        </td>
                                                    </tr>
                                                    <tr class="bg-white border-b">
                                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                            Term Currency
                                                        </td>
                                                        <td class="text-sm monstrade-blue-color text-right px-6 py-4 whitespace-nowrap">
                                                            USD
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center monstrade-blue-color">
                                    <p>
                                        <a href="#">All instruments</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


        </>
    )
} 

export default EnergiesCard;