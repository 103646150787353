import React from "react";
import TradeMarketCard from "../Cards/TradeMarketCard";
import './GlobalMarketSlider.component.css'

const DURATION = 20000;
const ROWS = 2;
const ITEM_PER_ROW = 5;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort(() => .5 - Math.random());
const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
    return (
        <div className='loop-slider max-h-[118px] h-[100vw]' style={{
            '--duration': `${duration}ms`,
            '--direction': reverse ? 'reverse' : 'normal'
        }}>
            <div className='slider-inner'>
                {children}
                {children}
            </div>
        </div>
    );
};

const Tag = ({ image: image }) => (
    <>
        {/* <div className='tag' style={{ '--color': color }}><span>#</span> {text}</div> */}
        <div className="ml-6">
                    <div>
                        <img className="market-logo" src={image} alt="" />
                    </div>
                </div>
    </>

);

const GlobalMarketSlider = ({ content: content }) => {
    return (
        <>
            <div className='tag-list'>
                {[...new Array(ROWS)].map((_, i) => (
                    <InfiniteLoopSlider key={i} duration={random(DURATION - 4000, DURATION + 4000)} reverse={i % 2}>
                        {shuffle(content).slice(0, ITEM_PER_ROW).map(item => (
                            <TradeMarketCard  image={item.image} key={item.name} />
                        ))}
                    </InfiniteLoopSlider>
                ))}
                <div className='fade' />
            </div>

        </>
    )
}

export default GlobalMarketSlider;