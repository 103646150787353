import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import { SimpleCardListContainer } from '../components/ContainerLayout/SimpleCardListContainer'
//images
import image from '../assets/images/banners/platforms-banner.png'
import icon1 from '../assets/icons/platform-icon-1.svg'
import icon2 from '../assets/icons/platform-icon-2.svg'
import icon3 from '../assets/icons/platform-icon-3.svg'
import icon4 from '../assets/icons/platform-icon-4.svg'
import { PlatformDeviceContainer } from '../containers/PlatformDeviceContainer'
import { HowDoOpenAccount } from '../containers/HowDoOpenAccount'
import { WhyChoosePlatform } from '../containers/WhyChoosePlatform'

export const Platforms = () => {
    const landing = {
        h : 'Trade on Monstrade’s MT5',
        p: 'You’re in for a treat with MetaQuotes multi-asset platform, '+
            'built for online CFD trading and exchange instruments.',
        btn: {
            text: 'GET MT5',
            link : '/'
        }
    }
    const cards = [
        { 
            header: 'Flexible trade system', 
            description: 'A big and reliable online shop, where you can find more that 1,500 expert'+
                        ' advisors and technical indicators.', 
            icon: icon1, 
            link: '' 
        },
        { 
            header: 'MetaTrader Market', 
            description: 'MetaTrader 5 features netting and hedging systems, Market Depth and segregtion '+
                        'of orders and transactions.', 
            icon: icon2, 
            link: '' 
        },
        { 
            header: 'Algorithmic Trading', 
            description: 'Using MQL5, a specialized programming envioronmet, you can create, optimize and '+
                        'test trading robots by yourself.', 
            icon: icon3, 
            link: '' 
        },
        { 
            header: 'Virtual Hosting', 
            description: 'MetaTrader 5 may help you to rent a virtual hosting for 24/7 operation of trading '+
                        'robots and subscriptocon for trading signals.', 
            icon: icon4, 
            link: '' 
        }
    ]

  return (
    <div>
        <ContainerWithBGImage  image={image} landing={landing} itemAlingment={'left'} />
        <SimpleCardListContainer cards={cards} />
        <PlatformDeviceContainer />
        <HowDoOpenAccount />
        <WhyChoosePlatform />
    </div>
  )
}
