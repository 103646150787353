import React,{useState,useEffect} from 'react'
import bgImage from '../assets/images/start-trading-img.png'
import { RegisterForm } from '../components/Forms/RegisterForm';
import styles from './StartTrading.module.css'
export const StartTrading = () => {
    const [isLoaded,setIsLoaded] = useState(false)
    useEffect(() => {
        
    },[isLoaded])
    let img = new Image();
    img.src = bgImage
    console.log(img.height)
  return (
    <div className={`container-full relative max-h-[${img.height}px]`} style={{height: `${((img.height/1440)*100).toFixed(2)}vw`}}>
        <div className={`absolute  z-[-1]`} >
            <img src={img.src} alt="" onLoad={() => { setIsLoaded(true)}} />
        </div>
        <div className='container-max mx-auto'>
            <div className='grid grid-cols-2 pt-10'>
                <div>
                    <div className='mb-7' > <h3 className='text-5xl text-white' > Start Trading <br/> in Minutes</h3> </div>
                    <div className='text-white text-lg' >
                        <ul className={styles.sul} >
                            <li>Access 10,000+ financial instruments</li>
                            <li>Auto open & close positions</li>
                            <li>News & economic calendar</li>
                            <li>Technical indicators & charts</li>
                            <li>Many more tools included</li>
                        </ul>
                    </div>
                </div>
                <div className='grid justify-items-end' >
                    <div className='w-3/4' >
                        <RegisterForm />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
