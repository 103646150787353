import React from "react";

const SpreadsAffect = () => {
    return (
        <div className="container-full" >
            <div className="container-max mx-auto">
                <div className="text-center py-6" >
                    <div className="py-12">
                        <p className="text-3xl font-semibold" >Spreads Affect Your Trades</p>
                    </div>
                    <div>
                        <p className="text-xl " >
                            Successful traders always keep track of any changes in the spread, basing trading decisions on such
                            changes. Spread size plays an important role in trading, especially for scalpers and intraday traders:
                        </p>
                    </div>
                    <div className="grid grid-cols-2 py-12" >
                        <div className="pr-12 border-r-2" >
                            <p className="font-bold leading-10 text-lg" >High Spreads</p>
                            <p>
                                When the difference between the ask and bid prices are higher than usual, it could either indicate
                                a period of low liquidity or high market volatility. For instance, non-major forex pairs tend to have
                                a higher spread than major pairs.
                            </p>
                        </div>
                        <div className="pl-12">
                            <p className="font-bold leading-10 text-lg" >Low Spreads</p>
                            <p >
                                When the difference between the ask and bid prices is lower than usual, it could indicate high liquidity or
                                a period of low market volatility. For instance, spreads tend to be lower during the major forex sessions.
                            </p>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpreadsAffect;