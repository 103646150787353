import React from 'react';

const CookiePolicy = () => {
    return(
        <>
            <div className="container-full py-6">
                <div className="container-max mx-auto">
                    <span className='block font-bold text-3xl py-4'>AML Policy</span>
                    <p>
                    The policy of Monstrade is based on management of compliance risks arising from customers; and it also oversees: <br/> <br/>
                    </p>
                    <ul className='list-disc'>
                        <li>Ongoing compliance with the requirements introduced by the national and international laws, regulations and sanctions;</li>
                        <li>Implementation of the risk-based "Know Your Customer" and "Customer Activity Monitoring " principles;</li>
                        <li>Determination and reporting of the suspicious transactions;</li>
                        <li>Preservation of reputation and brand value of the Site towards national and international authorities.</li>
                    </ul>
                    <p>
                        <br/>
                        Laundering of proceeds of crime and financing of terrorism may arise diversely from various fields of activity.<br/> <br/>
                    </p>
                    <p>
                        Risk-based approach enables the measurement of risks for potential laundering of proceeds of crime based on the field of activity, identification of the criteria, also activation of the appropriate means and methods for mitigating and controlling such risks. For the purpose of preventing any transactions with respect to laundering of proceeds of crime and financing of terrorism. Monstrade arranges and organizes the works required for implementation  of adequate procedures in relation to monitoring the customers' transactions, related reporting, retention of records, organization of the training activities and performance of the internal auditing activities, with a risk-based approach; and ensures that any risks falling under such scope are  controlled.<br/> <br/>
                    </p>
                </div>
            </div>
        </>
        
       
        
    )
}

export default CookiePolicy;