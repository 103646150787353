import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FadeInOut } from "../components/Effects/FadeInOut";

const RiskWarning = () => {
    const [isVisible, setIsVisible] = useState(true);
    const path = window.location.pathname
    const clickHandler = () => {
        if (isVisible) {
            setIsVisible(false)
        }
        if (!isVisible) {
            setIsVisible(true)
        }
    }

    useState( () => {
        if(path !== '/'){
            setIsVisible(false)
        }
    },[])
     
    return (

        <div className={`container-full bg-monstrade-blue z-[60] ${isVisible ? 'absolute' : ''}`}>
            <div className="container-max mx-auto">
                <button className="w-full top-0 text-white text-lg" onClick={clickHandler} style={{transitionProperty:'height', transitionDuration:'1000'}} >
                    <FadeInOut in={!isVisible}>
                        <div className="pb-1 text-xs">Click to read the risk warning !</div>
                    </FadeInOut>
                    <FadeInOut in={isVisible }>
                        <div className="w-full text-white text-center p-4 mx-auto">
                            <div className="container m-auto">
                                <p className="text-2xl font-bold py-3">Please Read The Risk Warning!</p>
                                <p className="py-3" >CFDs and Fx instruments are complex instruments and come with a high
                                    risk of losing money rapidly due to leverage. 77.20% of retail investor
                                    accounts lose money when trading CFDs and Fx instrument with this provider.
                                    You should consider whether you understand how CFDs and Fx instrument work
                                    and whether you can afford to take the high risk of losing your money.</p>
                            </div>
                        </div>
                    </FadeInOut>
                    <div className={`bg-monstrade-blue absolute flex w-[18px] h-[18px] rounded-full inset-x-1/2 m-[-8px] ${isVisible ? "border-white border-[1px]" : ""}`}>
                        {!isVisible &&
                            <FontAwesomeIcon icon={faCaretDown} size='xs' />
                        }
                        {isVisible &&
                            <FontAwesomeIcon icon={faCaretUp} size='xs' />
                        }
                    </div>
                </button>
            </div>
        </div>
    )
}

export default RiskWarning