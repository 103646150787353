import React from "react";
import GlobalMarketSlider from "../components/Slider/GlobalMarketSlider";

const content = [
    {name: 'skrill' , image : require('../assets/images/market-logo/skrill.png')},
    {name: 'amex' , image : require('../assets/images/market-logo/amex.png')},
    {name: 'mastercard' , image : require('../assets/images/market-logo/mastercard.png')},
    {name: 'tether' , image : require('../assets/images/market-logo/tether.png')},
    {name: 'visa' , image : require('../assets/images/market-logo/visa.png')},
]

const GlobalMarket = () => {
    return (
        <>
            <div className="container-full mx-auto bg-monstrade-blue text-white">
                <div className="container-max mx-auto pt-10 pb-3">
                    <div className="grid-rows-4">
                        <div className="flex" >
                            <div className="mx-auto max-w-[471px] py-8 text-center" >
                                <p className="text-3xl font-bold" >Trade the Global Markets
                                    at Industry-Low Spreads</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="mx-auto py-3">
                                <p className="text-xl" >
                                    Make speedy and safe trade with spreads as low as 0.0 pips
                                </p>
                            </div>
                        </div>
                        <div className="flex" >
                            <div className="flex mx-auto" >
                                <a className="bg-white text-monstrade-blue py-2 px-4 rounded" href="/open-account">Start Trading</a>
                            </div>
                        </div>
                        <div>
                            <div className="flex py-10 overflow-hidden" >
                                <GlobalMarketSlider content={content} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GlobalMarket;