import React from "react";

const LeverageTable = ({ table: table }) => {

    return (
        <>
            <table className="w-full my-10 table-fixed">
                <caption className="bg-[#D9D9D9] py-2">
                    <div className="grid grid-cols-2" >
                        <div className="text-start" >
                            <p className="pl-10">{table.name}</p>
                        </div>
                        <div>
                            {table.conditions &&
                                table.conditions.map((condition, index) => {
                                    return (
                                        <p key={index}>{condition}</p>
                                    )
                                })
                            }
                        </div>
                    </div>
                </caption>
                <thead>
                    <tr>
                        {table.meta.map((item, index) => {
                            return (
                                <th key={index} className={`${table.meta.length == index+1 ? "" : "w-1/4"}`}> {item} </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody className="text-center" >
                    {table.data.length > 0 ?
                        table.data.map((item, index) => {
                            return (
                                <tr key={index}  className={`${table.data.length == index + 1 ? "" : "border-b-2"}`}>
                                    {Object.keys(item).map((key,sindex) => {
                                        if (typeof item[`${key}`] === 'object') {
                                            let equityLow = item[`${key}`].equityLow 
                                            let equityHigh = item[`${key}`].equityHigh
                                            return(
                                                <td key={key} className={`${Object.keys(item).length == sindex + 1 ? "" : "border-r-2"}`} >
                                                    {equityLow < equityHigh ? 
                                                        equityLow == 0 ? `< ${equityHigh}` : `${equityLow} - ${equityHigh}` :
                                                        `> ${equityLow}`
                                                    } 
                                                </td>
                                            )
                                        } else {
                                            return (
                                                <td key={key} className={`${Object.keys(item).length == sindex + 1 ? "" : "border-r-2"}`} > {item[`${key}`]} </td>
                                            )
                                        }
                                    })}
                                </tr>
                            )
                        })
                        :
                        <></>
                    }
                </tbody>
            </table>
        </>
    )
}

export default LeverageTable;