import React from 'react'
//components
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import { MissionOnAboutUs } from '../containers/MissionOnAboutUs'
//images
import image from '../assets/images/banners/about-us-banner.png'
import image2 from '../assets/images/about-monstrade-img.png'
import { WhoWeAre } from '../containers/WhoWeAre'
import { SimpleCardListContainer } from '../components/ContainerLayout/SimpleCardListContainer'
import icon1 from '../assets/icons/security-of-client-cert-icon.svg'
import icon2 from '../assets/icons/security-of-client-heart-icon.svg'
import icon3 from '../assets/icons/security-of-client-tick-icon.svg'
import icon4 from '../assets/icons/security-of-client-shield-icon.svg'
import { CustomerReviewContainer } from '../containers/CustomerReviewContainer'

export const AboutUs = () => {

    const cards = [
        {   
            header: 'Monstrade Activity', 
            description: 'Monstrade Activity '+ 
                        'Financial Services Commission Skopje', 
            icon: icon1, link: '' },
        { 
            header: 'Negative balance protection', 
            description: 'In case the market volatility prevents clients from keeping a positive balance '+ 
                        'on their accounts, Monstrade automatically sets the balance to zero.', 
            icon: icon2, link: '' },
        { 
            header: 'Compensation Fund of The Financial Commission', 
            description: 'Participant of The Financial Commission Compensation fund', 
            icon: icon3, link: '' },
        {   
            header: 'Civil Liability insurance program', 
            description: 'Monstrade has implemented a Civil Liability insurance program for a limit'+ 
                        'of 5,000,000 EUR, which includes market-leading coverage against omissions, '+ 
                        'fraud, errors, negligence, and other risks that may lead to financial losses of clients.', 
            icon: icon4, link: '' },
    ]

    return (
        <div>
            <ContainerWithBGImage image={image} >
                <div className='flex-col m-auto text-white text-center' >
                    <div className='mb-10' >
                        <h1 className='text-5xl font-semibold' >MonsTrade</h1>
                        <h2 className='text-5xl' >Rapidly Expanding Broker</h2>
                    </div>
                    {/* <div className='grid grid-cols-3 w-full mb-16'>
                        <div>
                            <p className='text-6xl font-bold mb-3' >11</p>
                                <p className='mb-3 text-2xl' >years experience in <br/>the industry</p>
                            <div className='px-24' >
                                <div className='border-b-8 border-[#00C7FF]' ></div>
                            </div>
                        </div>
                        <div>
                            <p className='text-6xl font-bold mb-3' >1 000 000+</p>
                                <p className='mb-3 text-2xl' >clients from <br/>169 countries</p>
                            <div className='px-24' >
                                <div className='border-b-8 border-[#00C7FF]' ></div>
                            </div>
                        </div>
                        <div>
                            <p className='text-6xl font-bold mb-3' >30+</p>
                            <div className='px-10' >
                                <p className='mb-3 text-2xl' >years experience in <br/>the industry</p>
                            </div>
                            <div className='px-24' >
                                <div className='border-b-8 border-[#00C7FF]' ></div>
                            </div>
                        </div>
                    </div> */}
                    <div>
                        <a href='/open-account' className='bg-[#00C7FF] py-3 px-4 text-3xl font-semibold hover:bg-mons-blue-1' >
                            TRADE NOW
                        </a>
                    </div>
                </div>
            </ContainerWithBGImage>
            <MissionOnAboutUs />
            <ContainerWithBGImage image={image2}>
                <div className='my-auto w-3/4' >
                    <h3 className='text-6xl font-extralight mb-7'>About Monstrade</h3>
                    <p>
                        From the moment of its foundation, Monstrade has always been focused on providing best trading 
                        conditions using innovative technologies and many years of experience. Monstrade is an international
                         broker, which offers 8 asset types and more than 12,000 instruments for trading. We’re very proud 
                         of a variety of services we provide our clients and partners with, which are of the same quality 
                         for all, regardless their experience and amount of their investments.
                    </p>
                </div>
            </ContainerWithBGImage>
            <WhoWeAre />
            {/* <div className='container-full bg-[#2167EE] text-white py-10'>
                <SimpleCardListContainer 
                    cards={cards} 
                    header={"Security of Client's Funds"}
                    text={"Your funds are completely secure when you trade with Monstrade."} />
            </div> */}
            {/* <CustomerReviewContainer commandIncluded={true} /> */}
        </div>
    )
}
