import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import { EBookListContainer } from '../containers/EBookListContainer'
import { WhyChoose } from '../containers/WhyChoose'
//images
import bgImage from '../assets/images/banners/ebook-banner.png'
import bgImage2 from '../assets/images/ebook-bg-image.png'

export const EBooksPage = () => {
    const landing = {
        h1: 'FOREX CALCULATORS',
        p: 'Use Monstrade’s pratical annd effcient calculators to ' +
            'check your trading costs and follow your trading strategy. ' +
            'Scroll down and choose your calculator.',
        btn: {
            text: 'JOIN NOW',
            link: '/'
        }
    }
    return (
        <div>
            <ContainerWithBGImage
                landing={landing}
                image={bgImage}
                itemAlingment={'left'}
                textAlingment={'left'} />
            <EBookListContainer />
            <WhyChoose />
            <ContainerWithBGImage
                landing={landing}
                image={bgImage2}
                itemAlingment={'lefttop'}
                textAlingment={'left'}
            />
        </div>
    )
}
