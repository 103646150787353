import React from 'react'
import { SpreadTable } from '../components/Tables/SpreadTable'
import { spreadTableContent } from '../features/content/spreadTables.content'

export const ForexSpreadSearch = () => {
    const tables = spreadTableContent();

    return (
        <div className='container-full' id="anchor">
            <div className='container-max mx-auto'>
                <div className='p-24 w-3/4' >
                    <h3 className='text-4xl font-bold ' >Spreads <span className='text-2xl text-gray-400' >(Sep2022)</span></h3>
                    <p>Spreads on our MetaTrader 5 platform are variable, with quotes coming from major international
                        financial institutions. During liquid times our industry-leading spreads can go as low as 0.0 pips. Our
                        more typical spreads are listed below.</p>
                </div>
                {tables.map( (table, index) => {
                    return <SpreadTable key={index} table={table} />
                })}
            </div>
        </div>
    )
}
