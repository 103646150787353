import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
//images
import image from '../assets/images/banners/partner-banner.png'
import { ForexAffiliateAndPartnership } from '../containers/ForexAffiliateAndPartnership'
import { ChooseYourProgram } from '../containers/ChooseYourProgram'
import { LoyalityProgram } from '../containers/LoyalityProgram'
import { AffiliateHowItWork } from '../containers/AffiliateHowItWork'
import { AffiliateEasyStart } from '../containers/AffiliateEasyStart'
export const Partner = () => {

    return (
        <div>
            <ContainerWithBGImage image={image} itemAlingment={'left'} >
                <div className='my-auto text-white w-1/3' style={{ textShadow: '2px 2px 4px #000000' }}>
                    <h1 className='text-3xl font-bold box  mb-3' >Monstrade Affiliate Program</h1>
                    <p className='mb-3 box drop-shadow-2xl' >Join the affiliate program ang get profit with Monstrade:</p>
                    <p className='font-semibold box drop-shadow-2xl' >Revenue Share up to 70%</p>
                    <p className='mb-3' >Instant partner commission</p>
                    <p>Revenue program up to 20%</p>
                    <p className='mb-6' >of the total partner commission</p>
                    <div className='flex' >
                        <a href='/open-account' className='border-white text-center border-[1px] hover:bg-mons-blue-1 rounded px-3 py-1'>
                            <span className='font-semibold' >Become a Partner</span><br />
                            <span>Start earning in few minutes</span>
                        </a>
                    </div>
                </div>
            </ContainerWithBGImage>
            <ForexAffiliateAndPartnership />
            <ChooseYourProgram />
            <LoyalityProgram />
            <AffiliateHowItWork />
            <AffiliateEasyStart />
        </div>
    )
}
