import React from "react";
import BlueTick from "../../assets/icons/blue-tick.svg"
import NewAccount from "../../assets/icons/new-account.svg"
import LaptopCoffee from "../../assets/icons/laptop-coffee.svg"
import GrayWallet from "../../assets/icons/gray-wallet.svg"

const VipContent = () => {
    return(
        <>
            <div className="container-full py-8">
                <div className="container-max mx-auto">
                    <div className="text-center">
                        <p className="text-3xl">VIP program Advantages</p>
                        <br/>
                        <p>VIP Program levels are granted to forex traders depending on the total amount of funds on their trading accounts.</p>
                    </div>
                    <div className="py-16">
                        <div class="flex flex-col">
                            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="py-2 max-w-[1000px] text-center sm:px-6 lg:px-8 mx-auto">
                                    <div class="overflow-hidden">
                                        <table class="min-w-full">
                                        <thead class="bg-white border-b">
                                            <tr>
                                            <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4">
                                                
                                            </th>
                                            <th scope="col" class="text-sm font-medium text-white silver-gradient px-6 py-4">
                                                SILVER
                                            </th>
                                            <th scope="col" class="text-sm font-medium text-white gold-gradient px-6 py-4">
                                                GOLD
                                            </th>
                                            <th scope="col" class="text-sm font-medium text-white platinium-gradient px-6 py-4">
                                                PLATINIUM
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">Additional Cashback(Rebates)</td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                20%
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                30%
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                40%
                                            </td>
                                            </tr>
                                            <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">More % on account balance</td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                20%
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                30%
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                40%
                                            </td>
                                            </tr>
                                            <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">Improvement of VPS server order conditions</td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                <img src={BlueTick} alt="" className="mx-auto"/>
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                <img src={BlueTick} alt="" className="mx-auto" />
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                <img src={BlueTick} alt="" className="mx-auto" />
                                            </td>
                                            </tr>
                                            <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-left">Personal VIP manager</td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                <img src={BlueTick} alt="" className="mx-auto" />
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                <img src={BlueTick} alt="" className="mx-auto" />
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap bg-gray-200">
                                                <img src={BlueTick} alt="" className="mx-auto" />
                                            </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center py-4">
                        <p className="text-3xl">How to get VIP conditions for your account?</p>
                        <br/>
                        <p>After the total amount of funds for forex trading on your accounts reaches the required number, the VIP status will be granted automatically.</p>
                    </div>
                    <div className="grid grid-cols-3 py-8">
                        <div className="grid-rows-2 w-11/12">
                            <div className=" bg-slate-200 h-[53px]">
                                <div>
                                    <img src={NewAccount} alt="" className="float-left"/>
                                    <p className="pt-3 text-monstrade-blue font-semibold">Open a trading account</p>
                                </div>
                            </div>
                            <div>
                                <p className="text-sm">To get access to the “VIP program, you have to have one or several open accounts at Monstrade.</p>
                            </div>
                        </div>
                        <div className="grid-rows-2 w-11/12">
                            <div className=" bg-slate-200 h-[53px]">
                                <div>
                                    <img src={LaptopCoffee} alt="" className="float-left"/>
                                    <p className="pt-1 text-monstrade-blue font-semibold">Fulfill the requirements for the total amount of funds</p>
                                </div>
                            </div>
                            <div>
                                <p className="text-sm">Make the total amount of funds on your accounts reach the required number by using any of more than 20 methods of depositing.</p>
                            </div>
                        </div>
                        <div className="grid-rows-2 w-11/12">
                            <div className=" bg-slate-200 h-[53px]">
                                <div>
                                    <img src={GrayWallet} alt="" className="float-left"/>
                                    <p className="pt-3 text-monstrade-blue font-semibold">Benefit from VIP conditions</p>
                                </div>
                            </div>
                            <div>
                                <p className="text-sm">Use in your forex trading operations all opportunities and privileges that Monstrade offers to the "VIP client" program participants.</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center py-8">
                        <div class="flex space-x-2 float-left pr-4">
                            <a href='/open-account'>
                            <button
                                href='/open-account'
                                type="button"
                                class="px-10 py-4 bg-monstrade-button-green text-white font-medium text-xs leading-tight uppercase rounded shadow-md"
                            >OPEN ACCOUNT</button>
                            </a>
                        </div>
                        <div class="flex space-x-2 pr-4">
                            <a href='/open-account'>
                            <button
                                
                                type="button"
                                class="px-10 py-4 bg-monstrade-blue text-white font-medium text-xs leading-tight uppercase rounded shadow-md"
                            >DEPOSIT FUNDS</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VipContent;