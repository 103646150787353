import React from 'react'

export const WhyChooseIconCard = ( {icon, text} ) => {
    return (
        <div>
            <div className='border-[1px] border-black w-[215px] h-[215px] rounded-3xl flex mx-auto mb-3 '>
                <img className='m-auto' src={ icon } alt='' />
            </div>
            <div className='text-center' >
                <p className='text-lg font-semibold' >{text}</p>
            </div>
        </div>
    )
}
