import React from 'react'
import MonstradeLogo from '../assets/icons/monstrade_logo.svg'

const LoginPage = () => {
    return(
        <>
            <div className='container-full bg-login-black'>
                <div className='container-max mx-auto'>
                    <div className='my-16'>
                        <div class="p-6 rounded-lg max-w-md mx-auto">
                        <div className='p-4 w-4/6'>
                            <img src={MonstradeLogo} alt="" />
                        </div>
                        <div class="p-6 rounded-lg shadow-lg bg-white max-w-md mx-auto">
                            <form>
                                <label for="exampleInputEmail2" class="form-label inline-block mb-2 text-black font-semibold text-4xl">Sign in</label>
                                <label for="exampleInputEmail2" class="form-label inline-block mb-2 text-gray-700 text-sm">The device is not yours? Use private or incognito mode to log in.</label>
                                <div class="form-group mb-6">
                                <label for="exampleInputEmail2" class="form-label inline-block mb-2 text-black font-bold">Email</label>
                                <input type="email" class="form-control
                                    block
                                    w-full
                                    px-3
                                    py-1.5
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInputEmail2"
                                    aria-describedby="emailHelp" placeholder="Your email"/>
                                </div>
                                <div class="form-group mb-6">
                                <label for="exampleInputPassword2" class="form-label inline-block mb-2 text-black font-bold">Password</label>
                                <input type="password" class="form-control block
                                    w-full
                                    px-3
                                    py-1.5
                                    text-base
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInputPassword2"
                                    placeholder="Your password"/>
                                </div>
                                <button type="submit" class="
                                w-full
                                px-6
                                py-3
                                bg-blue-600
                                text-white
                                font-medium
                                text-xs
                                leading-tight
                                uppercase
                                rounded
                                shadow-md
                                hover:bg-blue-700 hover:shadow-lg
                                focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                                active:bg-blue-800 active:shadow-lg
                                transition
                                duration-150
                                ease-in-out">Continue</button>
                                <div className='text-center grid grid-cols-12'>
                                    <div className='col-span-6 text-right'>
                                        <a href="#!" class="text-blue-600 hover:text-blue-700 focus:text-blue-700 text-sm transition duration-200 ease-in-out">Forgot your password?</a>
                                    </div>
                                    <div className=''>
                                        •
                                    </div>
                                    <div className='col-span-5 text-left'>
                                        <a href="#!" class="text-gray-600 focus:text-blue-700 text-sm transition duration-200 ease-in-out">Privacy Policy</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage;