import React from "react";
import bgImage from "../assets/images/promotion-bg.png"



const content = [
    {
        header: "Cash Back Money",
        entries: ['Monthly Bonus up to 1000 USD',
                'Real money',
                'Earn more bonus as you trade',
                'This bonus can be withdrawn'],
        buttonContent: 'Receive Bonus',
        link: ''
    },
    {
        header: "Trading Bonus",
        entries: ['Bonus up to 10,000 USD',
            'Received bonus is a credit',
            'Earn more bonus as you trade',
            'This bonus cannot be withdrawn'],
        buttonContent: 'Receive Bonus',
        link: ''
    },
    {
        header: "Deposit Bonus",
        entries: ['Provides support as you deposit money',
            'Available for all clients.',
            'This bonus cannot be withdrawn'],
        buttonContent: 'Read More',
        link: ''
    },

    {
        header: "Demo Bonus",
        entries: ['Earn as you try',
            'Money without risk',
            'This bonus can be withdrawn'],
        buttonContent: 'Read More',
        link: ''
    }
]

const MonstradePromotion = () => {
    return (
        <>
            <div className="w-full lg:max-h-[894px] lg:h-[62vw] h-auto relative bg-[#2e66ee] z-0">
                <div className="absolute z-[-1] bottom-0" >
                    <img src={bgImage} alt="" />
                </div>
                <div className="container-max m-auto py-12 z-10">
                    <div className="rows-2 lg:py-5">
                        <div className="lg:mb-3">
                            <div className="md:py-3" >
                                <p className="md:text-4xl text-xl text-white font-bold" >Invest in Forex and benefit from <br /> Monstrade promotions</p>
                            </div>
                            <div className="py-3">
                                <p className="text-white md:text-normal text-sm leading-4" >
                                    Monstrade offers the best promotional programs on financial markets. <br/>
                                    Start investing at Monstrade right now and discover a great variety of bonuses!
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 text-center">
                                {content.map((item, index) => {
                                    return (
                                            <div key={index} className="py-2">
                                                <div className="border-2 border-[#002E90] text-white max-h-[420px] h-full rounded-md bg-[#00000033] py-3">
                                                    <div className="pt-3 h-1/6">
                                                        <p dangerouslySetInnerHTML={{ __html: item.header }} className="font-bold md:text-xl leading-5" ></p>
                                                    </div>
                                                    <div className="md:px-5 px-1 md:h-4/6">
                                                        <ul className="list-disc text-left h-full">
                                                            {item.entries.map((entry, index) => {
                                                                return (
                                                                        <li  key={index} className="pt-1 md:pb-3 md:text-lg font-medium md:leading-6 leading-5 text-xs" >{entry}</li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div className="h-1/6 flex" >
                                                        <div className="m-auto" >
                                                            <a href='/open-account' className="bg-mons-green-1 hover:bg-[#C5C5C5] flex max-h-[41px] rounded-md hover:bg-[#84BD31] mt-5">
                                                                <span className="px-5 py-2 font-medium">{item.buttonContent}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MonstradePromotion;