import React from 'react';
import PhoneMockup from '../assets/images/phone_mockup.png'
import MetatraderIco from '../assets/icons/metatrader5.svg'

const Phone = () => {
    return(
        <div className="w-screen -mb-2 mx-auto h-screen relative">
            <div className='absolute z-[-1]'>
                <img src={PhoneMockup} alt="" />
            </div>
            <div className="grid-rows-3 text-center text-white">
                <div className='pr-36 pt-36 text-right'>
                    <p className='text-3xl font-semibold'>Monstrade trading platforms</p>
                    <p className='text-2xl text-blue-700'>Best trading platforms to invest in Forex</p>
                </div>
                <div className='pr-36 pt-12 text-right'>
                    <div className='flex justify-end ' >
                    <p className='w-2/5'>
                        You can reach out to the Monstrade from any devices to trade with competitive 
                        conditions. All you need to do that just choose a platform and start your investment in Indices.
                    </p>
                    </div>
                </div>
                <div className='pr-36 pt-12 text-right'>
                    <a className="text-cyan-600 underline-offset-2" href="">Download a platform and invest in forex</a>
                </div>
                <div className='float-right pr-52 pt-20'>
                    <img src={MetatraderIco} alt="" />
                </div>
            </div>

        </div>
    )
}

export default Phone;