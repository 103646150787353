

export const spreadTableContent = () => {
    return (
        [
            {
                name: 'Forex Spreads',
                meta: [
                    { column: 'Symbol', subColumn: [] },
                    { column: 'Product', subColumn: [] },
                    {
                        column: 'Standart A/c', subColumn: [
                            { column: 'Avg', subColumn : [] }
                        ]
                    },
                    {
                        column: 'Raw ECN A/c', subColumn: [
                            { column: 'Min', subColumn : [] },
                            { column: 'Avg', subColumn : [] },
                        ]
                    },
                ],
                data: [
                    ['AUDCAD', 'Dolar Australijski vs Dolar Kanadyjski', 1.9, 0, 0.9],
                    ['USDTRY', 'Dolar Australijski vs Dolar Kanadyjski', 1.9, 0, 0.9],
                    ['AUDCAD', 'Dolar Australijski vs Dolar Kanadyjski', 1.9, 0, 0.9],
                    ['AUDCAD', 'Dolar Australijski vs Dolar Kanadyjski', 1.9, 0, 0.9],
                    ['AUDCAD', 'Dolar Australijski vs Dolar Kanadyjski', 1.9, 0, 0.9],
                    ['AUDCAD', 'Dolar Australijski vs Dolar Kanadyjski', 1.9, 0, 0.9],
                    ['AUDCAD', 'Dolar Australijski vs Dolar Kanadyjski', 1.9, 0, 0.9],
                ]
            }
        ]
    )
}