import React from 'react'
import img from "../assets/images/high-low-banner.png"

export const HighAndLowForexSpread = () => {
  return (
        <div className='container-full relative h-[44.1vw] '>
            <div className='absolute z-[-1]' >
                <img src={img} alt="" />
            </div>
            <div className='container-max mx-auto text-white pt-8'>
                <div className='pt-12 pb-8' >
                    <h3 className='text-4xl text-center' >Understanding High and Low Forex Spreads</h3>
                </div>
                <div className='grid grid-cols-2 gap-24 py-6'>
                    <div>
                        <p>It is important to know that FX spreads can fluctuate throughout the day. This is 
                            because spreads are influenced by several factors, such as liquidity, volatility and 
                            market news. You will see some major currency pairs, like the EUR/USD or GBP/USD, which 
                            tend to have more liquidity, also have lower spreads than emerging currency pairs, like 
                            the USD/JPY.<br/><br/>
                            Also, liquidity can decline and spreads can widen in the days or hours leading up to 
                            major economic news events and between trading sessions.<br/><br/>
                            Keep an eye on the economic calendar. Releases occur sporadically and depending on 
                            whether expectations are met or not, prices can fluctuate rapidly. If you are currently 
                            holding a position and the spread widens, you could receive a margin call. Spreads can 
                            widen considerably when the financial markets are volatile: a phenomenon known as slippage.
                        </p>
                    </div>
                    <div>
                        <p>
                            It is also important to understand the difference between fixed and variable spreads. 
                            Fixed spreads remain the same, no matter what the market conditions are. Variable spreads 
                            keep on changing, based on the supply and demand of the instruments and the overall market 
                            volatility.<br/><br/>
                            Choosing the optimal spread type is important to bring down trading costs and is a key 
                            part of the trading strategy of any decent trader. While retail traders with smaller accounts, 
                            who trade less frequently, could benefit from fixed spreads, those who trade more frequently 
                            and during peak market hours (when the spreads are the tightest), might prefer variable spreads. 
                            Variable spreads tend to be lower than fixed spreads especially in calmer markets.                            
                        </p>
                    </div>
                </div>
            </div>
        </div>
  )
}
