import React from "react";


const PageNotFound = () => {
    return(
        <>
            <h1>404 not found</h1>
        </>
    )
}

export default PageNotFound;