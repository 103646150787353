import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import QRCode from 'react-qr-code';
import tradeonmobileBg from '../assets/images/tradeonmobile-bg.png'

const QR_VALUE = "www.monstrade.com"

const TradeOnMobile = () => {
    return (
        <>
            <div className='container-full bg-gradient-to-r from-[#E0E0DF] to-[#CDCECF]'>
                <div className='container-max mx-auto'>
                    <div className='mx-auto' >
                        <img src={tradeonmobileBg} alt="" className='mx-auto' />
                    </div>
                    <div className='md:flex hidden' >
                        <div className='max-w-[104px] p-1 bg-white'>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={QR_VALUE}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <div className='flex px-4' >
                            <p className='m-auto font-bold text-monstrade-blue' >Scan it!</p>
                        </div>
                    </div>
                    <div className='md:columns-2 py-8'>
                        <div className='block md:pr-5 py-3'>
                            <div>
                                <p className='font-bold text-2xl' >Trade on Mobile</p>
                            </div>
                            <div className='py-3'>
                                <p className='leading-7' >
                                    Trade the CFD market on the go with our <br />
                                    mobile application and benefit from ultra-low <br />
                                    latency trading infrastructure, award-winning <br />
                                    order execution and deep liquidity.
                                </p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2' >
                            <div className='mr-auto my-auto' >
                                <div className='md:hidden block' >
                                    <div className='max-w-[100px] max-h-[100px] p-1 bg-white'>
                                        <QRCode
                                            size={256}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={QR_VALUE}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                    <div className='flex px-4' >
                                        <p className='md:m-auto font-bold text-monstrade-blue' >Scan it!</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul className='leading-8 text-lg' >
                                    <li>
                                        <span className='mr-3' >
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='text-mons-blue-1' size='xl' />
                                        </span>
                                        Trade world markets
                                    </li>
                                    <li>
                                        <span className='mr-3' >
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='text-mons-blue-1' size='xl' />
                                        </span>
                                        Manage trading accounts
                                    </li>
                                    <li>
                                        <span className='mr-3' >
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='text-mons-blue-1' size='xl' />
                                        </span>
                                        Variety of payment methods
                                    </li>
                                    <li>
                                        <span className='mr-3' >
                                            <FontAwesomeIcon icon="fa-solid fa-check" className='text-mons-blue-1' size='xl' />
                                        </span>
                                        Latest economic events
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='flex py-5'>
                        <div className='m-auto' >
                            <p className='text-lg' >Available on
                                <span className='text-monstrade-blue font-semibold' > iOS </span>
                                and
                                <span className='text-monstrade-blue font-semibold' > Android </span>
                                devices!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TradeOnMobile;