import React from "react";

const BrokerBonusContent = () => {
    return(
        <>
            <div className="p-8 text-gray-700">
                <p className="text-gray-400">July 28, 2022</p>
                <br/>
                <p className="font-semibold text-black text-2xl">To get the Bonus</p>
                <br/>
                <ul className="list-disc">
                    <li>Only Standard and Fixed USD and EUR accounts in the MT5 platforms can participate in the loyalty program.</li>
                </ul>
                <br/>
                <p className="font-semibold text-black text-2xl">Terms and conditions:</p>
                <ul className="list-disc">
                    <li>Maximum amount of bonus funds per client — $10,000;</li>
                    <li>Bonus is paid in the form of non-withdrawable credits and displayed in Credit line of the MetaTrader 5 platform. Bonus funds are not added to the real funds of your account balance;</li>
                    <li>This offer is not cumulative with any other Monstrade’ bonuses and promotions;</li>
                    <li>Bonus funds will be available for withdrawal after reaching the required level of trading turnover*;</li>
                    <li>Only closed trades are counted in the turnover. The minimum limit for a trade is 3 pips;</li>
                    <li>Only new trades are taken into account in calculations of the turnover. Trading account history doesn’t affect turnover calculations;</li>
                    <li>The bonus can not be used in a «drawdown» situation. It will be written off from the account when the «Stop out» level is reached;</li>
                    <li>The client will have to calculate «Stop out» level himself as the MetaTrader 5 platform takes into account only real assets with no regard to bonus funds, that can’t be used during the drawdown. Therefore, you can reach your «Stop out» level sooner, than indicated in MetaTrader 5;</li>
                    <li>Bonus for the reporting period will be cancelled, if a client fully or partially withdraws his initial deposit, the above-mentioned bonus has been calculated from;</li>
                    <li>If fraudulent activity with credit funds is detected, the Company reserves the right to block the client’s trading account and write off all bonus payments from the trading account without prior notification and explanation.</li>
                    <li>The offer is valid for Standard and Fixed accounts in MetaTrader 5.</li>
                    <li>Trading turnover required for the bonus withdrawal is calculated based only on Forex trading.</li>
                    <li>Please, pay attention that your bonus is valid for 30 days. If your trading account has shown no activity during this period, the bonus will be cancelled.</li>
                </ul>
                <br/>
                <p className="text-xs">*How to calculate trading turnover required for bonus withdrawal? The turnover required for bonus withdrawal will be calculated according to the formula: bonus amount / 3 USD. That is, 3 USD per each full lot. The bonus will be available for withdrawal after fulfilling the requirements for the trading volume.</p>
            </div>
        </>
    )
}

export default BrokerBonusContent;