import React from 'react'

export const BgWhiteBlueButton = ({text , link}) => {
    return (
        <a href='/open-account' className={`
                                bg-white
                                text-mons-blue-1
                                hover:bg-monstrade-blue
                                hover:text-white
                                ease-in
                                duration-150
                                lg:py-4 lg:px-4 py-2 px-6 
                                rounded-lg lg:text-2xl md:text-xl 
                                font-semibold`}>
            {text}
        </a>
    )
}
