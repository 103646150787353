import React from 'react';


const TopBanner = (props) => {
    return(
    <>

        <div className='container-full relative lg:h-[39vw] h-auto text-white'>
            <div className='absolute z-[-1]'>
                <img src={props.bgimage} alt="" />
            </div>
            <div className='container-max mx-auto'>
                <div className='text-center'>
                    <p className="text-2xl font-semibold py-16 w-60 mx-auto">{props.mainTopic}</p>
                    <p className="text-lg">{props.subText}</p>
                </div>
                <div className={"grid text-center py-20 " + (props.is4Columns ? 'lg:grid-cols-4' : 'lg:grid-cols-3')}  >
                    <div>
                        <div className="grid-rows-3">
                            <div className="mx-auto w-20">
                                <img className='max-h-20' src={props.firstIcon} alt="" />
                            </div>
                            <div>
                                <p className="text-base py-2">{props.topic1}</p>
                            </div>
                            <div className='w-56 mx-auto'>
                                <p className="text-sm">{props.text1}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="grid-rows-3">
                            <div className="mx-auto w-20">
                                <img className='max-h-20' src={props.secondIcon} alt="" />
                            </div>
                            <div>
                                <p className="text-base py-2">{props.topic2}</p>
                            </div>
                            <div className='w-56 mx-auto'>
                                <p className="text-sm">{props.text2}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="grid-rows-3">
                            <div className="mx-auto w-20">
                                <img className='mx-auto max-h-20' src={props.thirdIcon} alt="" />
                            </div>
                            <div>
                                <p className="text-base py-2">{props.topic3}</p>
                            </div>
                            <div className='w-56 mx-auto'>
                                <p className="text-sm">{props.text3}</p>
                            </div>
                        </div>
                    </div>
                    {props.is4Columns ?
                    <div>
                        <div className="grid-rows-3">
                            <div className="mx-auto w-20">
                                <img className='mx-auto max-h-20' src={props.forthIcon} alt="" />
                            </div>
                            <div>
                                <p className="text-base py-2">{props.topic4}</p>
                            </div>
                            <div className='w-56 mx-auto'>
                                <p className="text-sm">{props.text4}</p>
                            </div>
                        </div>
                    </div>
                    : ""}
                </div>
            </div>
        </div>


        {/* <div className="p-0 mx-auto">
            <div className='absolute z-[-1] overflow-hidden max-h-96'>
                <img src={props.bgimage} alt="" />
            </div>
            <div className="grid-rows-3 text-center text-white">
                <div className="pt-10">
                    <p className="text-xl font-semibold">{props.mainTopic}</p>
                </div>
                <div className="pt-4">
                    <p className="text-base">{props.subText}</p>
                </div>
                <div className="grid grid-cols-3 pt-16">
                    <div>
                        <div className="p-4 grid-rows-3 pl-64">
                            <div><img className="w-16 mx-auto" src={BalanceIco} alt="" /></div>
                            <div><p className="text-sm pt-2">{props.topic1}</p></div>
                            <div>
                                <p className="text-xs pt-4">{props.text1}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="p-4 grid-rows-3">
                            <div><img className="w-16 mx-auto"  src={ForexIco} alt="" /></div>
                            <div><p className="text-sm pt-2">{props.topic2}</p></div>
                            <div><p className="text-xs pt-4">{props.text2}</p></div>
                        </div>
                    </div>
                    <div>
                        <div className="block p-6 max-w-sm grid-rows-3 pr-56">
                            <div><img className="w-11 mx-auto"  src={HourglassIco} alt="" /></div>
                            <div><p className="text-sm pt-2">{props.topic3}</p></div>
                            <div><p className="text-xs pt-4">{props.text3}</p></div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> */}
    </>
    )
}

export default TopBanner;