import React from "react";
import accontTypeContent from "../features/content/accountType.content";
import AccountTypeCardSlider from "../components/Slider/AccountTypeCarousel";

const accountTypes = accontTypeContent()

const AccountType = () => {
    return (
        <>
            <div className="container-full bg-monstrade-gray mx-auto overflow-hidden">
                <div className="container-max mx-auto py-10">
                    <div className="grid-rows-4">
                        <div>
                            <h2 className="text-2xl md:text-4xl text-center py-2" >Account Types</h2>
                        </div>
                        <div className="text-center md:pb-5" >
                            <a href="/" className="text-monstrade-blue py-2">All account types</a>
                        </div>
                        <div className="md:py-10 py-5" >
                            <AccountTypeCardSlider accountTypes={accountTypes}/>
                        </div>
                        <div className="flex" >
                            <div className="grid grid-cols-2 gap-2 m-auto md:py-10" >
                                <a className="bg-monstrade-blue flex md:mx-3 rounded w-full" href="/open-account">
                                    <span className="text-white p-1 px-3 hover:bg-monstrade-green  text-center" >OPEN TRADING ACCOUNT</span>
                                </a>
                                <a className="bg-white flex md:mx-3 rounded w-full" href="/open-account">
                                    <span className="text-gray-500 p-1 md:px-3 hover:bg-monstrade-green  text-center" >OPEN DEMO ACCOUNT</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountType;