import React from "react";

const BrokerBonusContent = () => {
    return(
        <>
            <div className="p-8 text-gray-700">
                <p className="text-gray-400">September 13, 2022</p>
                <br/>
                <p className="">Switch your broker to Monstrade and get an exclusive 20% bonus on your deposit and the best trading conditions:</p>
                <br/>
                <ul className="list-disc">
                    <li>More than 500 financial instruments: currency pairs, stocks, bonds, metals, indices, commodities;</li>
                    <li>Spreads as low as 0.2 pips;</li>
                    <li>Order execution as fast as 0.03 sec.;</li>
                    <li>Two trading platforms: MT5;</li>
                    <li>Trade analyzer;</li>
                    <li>Unique market sentiment indicator;</li>
                    <li>Trading signals and analytical reviews from recognized experts;</li>
                    <li>Support of a manager;</li>
                    <li>No Dealing Desk order processing.</li>
                </ul>
                <br/>
                <p className="font-semibold text-black text-2xl">Bonus terms and conditions:</p>
                <ul className="list-decimal">
                    <li>To receive a bonus, you must provide a statement from another broker and/or your investment password to check the account activity.</li>
                    <li>The bonus is available to clients who traded on live accounts.</li>
                    <li>The offer is available after the replenishment of a trading account with Monstrade.</li>
                    <li>The offer is available for Standard and Fixed accounts opened on the MetaTrader 5 platforms (USD, EUR).</li>
                    <li>Maximum amount of bonus funds per client — $5,000 / €5,000.</li>
                    <li>20% of the deposit amount is paid in the form of a non-withdrawable trading bonus, which can be used in all trading transactions, including a drawdown.</li>
                    <li>This bonus offer is not cumulative with other Monstrade’ bonuses and promotions.</li>
                    <li>This is a one-time offer. A client can only receive this bonus once.</li>
                    <li>Other bonus terms and conditions are specified in the Bonus accrual rules.</li>
                </ul>
            </div>
        </>
    )
}

export default BrokerBonusContent;