

export const calculationsContent = () => {
  return [
    {
        name : 'Margin', 
        options : 
        [
            {name: 'ACCOUNT CURRENCY',id: 'accountCurrency', type: 'select' },
            {name: 'ACCOUNT CLASS',id : 'accountClass' , type: 'select' },
            {name: 'INSTRUMENT', id:'instrument' ,type: 'select' },
            {name: 'LEVERAGE', id: 'leverage' ,type: 'text'},
            {name: 'VOLUME IN LOTS', id: 'volumeInLots' ,type: 'text' }
        ],
        description : {
            howItWorks : 
                        "Calculate the margin needed for opening and holding" + 
                        "of positions with our margin calculator. <br/><br/>"+
                        "Enter the base currency of your account, select the "+ 
                        "asset class and instrument, then enter the volume of "+
                        "your position in lots.",
            example: 
                "Volume in Lots: 5 <br/>"+
                "(One Standard Lot = 100,000 Units) <br/><br/>" + 
                "Leverage: 100 <br/>"+
                "Account Base Currency: USD <br/>"+
                "Currency Pair: EUR/USD <br/>"+
                "Exchange Rate: 1.365 (EUR/USD) <br/><br/>"+
                "Required Margin = 500,000 /100 * 1.365 <br/>"+
                "<b>Required margin is 6825.00 USD</b>"
        }
    },
    {
        name: 'Currency',
        options :
        [
            {name: 'FROM',id: 'from', type: 'select'},
            {name: 'TO', id:'to', type: 'select'},
            {name: 'AMAOUNT', id: 'amount', type: 'text'}
        ]
    },
    {
        name: 'Pip',
        options :
        [
            {name: 'ACCOUNT CURRENCY',id: 'accountCurrency', type: 'select' },
            {name: 'ACCOUNT CLASS',id : 'accountClass' , type: 'select' },
            {name: 'INSTRUMENT', id:'instrument' ,type: 'select' },
            {name: 'VOLUME IN LOTS', id: 'volumeInLots' ,type: 'text' }
        ]
    },
    {
        name: 'Swap',
        options :
        [
            {name: 'ACCOUNT CURRENCY',id: 'accountCurrency', type: 'select' },
            {name: 'ACCOUNT CLASS',id : 'accountClass' , type: 'select' },
            {name: 'INSTRUMENT', id:'instrument' ,type: 'select' },
            {name: 'VOLUME IN LOTS', id: 'volumeInLots' ,type: 'text' }
        ]
    },
    {
        name: 'Profit',
        options :
        [
            {name: 'ACCOUNT CURRENCY',id: 'accountCurrency', type: 'select' },
            {name: 'ACCOUNT CLASS',id : 'accountClass' , type: 'select' },
            {name: 'INSTRUMENT', id:'instrument' ,type: 'select' },
            {name: 'VOLUME IN LOTS', id: 'volumeInLots' ,type: 'text' },
            {name: 'TYPE', id: 'type' ,type: 'select' },
            {name: 'OPEN PRICE', id: 'openPrice' ,type: 'text' },
            {name: 'STOP LOSS', id: 'stopLoss' ,type: 'text' },
            {name: 'TAKE PROFIT', id: 'takeProfit' ,type: 'text' }
        ]
    },

  ]
}

export const accountCurrencyContent = () => {
    return [
        { value: 'AUD', label: 'AUD' },
        { value: 'CAD', label: 'CAD' },
        { value: 'CHF', label: 'CHF' },
        { value: 'CZK', label: 'CZK' },
        { value: 'USD', label: 'USD' },
        { value: 'EUR', label: 'EUR' }
    ]
}

export const accountClassContent = () => {
    return [
        { value: 'metals', label: 'Metals' },
        { value: 'energies', label: 'Energies' },
        { value: 'indices', label: 'Indices' },
        { value: 'forex', label: 'Forex' },
        { value: 'stocks', label: 'CFD Stocks' },
    ]
}

export const instrumentContent = () => {
    return [
        { value: 'XAUUSD', label: 'XAUUSD' },
        { value: 'AUDCAD', label: 'AUDCAD' },
        { value: 'XAGAUD', label: 'XAGAUD' },
    ]
}

export const typeContent = () => {
    return [
        { value: 'buy', label: 'BUY' },
        { value: 'sell', label: 'SELL' },
    ]
}