import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const AccountTypeCard = ({ item, styles, selectHandler }) => {

    return (
        <>
            <div className="max-w-[344px] bg-white text-center h-[486px] rounded absolute cursor-pointer" style={styles} onClick={() => { selectHandler(item.id) }}>
                <div className="grid-rows-4 px-6">
                    <div>
                        <div className="pt-5">
                            {item.recomended &&
                                <div className="flex" >
                                    <div className="mx-auto flex" >
                                        <div className="mr-2 bg-mons-blue-1 rounded-full text-white w-[22px] h-[22px] flex" >
                                            <FontAwesomeIcon icon="fa-solid fa-star" size="sm"  className="m-auto"/>
                                        </div>
                                        <div className="m-auto" >
                                            <p className="text-xs text-gray-500 font-semibold">{item.command}</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <div className="p-1" >
                            <p className="text-2xl" >{item.header}</p>
                        </div>
                    </div>
                    <div>
                        <div className="p-3" >
                            <p className="text-base font-light text-left" >{item.description}</p>
                        </div>
                    </div>
                    <div className="py-4">
                        <table className="table-fixed">
                            <tbody className="" >
                                {item.table.map((row, index) => {
                                    return (
                                        <tr key={row.column} className={item.table.length === (index + 1) ? 'grid grid-cols-2 leading-7 items-center' : 'border-b-2 grid grid-cols-2 leading-7 items-center'}>
                                            <td className="border-r-2 text-left h-full" > {row.column} </td>
                                            <td className="text-sm text-gray-500" > {row.value} </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountTypeCard;