import React from 'react'

export const PartnerReceive = () => {
  return (
    <div className='container-full bg-mons-gray-1' >
        <div className='container-max mx-auto py-16'>
            <div className='text-center mb-20' >
                <h2 className='text-3xl mb-5' >How much money do MonsTrade partners receive?</h2>
                <p className='text-sm' >
                    When calculating Affiliate commission, we take into 
                    account all transactions performed by your clients – no time or bonus limitations!
                </p>
            </div>
            <div className='grid grid-cols-4 gap-20'>
                <div className='flex border-r-2 border-gray-400 row-span-2' >
                    <p className='m-auto font-semibold' >Accunt Types</p>
                </div>
                <div>
                    <p className='mb-10' >Prime ECN</p>
                    <div className='bg-white border-2 drop-shadow-xl rounded overflow-hidden'>
                        <div className='bg-mons-blue-1 w-1/2' >
                            <p className='text-white font-bold px-1' >50%</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='mb-10' >Classic</p>
                    <div className='bg-white border-2 drop-shadow-xl rounded overflow-hidden'>
                        <div className='bg-mons-blue-1 w-1/2' >
                            <p className='text-white font-bold px-1'>50%</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p className='mb-10' >Cent</p>
                    <div className='bg-white border-2 drop-shadow-xl rounded overflow-hidden'>
                        <div className='bg-mons-blue-1 w-2/5' >
                            <p className='text-white font-bold px-1'>20%</p>
                        </div>
                    </div>
                </div>
                <div className='col-span-3'>
                    <p>Affiliate commission rate is specified in the percentage of the Company’s revenue.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
