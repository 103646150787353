import React from 'react'
//images
import { EBookCard } from '../components/Cards/EBookCard'

export const EBookListContainer = () => {
    const books = [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
    ]
  return (
    <div className='container-full bg-mons-blue-1'>
        <div className='container-max mx-auto'>
            <div className='grid grid-cols-4 py-10 gap-4'>
                { books.map( (item) => {
                    return <EBookCard />
                })}
            </div>
        </div>
    </div>
  )
}
