import React from 'react'
import { ContainerWithFlat } from '../components/ContainerLayout/ContainerWithFlat'

export const SwapPoints = () => {

    const data = [
        { symbol: "ACWI", long: -5, short: -2.50 },
        { symbol: "ADAUSD", long: -20, short: -20.50 },
        { symbol: "AGG", long: -5, short: -2.50 },
        { symbol: "AUDCAD", long: -5.07, short: -2.84 },
        { symbol: "AUDCHF	", long: -2.35, short: -7.46 },
        { symbol: "AUDJPY", long: -3.71, short: -10.50 },
        { symbol: "AUDNZD", long: -2.29, short: -6.29 },
    ]

    return (
        <ContainerWithFlat bgColor={"bg-white"} display={"block"} >
            <div className='text-center mx-auto py-16 w-full'>
                <h3 className='text-xl font-bold mb-5' >Swaps Points</h3>
                <p className='text-lg font-light  mb-5' >The following rates will be applied at 5pm EST 31/10/2022</p>
                <p className='text-lg mb-5' >
                    Swap rates are subject to change.
                    Live swap rates are available on the MT5 platforms.
                </p>
            </div>
            <div className='w-full mb-24 flex' >
                <table className='w-3/5 text-center table-fixed mx-auto' >
                    <thead className='bg-gradient-to-r from-cyan-500 to-blue-500 h-[60px] rounded-lg'>
                        <tr className='' >
                            <th className='rounded-tl-3xl' >Symbol</th>
                            <th>Long</th>
                            <th className='rounded-tr-3xl' >Short</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((line,index) => {
                            return (
                                <tr className={`${index % 2 == 0 ? "bg-mons-gray-1" : ""} h-[60px]`} >
                                    <td className={`${index+1 == data.length ? "rounded-bl-3xl" : ""}`} >{line.symbol}</td>
                                    <td>{line.long}</td>
                                    <td className={`${index+1 == data.length ? "rounded-br-3xl" : ""}`}>{line.short}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </ContainerWithFlat>
    )
}
