import React from "react";
import { Outlet } from "react-router-dom"
import Footer from '../containers/Footer';
import RiskWarning from "../containers/RiskWarning";
import { FixedHeaderContainer } from "../components/ContainerLayout/FixedHeaderContainer";
import { Navbar } from "../components/Nav/Navbar";


const LayoutWebsite = () => {
    return (
        <>
            
            <FixedHeaderContainer>
                <RiskWarning></RiskWarning>
                <Navbar />
            </FixedHeaderContainer>
            <div className="pt-[82px]" id='main' >
                <Outlet />
            </div>
            <Footer></Footer>
        </>
    )
}

export default LayoutWebsite;