import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import image from '../../assets/images/book-sample-image.png'

export const EBookCard = () => {
    return (
        <div className='bg-white p-5 rounded-2xl'>
            <div className='text-center mb-5' >
                <h3 className='text-xl font-semibold' >What is risk-reward?</h3>
            </div>
            <div className='mb-2' >
                <img src={image} alt='' />
            </div>
            <div className='text-center mb-3' >
                <p className='font-light text-gray-500' >Japanese candlestick charts were invented by rice traders by in western Japan in the 1700s</p>
            </div>
            <div className='justify-end flex'>
                <div className='text-gray-400'>
                    <a href='/' className='mr-3'>
                        <FontAwesomeIcon icon="fa-regular fa-eye" />
                    </a>
                    <a href='/' >
                        <FontAwesomeIcon icon="fa-solid fa-download" />
                    </a>
                </div>
            </div>
        </div>
    )
}
