import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import TextArea from "../components/TextArea";
import EnergiesCard from "../components/Cards/EnergiesCard"
import PhoneMockup from '../components/PhoneMockup'
import AvailableAccountTypes from "../containers/AvailableAccountTypes";
import Security from "../components/Security";
import EnergiesImg from "../assets/images/energies_img.png"
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import WalletIco from "../assets/icons/wallet2.svg"
import IncreaseIco from "../assets/icons/increase.svg"

const mainTopic = "Energies"
const subText = "Invest in ETFs and CFDs on energy commodities, such as heating oil, oil, natural gas, and ethanol."

const topic1 = "Leverage value is up to 1:100"
const topic2 = "Spreads from 0 pips"
const topic3 = "Minimum deposit is 10 USD"
const topic4 = "Start trading with 10 USD"

const text1 = "Trade energy commodities with the leverage value up to 1:100."
const text2 = "Trading with lowest spreads from 0 pips."
const text3 = "You can start trading with as few as 10 USD on your trading account."
const text4 = "Energy commodities are an excellent choice for those, who prefer intraday trading."

const textAreaTitle = "What is energy commodities and raw materials trading?"
const textAreaContent = "Trading energy commodities is very popular around the world and very promising for traders, who prefer intraday trading and want to diversify their investment portfolio. Some of most popular energy commodities are heating oil,oil, natural gas, and ethanol. The easiest way to trade energies is to trade CFDs. Traders, who invest in CFDs on energy commodities, do not own energies themselves, but have an opportunity to receive profit thanks to price fluctuations on the market. Monstrade offers its clients to trade the most popular brands of oil, Brent and WTI."

const Energies = () => {
    return(
        <>
            <TopBanner firstIcon={BalanceIco} secondIcon={ForexIco} thirdIcon={WalletIco} forthIcon={IncreaseIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} topic4={topic4} text1={text1} text2={text2} text3={text3} text4={text4} bgimage={EnergiesImg} is4Columns={true} /> 
            <Button content="TRADE INDICES" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={true} />
            <TextArea textAreaTitle = {textAreaTitle} textAreaContent = {textAreaContent}/>
            <EnergiesCard/>
            <PhoneMockup/>
            <AvailableAccountTypes/>
            {/* <Security/> */}
        </>
    )
}

export default Energies;