import React from "react";

const LandingBanner = ({ image: image, defaultStyle: defaultStyle, content: content }) => {
    return (
        <>
            <div className={`w-full 
                            flex 
                            ${defaultStyle.container.maxH} 
                            h-[100vw] 
                            ${defaultStyle.container.h} 
                            relative 
                            overflow-hidden`} >
                <div className="absolute z-[-1] h-full" >
                    <img className="lg:w-full lg:min-w-full md:min-w-[120%] min-w-[250%]" src={image} alt="" />
                </div>
                <div className={`container-max mx-auto flex text-${defaultStyle.composition.alignment} text-white`}>
                    <div className={`${defaultStyle.composition.lgW}  
                                    w-full 
                                    my-auto 
                                    ${defaultStyle.composition.margin == "center" ? "mx-auto" : ""}`} >
                        <div className="mb-4 md:mb-12" >
                            <h1 className={`xl:text-${defaultStyle.header.size}xl lg:text-4xl md:text-2xl text-2xl font-bold`} >
                                {content.header}
                            </h1>
                        </div>
                        {content.text &&
                            <div className="mb-4 md:mb-12" >
                                <p className="lg:text-2xl md:text-xl" >
                                    {content.text}
                                </p>
                            </div>
                        }
                        {content.button &&
                            <div>
                                <div>
                                    <a href={content.path} className={`bg-${defaultStyle.button.color} 
                                                                            hover:bg-monstrade-blue 
                                                                            text-${defaultStyle.button.textColor}
                                                                            lg:py-4 lg:px-8 py-2 px-6 
                                                                            rounded-lg lg:text-2xl md:text-xl 
                                                                            font-semibold
                                                                            ${defaultStyle.button.border ? "border-2 border-monstrade-blue" : ""} `}>
                                        {content.button}
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingBanner;