import React from 'react'

export const NavItem = ({ item }) => {
    return (
        <li className="nav-item dropdown static">
            <a className="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap" href="#" type="button" id="dropdownMenuButtonX" data-bs-toggle="dropdown"
                aria-expanded="false">{item.name}
            </a>
            <div className="dropdown-menu w-[100vw] max-w-[1440px]
                        mt-0 sm:hidden shadow-lg bg-mons-gray-1 left-0
                        absolute top-full ml-0 lg:ml-[calc((100vw-1024px)/-2)] min-[1440px]:ml-[-208px]
                        2xl:h-[464px] md:h-[560px]"
                aria-labelledby="dropdownMenuButtonX">
                <div className="px-6 lg:px-8 py-5">
                    <div className='grid grid-cols-4'>
                        <div className="lg:ml-10 pr-2 border-r-2 border-gray-300" >
                            <p className="text-lg pb-3 text-monstrade-blue font-bold text-1xl">{item.header}</p>
                            <p className="py-3" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>
                        {item.subItems.length > 0 ?
                            item.subItems.map((lvl1) => {
                                if(lvl1.status === true) return (
                                    <div className="ml-5 pr-10" key={lvl1.id} >
                                        {lvl1.icon &&
                                            <div>
                                            </div>
                                        }
                                        <p className="pb-3 text-monstrade-blue font-bold text-1xl" >{lvl1.name}</p>
                                        <div>
                                            {lvl1.subItems && lvl1.subItems.length > 0 ?
                                                lvl1.subItems.map((lvl2) => {
                                                    if(lvl2.status === true) return (
                                                        <div className="border-b-2 border-gray-300 flex" key={lvl2.id}>
                                                            <a href={lvl2.link} className="py-3"> {lvl2.name} </a>
                                                            {/* <p className="py-3" > {lvl2.name} </p> */}
                                                        </div>
                                                    )
                                                })
                                                : ''}
                                        </div>
                                    </div>
                                )
                            }) : ""
                        }
                    </div>
                </div>
            </div>
        </li>
    )
}
