import React from 'react'
import { ContainerWithFlat } from '../components/ContainerLayout/ContainerWithFlat'
import image from '../assets/images/currency-exchange-euro.png'
export const ForexSwapWork = () => {
  return (
    <ContainerWithFlat bgColor={'bg-white'} >
        <div className='grid grid-cols-2 py-24'>
            <div className='pr-10' >
                <h3 className='text-3xl mb-10' >How Does a Forex Swap Work?</h3>
                <p>
                    When traders make a deal to buy or sell a currency, they commit themselves to making the 
                    final payments on the “value date.” The settlement is carried out within two working 
                    days of the transaction in the spot market. When the position remains open and is rolled 
                    over to the next day, it means that the value date shifts to a day ahead.<br/><br/>
                    The corresponding volumes of currencies in the trade are borrowed and lent from the 
                    interbank market, at the current credit interest and deposit rates. So, the gains from 
                    lending and the cost of borrowing are transferred to the trader by their broker. Either 
                    the position gets re-opened automatically, at a new value date, adjusted to swap rate 
                    and a new price, or the swap is credited to or debited from the trader’s account, while 
                    the position is left with the previous price.
                </p>
            </div>
            <div className='flex justify-end' >
                <img src={image} />
            </div>
        </div>
    </ContainerWithFlat>
  )
}
