import React, { useRef } from 'react'
import { Transition } from 'react-transition-group';

export const FadeInOut = ({ children: children, in: inProp, height: height }) => {
    const nodeRef = useRef(null)

    const duration = 500;

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 1,
        visibility: 'visible'
    }

    const transitionStyles = {
        entering: { opacity: 1, visibility: 'visible', height : 'auto' },
        entered: { opacity: 1, visibility: 'visible', height : 'auto' },
        exiting: { opacity: 0, visibility: 'hidden', height : 0 },
        exited: { opacity: 0, visibility: 'hidden', height : 0 },
    };
    return (

        <Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
            {state => (
                <div ref={nodeRef} style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }} className={``}>
                    {children}
                </div>
            )}

        </Transition>
    )
}
