import React from 'react'

export const SimpleCard = ({header, link, description, icon, btnText}) => {
    return (
        <a href={link} key={header}>
            <div className="grid-rows-3" >
                <div className="flex justify-center md:h-[8vw]">
                    <img src={icon} alt="" className="py-2" />
                </div>
                <div className="flex justify-center" >
                    <p className="font-semibold text-md py-2" >{header}</p>
                </div>
                <div className="flex justify-center" >
                    {description &&
                        <p className="text-center text-xs py-1" >{description}</p>
                    }
                    {btnText &&
                        <div className='flex' >
                            <a href="#anchor" className="bg-monstrade-blue hover:bg-monstrade-green text-white px-2 py-1 rounded-md" > VIEW SPREADS </a>
                        </div>
                    }
                </div>
            </div>
        </a>
    )
}
