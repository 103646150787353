import React from 'react'

export const ForexSpreadsFAQ = () => {
    return (
        <div className='container-full bg-[#EEEEEE] '>
            <div className='container-max mx-auto py-24'>
                <h3 className='text-3xl py-12 text-center'>Forex Spreads - FAQ</h3>
                <div className='border-[2px] border-monstrade-blue rounded-xl my-2' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse1"
                        aria-expanded="false"
                        aria-controls="collapse1">
                        What is Spread in Forex?
                    </button>

                    <div className="collapse" id="collapse1">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        Spread in Forex is the difference between the bid price and ask price. 
                        The Spread cost is measured in 'pips' and is the cost of trading. 
                        Popular currency pairs such as the EUR/GBP and USD/AUD have lower 
                        spreads as a result of higher levels of liquidity. An in-depth 
                        explanation can be found in our Beginner's Guide To Forex Trading.
                        </div>
                    </div>
                </div>
                <div className='border-[2px] border-monstrade-blue rounded-xl my-2' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        How is Spread Calculated in the Forex Market?
                    </button>

                    <div className="collapse" id="collapse2">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        Spread in Forex is the difference between the bid price and ask price. 
                        The Spread cost is measured in 'pips' and is the cost of trading. 
                        Popular currency pairs such as the EUR/GBP and USD/AUD have lower 
                        spreads as a result of higher levels of liquidity. An in-depth 
                        explanation can be found in our Beginner's Guide To Forex Trading.
                        </div>
                    </div>
                </div>
                <div className='border-[2px] border-monstrade-blue rounded-xl my-2' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        What Does a Forex Spread Tell Traders?
                    </button>

                    <div className="collapse" id="collapse3">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        Spread in Forex is the difference between the bid price and ask price. 
                        The Spread cost is measured in 'pips' and is the cost of trading. 
                        Popular currency pairs such as the EUR/GBP and USD/AUD have lower 
                        spreads as a result of higher levels of liquidity. An in-depth 
                        explanation can be found in our Beginner's Guide To Forex Trading.
                        </div>
                    </div>
                </div>
                <div className='border-[2px] border-monstrade-blue rounded-xl my-2' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        How much is 10 pips worth?
                    </button>

                    <div className="collapse" id="collapse4">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        Spread in Forex is the difference between the bid price and ask price. 
                        The Spread cost is measured in 'pips' and is the cost of trading. 
                        Popular currency pairs such as the EUR/GBP and USD/AUD have lower 
                        spreads as a result of higher levels of liquidity. An in-depth 
                        explanation can be found in our Beginner's Guide To Forex Trading.
                        </div>
                    </div>
                </div>
                <div className='border-[2px] border-monstrade-blue rounded-xl my-2' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        How does spread affect profit?
                    </button>

                    <div className="collapse" id="collapse5">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        Spread in Forex is the difference between the bid price and ask price. 
                        The Spread cost is measured in 'pips' and is the cost of trading. 
                        Popular currency pairs such as the EUR/GBP and USD/AUD have lower 
                        spreads as a result of higher levels of liquidity. An in-depth 
                        explanation can be found in our Beginner's Guide To Forex Trading.
                        </div>
                    </div>
                </div>
                                <div className='border-[2px] border-monstrade-blue rounded-2xl my-2' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        What is low spread in forex?
                    </button>

                    <div className="collapse" id="collapse6">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        Spread in Forex is the difference between the bid price and ask price. 
                        The Spread cost is measured in 'pips' and is the cost of trading. 
                        Popular currency pairs such as the EUR/GBP and USD/AUD have lower 
                        spreads as a result of higher levels of liquidity. An in-depth 
                        explanation can be found in our Beginner's Guide To Forex Trading.
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
