import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
//images
import bgImage from '../assets/images/banners/accounts-banner.png'
import comonditiesIcon from '../assets/images/comondities-icon.png'
import { SimpleCardListContainer } from '../components/ContainerLayout/SimpleCardListContainer'
import { CompareAccountTypeContainer } from '../containers/CompareAccountTypeContainer'
import { StartTrading } from '../containers/StartTrading'
import { TakingOffContainer } from '../containers/TakingOffContainer'

export const Accounts = () => {
    const landing = {
        h1: 'We compile the most accurate news for you.',
        p: 'We understand that each trader has different needs.' +
            'Monstrade offers 4 account types to help you better execute trades.',
        btn: {
            text: 'JOIN NOW',
            link: '/open-account'
        }
    }
    const cards = [
        { header: 'Demo Account', description: 'With highly competitive pricing. Low minimum deposits and incomparable liquidty.', icon: comonditiesIcon, link: '' },
        { header: 'Cent Account', description: 'With highly competitive pricing. Low minimum deposits and incomparable liquidty.', icon: comonditiesIcon, link: '' },
        { header: 'Classic Account', description: 'With highly competitive pricing. Low minimum deposits and incomparable liquidty. ', icon: comonditiesIcon, link: '' },
        { header: 'Prime Account', description: 'With highly competitive pricing. Low minimum deposits and incomparable liquidty. ', icon: comonditiesIcon, link: '' },
        { header: 'ECN Account', description: 'With highly competitive pricing. Low minimum deposits and incomparable liquidty. ', icon: comonditiesIcon, link: '' },
        { header: 'Islamic Account', description: 'With highly competitive pricing. Low minimum deposits and incomparable liquidty. ', icon: comonditiesIcon, link: '' }
    ]

    return (
        <div>
            <ContainerWithBGImage 
                image={bgImage}
                landing={landing}
                itemAlingment={'left'}
                textAlingment={'left'}
                containerSlice={'1'}
                imgBgColor={'#1E1E1E'} 
            />
            <SimpleCardListContainer 
                cards={cards}
                header={'<b>What Type of Trading Account is Right for You?</b>'}
                text={'Monstrade offers 4 account types to best suit your trading needs.'}
            />
            <CompareAccountTypeContainer />
            <TakingOffContainer />
            <StartTrading />
        </div>
    )
}
