import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import image from '../assets/images/swap-charges-banner.png'
export const SwapCharges = () => {
    return (
        <ContainerWithBGImage image={image} >
            <div className='m-auto' >
                <div className='text-white text-center mb-10'>
                    <h3 className='text-4xl' >Importance of Swap Charges in Forex</h3>
                </div>
                <div className='grid grid-cols-2 text-white'>
                    <div className='pr-16'>
                        <p className='text-[20px] leading-5 font-light' >
                            Swap calculations are done at the end of the day, for positions 
                            that remain open after 5:00pm ET. This is important for those who 
                            want to hold on to long term positions for a considerable period 
                            of time. Traders who use strategies focused not only on intraday 
                            price fluctuations but also on trend trading, on the basis of 
                            fundamental market changes, need to carefully think about swap rates.<br/><br/>
                            In addition, forex swap charges are vital for traders using carry 
                            trade strategies. These strategies are entirely based on the interest 
                            rate differential between two currencies. The currency with a lower 
                            yield becomes the funding currency (borrowed currency) and the 
                            borrowed amount is used to buy a higher yield currency.<br/><br/>
                        </p>
                    </div>
                    <div className='pl-16' >
                        <p className='text-[20px] leading-5 font-light' >
                            For instance, the Swiss franc (CHF) has a negative spread with almost 
                            every currency, which suggests that a trader who goes long on the ‘swissy’ 
                            can expect negative swap rates. On the other hand, if someone goes short 
                            on it, they can expect a positive swap when positions are held overnight. 
                            In contrast, going long on the GBP can result in positive swap rates against 
                            the euro, yen and swissy, while negative swap could be expected with the 
                            US, Canadian and Australian dollars.<br/><br/>
                            Forex swaps are also important for hedging purposes. If a trader opens a 
                            position, expecting a certain type of market movement that hasn’t begun 
                            yet, they may consider opening another position in the opposite direction, 
                            without closing the first one. This is often called “lock mode hedging.” 
                            The low spread between the rates, which is ensured by interbank swap, can 
                            help minimise the cost of maintaining such positions.
                        </p>
                    </div>
                </div>
            </div>
        </ContainerWithBGImage>
    )
}
