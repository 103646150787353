import React from 'react'
import { VideoCard } from '../components/Cards/VideoCard'

export const VideoListContainer = () => {
    const videos = [{},{}, {},{}]
    return (
        <div className='container-full mb-10' >
            <div className='container-max pt-24 mx-auto'>
                <div className='grid grid-cols-3 gap-3'>
                    { videos.map ( () => {
                        return <VideoCard />
                    })}
                </div>
            </div>
        </div>
    )
}
