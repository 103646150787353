import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import Table from "../components/Table"
import PhoneMockup from '../components/PhoneMockup'
import AvailableAccountTypes from "../containers/AvailableAccountTypes";
import Security from "../components/Security";
// import Faq from "../components/Faq"
import ForexImg from "../assets/images/forex_img.png"
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import HourglassIco from "../assets/icons/hourglass.svg"

const mainTopic = "Invest in Forex"
const subText = "Trade currencies on the global financial market on competitive conditions"

const topic1 = "Leverage up to 1:500"
const topic2 = "Low spreads"
const topic3 = "High trade speed"

const text1 = "Trade with the leverage value up to 1:500 in any event of the account type."
const text2 = "Trade with low spreads from 0.0 pips "
const text3 = "Order execution from 0.1 seconds."

// const faqTitle1 = "What is forex trading, and how to invest in Forex?"
// const faqText1 = "The Forex market is the largest and most liquid trading facility in the world with a daily turnover of more than 5 trillion dollars.<br/><br/> The Forex market that is opened 24/5 and considered to be the largest and most liquid trading facility in the world. The turnover is more than 4 trillion Dollars per day and this number is more than any other market. Forex is intended for buying and selling different global currencies, which are paired into single trading instruments. For example, the Euro vs. the US Dollar (EUR/USD). One of the currencies is good, the other one is a tool for paying for this good. The profit from trading is gained due to the currency exchange rate change to one of the directions as time goes by.<br/><br/>No need for large investments to start investing in Forex, as you can use leverage to access stock trading. Thanks to this, traders can perform trading operations that involve bigger amounts of money than they actually have on their accounts. The leverage may increase the profit a trader receives from transactions, but at the same time, it may increase their losses as well."

// const faqTitle2 = "What currency pairs are better for trading on Forex?"
// const faqText2 = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, nesciunt?"

const ForexPage = ()=> {
    return(
        <>
            <TopBanner firstIcon={BalanceIco} secondIcon={ForexIco} thirdIcon={HourglassIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} text1={text1} text2={text2} text3={text3} bgimage={ForexImg} /> 
            <Button content="START INVESTING" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={true} /> 
            <Table/>
            <PhoneMockup/>
            <AvailableAccountTypes/>
            {/* <Security/> */}
            {/* <Faq faqTitle1={faqTitle1} faqText1={faqText1} faqTitle2={faqTitle2} faqText2={faqText2} isTwoItems={true}/> */}
        </>
    )
}

export default ForexPage;