import React from 'react'
import bgImage from '../assets/images/about-us-world-image.png'

export const MissionOnAboutUs = () => {
    return (
        <div className='container-full bg-[#00030a] h-[50vw]' >
            <div className='container-max mx-auto relative h-full flex flex-col'>
                <div className='absolute m-auto h-[40vw] flex z-[0]' >
                    <img className='m-auto' src={bgImage} alt="" />
                </div>
                <div className='mt-10 z-40'>
                    <p className='text-white text-lg text-center mx-auto' >
                        Over the years of the Company’s existence, the letter A has acquired
                        several different meanings, which now shape our mission and philosophy:
                    </p>
                </div>
                <div className='text-white z-40 relative'>
                    <div className='max-w-[240px] absolute text-right left-[4vw]' >
                        <h3 className='text-xl font-bold mb-1' >Alpha</h3>
                        <p>
                            A symbol of leadership and perfection in our quest
                            for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-right top-[13vw]' >
                        <h3 className='text-xl font-bold mb-1' >Adrenaline</h3>
                        <p>
                            A symbol of leadership and perfection in our quest
                            for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-right top-[29.5vw] left-[4vw]' >
                        <h3 className='text-xl font-bold mb-1' >Accountable</h3>
                        <p>
                            A symbol of leadership and perfection in our quest
                            for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-left right-[4vw]' >
                        <h3 className='text-xl font-bold mb-1' >Advanced</h3>
                        <p>
                            A symbol of leadership and perfection in our quest
                            for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-left top-[13vw] right-0' >
                        <h3 className='text-xl font-bold mb-1' >Accomplished</h3>
                        <p>
                            A symbol of leadership and perfection in our quest
                            for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                    <div className='max-w-[240px] absolute text-left top-[29vw] right-[4vw]' >
                        <h3 className='text-xl font-bold mb-1' >Alliance</h3>
                        <p>
                            A symbol of leadership and perfection in our quest
                            for sustainability, service excellence and outstanding results.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
