import React from "react";
import TopBanner from "../components/TopBanner";
import CashbackImg from "../assets/images/trading-bonus_img.png"
import DollarIco from "../assets/icons/re-dollar.svg"
import WalletIco from "../assets/icons/percent-wallet.svg"
import ServerIco from "../assets/icons/cloud-server.svg"
import PersonIco from "../assets/icons/person.svg"
import Button from "../components/Button"
import VipContent from "../components/PromotionComps/VipContent"

const mainTopic = "Trade Demo, Earn Real Cash"

const text1 = "Zero fees on credit card/electronic wallet deposits and withdrawals"
const text2 = "Zero fees on deposits / withdrawals made by wire transfer over 200 USD, or currency equivalent"
const text3 = "Instant account funding via all credit cards/electronic wallets"
const text4 = "No hidden commissions"

const VipPage = () => {
    return(
        <>
            <TopBanner firstIcon={DollarIco} secondIcon={WalletIco} thirdIcon={ServerIco} forthIcon={PersonIco} mainTopic={mainTopic} text1={text1} text2={text2} text3={text3} text4={text4} bgimage={CashbackImg} is4Columns={true} /> 
            <Button content="DEPOSIT FUNDS" color="bg-monstrade-button-green" py="py-2" px="px-8" isArrow={false} />
            <VipContent/>
        </>
    )
}

export default VipPage;
