import React from "react";
import LandingBanner from "../components/Banners/LandingBanner";
import bgImage from "../assets/images/banners/forex-news-banner.png";
const LandingBannerForexNews = () => {
    const defaultStyle = {
        composition: {
            margin: "left",
            alignment : "left",
            lgW: "lg:w-1/3"
        },
        header : {
            size: "5"
        },
        text : {

        },
        container: {
            h : "md:h-[46.5vw]",
            maxH : "max-h-[671px]"
        },
        image: {

        },
        button: {
            color: "white",
            textColor: "monstrade-blue",
            border: true,
            borderColor: "monstrade-blue"
        }
    }
    const content = {
        header : "We compile the most accurate news for you.",
        text : "We understand that each trader has different needs. Monstrade offers 4 account types to help you better execute trades.",
        button : "JOIN NOW",
        path : "/open-account"
    }
    return(
        <>
            <LandingBanner  image={bgImage} defaultStyle={defaultStyle} content={content}/>
        </>
    )
}

export default LandingBannerForexNews;