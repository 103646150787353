import React from "react";
import Landingbanner from "../containers/LandingBanner";
import AccountType from "../containers/AccountType";
import WhyMonstrade from "../containers/WhyMonstrade";
import GlobalMarket from "../containers/GlobalMarket";
import RangeOfPlatform from "../containers/RangeofPlatform";
import MonstradePromotion from "../containers/MonstradePromotion";
// import KeyMarket from "../containers/KeyMarket";
import TradeOnMobile from "../containers/TradeOnMobile";
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";
// import { CustomerReviewContainer} from '../containers/CustomerReviewContainer'
//images
import indicesIcon from '../assets/images/indices.svg'
import forexIcon from '../assets/images/forex-icon.svg'
import cryptoIcon from '../assets/images/cryptocurrencies-icon.svg'
import comonditiesIcon from '../assets/images/comondities-icon.png'
import bondsIcon from '../assets/images/bonds-icon.svg'
import metalsIcon from '../assets/images/metals-icon.svg'

const cards = [
    { header: 'Indices', description: '19 major global Indices', icon: indicesIcon, link: '' },
    { header: 'Forex', description: '60+ forex currency pairs on MT5', icon: forexIcon, link: '' },
    { header: 'Cryptocurrencies', description: 'Bitcoin, Ethereum, Ripple, Bitcoin Cash, Litecoin', icon: cryptoIcon, link: '' },
    { header: 'Commodities', description: 'Coffee, natural gas, corn & more', icon: comonditiesIcon, link: '' },
    { header: 'Bonds', description: 'US10YR & UK Long Gilt Futures GILT', icon: bondsIcon, link: '' },
    { header: 'Metals', description: 'Gold, oil, silver & more', icon: metalsIcon, link: '' }
]

const HomePage = () => {
    return(
        <>
            <Landingbanner />
            {/* <CustomerReviewContainer commandIncluded={false} /> */}
            <SimpleCardListContainer 
                cards={cards}
                header={'You Can trade all with Monstrade'}/>
            <AccountType />
            <WhyMonstrade />
            <GlobalMarket />
            <RangeOfPlatform />
            <MonstradePromotion />
            {/* will be improve
            <KeyMarket /> */}
            <TradeOnMobile />
        </>
    )
}

export default HomePage;
