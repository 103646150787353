import React from 'react'
import { DocumentationListcontainer } from '../containers/DocumentationListcontainer.jsx'

export const Documentation = () => {
  return (
    <div>
        <DocumentationListcontainer />
    </div>
  )
}
