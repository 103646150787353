import React from "react";
import bgImage from "../assets/images/what-is-spread-banner.png"

const WhatIsSpread = () => {
    return (
        <div className="w-full flex max-h-[491px] h-[100vw] md:h-[33vw] relative overflow-hidden ">
            <div className="absolute z-[-1]" >
                <img src={bgImage} alt="" />
            </div>
            <div className="container-max mx-auto text-left text-white">
                <div className="w-[46%]" >
                    <h2 className="text-4xl mt-12 mb-5" >
                        <span className="font-bold" >What is Spread in Forex?</span><br />
                        What does Spread mean?
                    </h2>
                    <p className="mb-5" >Trade with Monstrade on ultra-competitive spreads</p>
                    <p>
                        Forex is the most traded market in the world offering numerous
                        trading opportunities. One of the ways you pay for these
                        opportunities is through the broker spreads, or the difference
                        between the ask and bid prices of a tradable instrument.
                        When the spread is tight, your trading cost reduces.
                        <br/>
                        <br/>
                        Our diverse liquidity mix and partnerships with leading
                        banking and non-banking financial institutions for deep
                        liquidity also plays a key role in ensuring that we consistently
                        deliver tight spreads to our traders, starting as low as 0.0 pips.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default WhatIsSpread;