import React from 'react'
import { useState } from 'react';

const OpenAccount = () => {

    const [isSend,setIsSend] = useState(false);
    const clickHandler = () => {
        if(isSend){
            setIsSend(false);
        }else{
            setIsSend(true);
        }
    }

    return(
        <>
            <div className="container-full py-8">
                <div className="container-max mx-auto">
                    <form>
                        {isSend && 
                        <p className='text-green-600'>We received your request.</p>
                        }
                        <label className='text-4xl font-semibold'>
                            Create an account
                        </label>
                        <p className='py-4'>Required fields have an asterisk: *</p>
                        <div class="grid grid-cols-2 mb-6">
                            <div>
                                <label for="firstname" class="mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
                                <input type="firstname" id="firstname" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="First name" required/>
                            </div>
                            <div>
                                <label for="lastname" class="mb-2 text-sm font-medium text-gray-900 dark:text-white">Last name</label>
                                <input type="lastname" id="lastname" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Last name" required/>
                            </div>
                        </div>
                        <div class="mb-6">
                            <label for="email" class="mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                            <input type="email" id="email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-4/5 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Email" required/>
                        </div>
                        <div class="mb-6">
                            <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
                            <input type="phone" id="phone" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" required/>
                        </div>
                        <div class="mb-6">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input type="password" id="password" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder='Min. 8 characters' required/>
                        </div>
                        <button type="submit" onClick={()=>{clickHandler()}} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Register new account</button>
                        <p className='py-2'>Have you an account? <a href="/login" className=' text-monstrade-blue'>Sign in.</a> </p>
                    
                    </form>
                </div>
            </div>
        </>
    )
}

export default OpenAccount;