import React, { Fragment } from "react";
import contentForexwNews from "../features/content/forexNews.content";
import contentForexNewsTweet from "../features/content/forexNewsTweet.content";
import ForexNewsCard from "../components/Cards/ForexNewsCard";
import TweetCard from "../components/Cards/TweetCard";

const ContentForexNews = () => {

    const content = contentForexwNews();
    const tweets = contentForexNewsTweet();
    return (
        <>
            <div className="container-full" >
                <div className="container-max mx-auto">
                    <div className="grid grid-cols-6 py-10" >
                        <div className="col-span-4 grid grid-cols-3 gap-3 mr-4">
                            {content.map((item, index) => {
                                return (
                                    <ForexNewsCard key={index} item={item} />
                                )
                            })}
                        </div>
                        <div className="col-span-2 border-2 rounded-lg ml-4">
                            <div>
                                <p className="text-lg font-semibold border-b-2 p-2" >Tweets from @Monstrade</p>
                            </div>
                            <div className="grid grid-cols-1">
                                {tweets.map( (item => {
                                    return(
                                        <TweetCard item={item} />
                                    )
                                }))}
                            </div>
                            <div className="flex" >
                                <div className="m-auto py-5">
                                    <a href="https://twitter.com/MonsTradeFX" className="bg-[#2c88d1] text-white px-3 py-2 rounded-3xl font-bold" >View more on Twitter</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContentForexNews;