import React from 'react'
//containers
import { ForexMarketHours } from '../containers/ForexMarketHours'
//components
import { PricingContainer } from '../components/Containers/PricingContainer'
import { StartTrading } from '../containers/StartTrading'
import { TradingTimeTables } from '../containers/TradingTimeTables'
//image
import bgImage from '../assets/images/banners/trading-hours-banner.png'
export const TradingHours = () => {
  const defaultStyle = {
    composition: {
      margin: "center",
      alignment: "center",
      lgW: "lg:w-full"
    },
    header: {
      size: "5"
    },
    text: {

    },
    container: {
      h: "md:h-[46.5vw]",
      maxH: "max-h-[474px]"
    },
    image: {

    },
    button: {
      color: "white",
      textColor: "monstrade-blue",
      border: true,
      borderColor: "monstrade-blue"
    }
  }
  const content = {
    header: "Forex Trading Hours",
    text: "With Monstrade you can trade Forex around the clock. Our award-winning support team are available 24 hours a day, 5 days a week to help ensure a seamless trading day experience.",
    button: "Start Trading"
  }
  return (
    <>
      <PricingContainer image={bgImage} defaultStyle={defaultStyle} content={content}/>
      <ForexMarketHours />
      <TradingTimeTables />
      <StartTrading />
    </>
  )
}
