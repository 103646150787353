import React from "react";
import Button from "../Button"

const ZeroCommissionContent = () => {
    return(
        <>
            <div className="container-full">
                <div className="container-max mx-auto">
                    <div className="p-8">
                        {/* <p>
                            Monstrade covers all deposit and withdrawal transfer fees for payments made via Neteller, Moneybookers and all major credit cards (including VISA, VISA Electron, MasterCard, Maestro and China UnionPay). Additionally, all deposits and withdrawals above 200 USD processed by wire transfer are also included in our zero fees policy.
                        </p> */}
                        <br/>
                        <p className="font-bold text-2xl">Instant Funding</p>
                        <br/>
                        <p>
                            All our electronic deposit methods, including credit cards as well as electronic wallets, are 100% automatically processed and funds reflect in clients’ accounts automatically.
                        </p>
                        <br/>
                        <p>
                            <a href="/open-account" className="text-monstrade-blue">▸ Open a trading account</a>
                        </p>
                        <p>
                            <a href="/open-account" className="text-monstrade-blue">▸ Deposit funds in your trading account in the Members Area</a>
                        </p>

                        <div className="grid grid-cols-3 bg-slate-200 py-6 my-6">
                            <div className="col-span-2 text-right">
                                <p>All our electronic deposit methods, including credit cards as well as electronic wallets, are 100% automatically processed and funds reflect in clients’ accounts automatically.</p>
                            </div>
                            <div className="">
                                <Button content="OPEN AN ACCOUNT" color="bg-monstrade-button-green" py="py-2.5" px="px-3.5"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ZeroCommissionContent;

