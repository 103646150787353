import React from 'react'
//images
import logo1 from '../assets/images/commision-logo.png'
import logo2 from '../assets/images/execution-logo.png'
import icon1 from '../assets/icons/hand-with-money.svg'
import icon2 from '../assets/icons/call-center.svg'
import icon3 from '../assets/icons/rocket-icon.svg'

export const WhoWeAre = () => {
    return (
        <div className='container-full bg-[#F3F5F6]' >
            <div className='container-max mx-auto py-16'>
                {/* <div className='mb-9 text-center' >
                    <h3 className='text-xl' >Protecting Your Funds</h3>
                    <p className='text-lg text-gray-500' >Monstrade pays serious attention to the safety of clients’ funds.</p>
                </div>
                <div className='bg-white flex flex-col'>
                    <div className='text-center py-10'>
                        <p className='text-lg text-gray-500 w-2/3 mx-auto' >
                            We keep all clients’ funds separate from the company’s operating
                            accountsand diversify Company’s assets among reliable global banks
                        </p>
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='flex flex-col p-16 border-t-2 border-r-2'>
                            <div className='mx-auto mb-8' >
                                <img src={logo1} alt="" />
                            </div>
                            <div className='text-center' >
                                <p>Monstrade is a Member of The Financial Commission, which settles disputes
                                    between financial market participants, and provides protection for each
                                    of our clients with the Commission’s compensation fund, for up to €20,000
                                    per case
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col p-16 border-t-2'>
                            <div className='mx-auto mb-8' >
                                <img src={logo2} alt="" />
                            </div>
                            <div className='text-center' >
                                <p>Monstrade is a Member of The Financial Commission, which settles disputes
                                    between financial market participants, and provides protection for each
                                    of our clients with the Commission’s compensation fund, for up to €20,000
                                    per case
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='flex flex-col' >
                    <div className='mx-auto text-center pt-10 pb-5' >
                        <h2 className='text-2xl' >Who We Are</h2>
                    </div>
                    <div className='border-b-2 border-black w-16 mx-auto mb-6'></div>
                    <div className='text-center px-5 text-lg leading-5 mb-20' >
                        <p>
                            Monstrade was founded by veterans of the Skopje forex indistury.
                            With a combined experinece of 50 years, our mission is to create a broker
                            that adheres to a few simple principles:
                        </p>
                    </div>
                    <div className='grid grid-cols-3 gap-24 relative'>
                        <div className='absolute w-full h-full flex flex-col z-0 px-5'>
                            <div className='my-auto border-dashed border-2 border-[#C3C3C3]'></div>
                        </div>

                        <div className='flex flex-col bg-white p-10 rounded-2xl z-20 ' >
                            <div className='mx-auto mb-4' >
                                <img src={icon1} alt="" />
                            </div>
                            <div>
                                <p className='text-xl font-bold text-center mb-3' >Low Prices</p>
                                <p className='text-gray-500' >
                                    Radically lower the price which people pay to trade forex, CFDs and
                                    other financial products.
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col bg-white p-10 rounded-2xl z-20 ' >
                            <div className='mx-auto mb-4' >
                                <img src={icon2} alt="" />
                            </div>
                            <div>
                                <p className='text-xl font-bold text-center mb-3' >Support</p>
                                <p className='text-gray-500' >
                                    Friendly support for any experience level.
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col bg-white p-10 rounded-2xl z-20' >
                            <div className='mx-auto mb-4' >
                                <img src={icon3} alt="" />
                            </div>
                            <div>
                                <p className='text-xl font-bold text-center mb-3' >Technology</p>
                                <p className='text-gray-500' >
                                    Use cutting-edge technology to help deliver a seamless trading wxperience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
