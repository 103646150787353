

const forexNewsContent = () => {
    return (
        [
            { 
                date : "2022-09-30 22:45:00",
                image: "",
                category: "Crypto Review",
                header: "Altcoin outpaces Bitcoin in growth",
                description : "The largest cryptocurrency’s push past $20K this week has turned the market more bullish."
            },
            { 
                date : "2022-09-30 22:45:00",
                image: "",
                category: "Crypto Review",
                header: "Altcoin outpaces Bitcoin in growth",
                description : "The largest cryptocurrency’s push past $20K this week has turned the market more bullish."
            },
            { 
                date : "2022-09-30 22:45:00",
                image: "",
                category: "Crypto Review",
                header: "Altcoin outpaces Bitcoin in growth",
                description : "The largest cryptocurrency’s push past $20K this week has turned the market more bullish."
            },
            { 
                date : "2022-09-30 22:45:00",
                image: "",
                category: "Crypto Review",
                header: "Altcoin outpaces Bitcoin in growth",
                description : "The largest cryptocurrency’s push past $20K this week has turned the market more bullish."
            },
            { 
                date : "2022-09-30 22:45:00",
                image: "",
                category: "Crypto Review",
                header: "Altcoin outpaces Bitcoin in growth",
                description : "The largest cryptocurrency’s push past $20K this week has turned the market more bullish."
            },
            { 
                date : "2022-09-30 22:45:00",
                image: "",
                category: "Crypto Review",
                header: "Altcoin outpaces Bitcoin in growth",
                description : "The largest cryptocurrency’s push past $20K this week has turned the market more bullish."
            },
            { 
                date : "2022-09-30 22:45:00",
                image: "",
                category: "Crypto Review",
                header: "Altcoin outpaces Bitcoin in growth",
                description : "The largest cryptocurrency’s push past $20K this week has turned the market more bullish."
            },
            { 
                date : "2022-09-30 22:45:00",
                image: "",
                category: "Crypto Review",
                header: "Altcoin outpaces Bitcoin in growth",
                description : "The largest cryptocurrency’s push past $20K this week has turned the market more bullish."
            }
        ]
    )
}

export default forexNewsContent;