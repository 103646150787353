

const economicCalenderContent = () => {
    return(
        [
            {
                "data": "2022-09-30 22:45:00",
                "country": "JPN",
                "name": "Daylight Saving Time ends",
                "impact": 1,
                "actual": "-1.2%",
                "forecast": "",
                "previous": "1.4%"
              },
              {
                "data": "2022-09-30 22:45:00",
                "country": "JPN",
                "name": "Daylight Saving Time ends",
                "impact": 2,
                "actual": "-1.2%",
                "forecast": "",
                "previous": "1.4%"
              },
              {
                "data": "2022-09-30 22:45:00",
                "country": "JPN",
                "name": "Daylight Saving Time ends",
                "impact": 3,
                "actual": "-1.2%",
                "forecast": "",
                "previous": "1.4%"
              },
              {
                "data": "2022-09-30 22:45:00",
                "country": "JPN",
                "name": "Daylight Saving Time ends",
                "impact": 1,
                "actual": "-1.2%",
                "forecast": "",
                "previous": "1.4%"
              },
              {
                "data": "2022-09-30 22:45:00",
                "country": "JPN",
                "name": "Daylight Saving Time ends",
                "impact": 1,
                "actual": "-1.2%",
                "forecast": "",
                "previous": "1.4%"
              },
              {
                "data": "2022-10-01 15:30:00",
                "country": "JPN",
                "name": "Daylight Saving Time ends",
                "impact": 1,
                "actual": "-1.2%",
                "forecast": "",
                "previous": "1.4%"
              },
        ]
    )
}

export default economicCalenderContent;