import React from "react";

const PromotionButtons = () => {
    return(
        <>
            <div className="p-6">
                <div class="flex space-x-2 float-left pr-4">
                    <a  href="/open-account"
                        type="button"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                        class="inline-block px-6 py-3 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    >Open Account</a>
                </div>
                <div>
                <a href="/open-account" type="button" class="inline-block px-6 py-2.5 border-2 border-blue-600 text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                >Deposit Account</a>
                </div>
            </div>
            
        </>
    )
}

export default PromotionButtons;