import React from 'react'

export const AffiliateAccounts = () => {
  return (
    <div className='container-full bg-mons-gray-1'>
        <div className='container-max mx-auto py-10'>
            <div className='text-center mb-5' >
                <h3 className='text-3xl mb-5 font-light' >Increase your payouts with Affiliate accounts</h3>
                <p className='text-sm font-light leading-4' >
                    Your clients have an opportunity to trade on special Affiliate accounts 
                    with increased mark-up and you will get profit by 2.5 times more in comparison 
                    with standard accounts. Below are the trading conditions for different types of 
                    affiliate accounts:
                </p>
            </div>
            <div className='grid grid-cols-3 gap-5 w-3/4 mx-auto'>
                <div>
                    <div className='bg-white rounded overflow-hidden' >
                        <div className='text-center bg-[#272727] text-white p-2 rounded text-sm' >
                            <p>Cent Affiliate</p>
                        </div>
                        <table className='table-fixed text-xs text-center w-full'>
                            <tr className='border-b-2'>
                                <td className='border-r-2 py-2' >Execution Mode</td>
                                <td>Market Execution</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Spread</td>
                                <td>from 2.4 pips</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Affiliate commission rate:</td>
                                <td>70%</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Commission for trading volume of 1 million USD</td>
                                <td>No commission</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Available Instruments</td>
                                <td>28 currency pairs, Metals</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>
                    <div className='bg-white rounded overflow-hidden' >
                        <div className='text-center bg-[#272727] text-white p-2 rounded text-sm' >
                            <p>Cent Affiliate</p>
                        </div>
                        <table className='table-fixed text-xs text-center w-full'>
                            <tr className='border-b-2'>
                                <td className='border-r-2 py-2' >Execution Mode</td>
                                <td>Market Execution</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Spread</td>
                                <td>from 2.4 pips</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Affiliate commission rate:</td>
                                <td>70%</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Commission for trading volume of 1 million USD</td>
                                <td>No commission</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Available Instruments</td>
                                <td>28 currency pairs, Metals</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div>
                    <div className='bg-white rounded overflow-hidden' >
                        <div className='text-center bg-[#272727] text-white p-2 rounded text-sm' >
                            <p>Cent Affiliate</p>
                        </div>
                        <table className='table-fixed text-xs text-center w-full'>
                            <tr className='border-b-2'>
                                <td className='border-r-2 py-2' >Execution Mode</td>
                                <td>Market Execution</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Spread</td>
                                <td>from 2.4 pips</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Affiliate commission rate:</td>
                                <td>70%</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Commission for trading volume of 1 million USD</td>
                                <td>No commission</td>
                            </tr>
                            <tr className='border-b-2' >
                                <td className='border-r-2 py-2' >Available Instruments</td>
                                <td>28 currency pairs, Metals</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
