import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import image from '../assets/images/banners/what-are-swap-rates-banner.png'

export const WhatAreForexSwapRates = () => {
    return (
        <ContainerWithBGImage image={image} >
            <div className='m-auto' >
                <div className='text-white text-center mb-10'>
                    <h3 className='text-4xl' >What Are Forex Swap Rates?</h3>
                </div>
                <div className='grid grid-cols-2 text-white'>
                    <div className='pr-16'>
                        <p>The swap charges in forex or rollover interest rates is the net interest
                            return that a trader accumulates on a currency position held overnight.
                            This fee is charged when the trader borrows one currency to buy another,
                            as part of forex trading. <br /><br />
                            For instance, if you are buying EUR/USD, you might borrow in US Dollars
                            and buy Euros with the amount. In doing so, you will need to pay interest
                            on the borrowed US Dollars and earn interest on the Euros you bought.<br /><br />
                            The net interest fee is calculated based on the difference in interest
                            rates of the two traded currencies. If the forex swap rate calculation
                            is positive, the trader gains, while if it is negative, it is a cost for the trader.
                        </p>
                    </div>
                    <div className='pl-16' >
                        <p className='mb-6' >
                            Normally, deposit and credit rates on the same currency differ. The credit
                            rate is usually higher than the deposit rate. That is why forex swap rates
                            for long and short positions on the same currency pair are different. The
                            “storage” for holding a position overnight depends on several factors, such as:
                        </p>
                        <ul className='list-disc' >
                            <li className='mb-3' >The current interest rate differential between two currencies</li>
                            <li className='mb-3'>Currency pair price fluctuations</li>
                            <li className='mb-3'>Behaviour of the forward market</li>
                            <li className='mb-3'>Swap points of the counterparty</li>
                            <li className='mb-3'>Position of the liquidity provider in the market hierarchy</li>
                            <li className='mb-3'>Difference between forex swaps for long and short positions</li>
                        </ul>
                    </div>
                </div>
            </div>
        </ContainerWithBGImage>
    )
}
