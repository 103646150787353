

const forexNewsTweetContent = () => {

    return(
        [
            {userName: "MonsTrade Forex Broker",
            account: "MonsTradeFX",
            date: "20h",
            text: "EURUSD escapes From the Channel Down Formation",
            source: "monstrade.com",
            image: "",
            like: 5,
            comment: 2,
            retweet: 1
            },
            {userName: "MonsTrade Forex Broker",
            account: "MonsTradeFX",
            date: "20h",
            text: "EURUSD escapes From the Channel Down Formation",
            source: "monstrade.com",
            image: "",
            like: 5,
            comment: 2,
            retweet: 1
            },
            {userName: "MonsTrade Forex Broker",
            account: "MonsTradeFX",
            date: "20h",
            text: "EURUSD escapes From the Channel Down Formation",
            source: "monstrade.com",
            image: "",
            like: 5,
            comment: 2,
            retweet: 1
            },
        ]
    )
}

export default forexNewsTweetContent;