import React from 'react';
import BlueLine from "../assets/icons/blue-line.svg"

const Crypto3Content = () => {
    return(
        <>
            <div className='container-full py-8 bg-slate-200'>
                <div className='container-max mx-auto'>
                    <div className='text-center text-2xl'>
                        <p>How to invest in cryptocurrencies at Monstrade?</p>
                    </div>
                    <div className='grid grid-cols-3 py-10'>
                        <div className='grid-rows-3'>
                            <div>
                                <p className='text-6xl font-bold'>1</p>
                                <img className='py-3' src={BlueLine} alt="" />
                            </div>
                            <div>
                                <p className='font-semibold'>Register an account</p>
                            </div>
                            <div className='w-64'>
                                <p className='text-sm font-light'>Open a trading account in MT5</p>
                            </div>
                        </div>
                        <div className='grid-rows-3'>
                            <div>
                                <p className='text-6xl font-bold'>2</p>
                                <img className='py-3' src={BlueLine} alt="" />
                            </div>
                            <div>
                                <p className='font-semibold'>Deposit funds</p>
                            </div>
                            <div className='w-64'>
                                <p className='text-sm font-light'>To start trading, you have to have as little as 10 USD on your trading account.</p>
                            </div>
                        </div>
                        <div className='grid-rows-3'>
                            <div>
                                <p className='text-6xl font-bold'>3</p>
                                <img className='py-3' src={BlueLine} alt="" />
                            </div>
                            <div>
                                <p className='font-semibold'>Start trading!</p>
                            </div>
                            <div className='w-64'>
                                <p className='text-sm font-light'>You can invest in cryptocurrencies 24/7 from anywhere in the world.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Crypto3Content;