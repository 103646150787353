import React from 'react'
import styles from './SpreadSearch.module.css'

export const SpreadSearch = ({setSearchParam : setSearchParam}) => {
    return (
        <div className="flex justify-center">
            <div className="mb-3 xl:w-96">
                <input onChange={(event) => { setSearchParam(event.target.value)}}
                    type="search"
                    className="form-control block w-full px-3
                            py-2.5 text-base font-normal text-gray-700 bg-[#D9D9D9]
                            bg-clip-padding border border-solid border-gray-300
                            rounded-lg transition ease-in-out m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                    id="exampleSearch"
                    placeholder="Search a symbol"

                />
            </div>
        </div>
    )
}
