import React from "react";

const CashbackEnd = () => {
    return(
        <>
            <div className="p-6">
                <p>Other bonus terms and conditions are specified in the <a href="" className="font-semibold text-monstrade-blue">Bonus Terms and Conditions.</a></p>
                <br/>
                <p>To start receiving cash back from Monstrade, contact <a href="" className="font-semibold text-monstrade-blue">customer support.</a></p>
                <br/>
                <p className="font-bold">Make your trading even more profitable!</p>
            </div>
        </>
    )
}

export default CashbackEnd;