import React from 'react'
import { BgWhiteBlueButton } from '../Buttons/BgWhiteBlueButton';
import { useState } from 'react';
import { useEffect } from 'react';

export const ContainerWithBGImage = ({ image,
    imageBgColor,
    children,
    landing,
    itemAlingment,
    textAlingment,
    containerSlice }) => {
    const [isLoaded,setIsLoaded] = useState(false)

    let img = new Image();
    img.src = image
    useEffect(() => {
        
    },[isLoaded])
    return (
        <div className='container-full relative overflow-hidden' style={{ height: `${((img.height / 1440) * 100).toFixed(2)}vw` }}>
            <div className={`absolute z-[-1] h-full  ${imageBgColor ? `bg-[${imageBgColor}]` : ''}`} >
                <img className={`lg:w-full lg:min-w-full md:min-w-[120%] min-w-[250%] ${imageBgColor ? `bg-[${imageBgColor}]` : ''}`}
                    src={img.src}
                    alt="monstrade"
                    onLoad={() => { setIsLoaded(true)}}
                    />
            </div>
            <div className='container-max m-auto h-full flex'>
                {landing &&
                    <div className='flex'>
                        <div className={`
                                        ${!itemAlingment ? 'm-auto' : ''}
                                        ${itemAlingment === 'left' ? 'my-auto' : ''}
                                        ${itemAlingment === 'lefttop' ? 'mt-24' : ''}
                                         ${textAlingment === 'left' ? '' : 'text-center'} 
                                         w-${containerSlice ? containerSlice : '2'}/4`}>
                            <h1 className='text-white text-3xl font-bold mb-6'>{landing.h1}</h1>
                            <p className='text-white text-lg leading-5 mb-12'>{landing.p}</p>
                            {landing.btn &&
                                <BgWhiteBlueButton text={landing.btn.text} link={landing.btn.link} />
                            }
                        </div>
                    </div>
                }
                {children}
            </div>
        </div>
    )
}
