import React from "react";
import Button from "./Button"

const Table = () => {
    return(
        <div className="container mx-auto my-16">
            <table className="table-auto mx-auto font-extralight">
                <thead>
                    <tr>
                    <th className="font-extralight">Symbols</th>
                    <th className="pr-16"></th>
                    <th className="font-extralight px-6">Bid</th>
                    <th className="font-extralight px-6">Ask</th>
                    <th className="font-extralight px-6">Spread</th>
                    <th></th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="font-bold">EUR/GBP</td>
                        <td>€/£</td>
                        <td className="px-6">0.6161</td>
                        <td className="px-6">0.6161</td>
                        <td className="px-6">0.6161</td>
                        <td className="px-2"><Button content="BUY" color="bg-monstrade-button-green" py="py-0.33" px="px-2.5"/></td>
                        <td><Button content="SELL" color="bg-monstrade-button-red" py="py-0.33" px="px-3.5"/></td>
                    </tr>
                    <tr>
                        <td className="font-bold">USD/GBP</td>
                        <td>$/£</td>
                        <td className="px-6">0.6161</td>
                        <td className="px-6">0.6161</td>
                        <td className="px-6">0.6161</td>
                        <td><Button content="BUY" color="bg-monstrade-button-green" py="py-0.33" px="px-2.5"/></td>
                        <td><Button content="SELL" color="bg-monstrade-button-red" py="py-0.33" px="px-3.5"/></td>
                    </tr>
                    <tr>
                        <td className="font-bold">EUR/USD</td>
                        <td>€/$</td>
                        <td className="px-6">0.6161</td>
                        <td className="px-6">0.6161</td>
                        <td className="px-6">0.6161</td>
                        <td><Button content="BUY" color="bg-monstrade-button-green" py="py-0.33" px="px-2.5"/></td>
                        <td><Button content="SELL" color="bg-monstrade-button-red" py="py-0.33" px="px-3.5"/></td>
                    </tr>
                    <tr>
                        <td className="font-bold">USD/JPY</td>
                        <td>$/¥</td>
                        <td className="px-6">0.6161</td>
                        <td className="px-6">0.6161</td>
                        <td className="px-6">0.6161</td>
                        <td><Button content="BUY" color="bg-monstrade-button-green" py="py-0.33" px="px-2.5"/></td>
                        <td><Button content="SELL" color="bg-monstrade-button-red" py="py-0.33" px="px-3.5"/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Table;