import React from "react";
import ContentForexNews from "../containers/Content.ForexNews";
import LandingBannerForexNews from "../containers/LandingBanner.ForexNews";

const ForexNews = () => {
    return(
        <>
            <LandingBannerForexNews />
            <ContentForexNews />
        </>
    )
}

export default ForexNews;