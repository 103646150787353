import React from 'react'
//component
import { PricingContainer } from '../components/Containers/PricingContainer';
import { CompetitiveForexSwapRates } from '../containers/CompetitiveForexSwapRates';
import { AboutForexSwapRates } from '../containers/AboutForexSwapRates';
import { WhatAreForexSwapRates } from '../containers/WhatAreForexSwapRates';
import { ForexSwapWork } from '../containers/ForexSwapWork';
import { CalculationOfSwapRates } from '../containers/CalculationOfSwapRates';
import { SwapCharges } from '../containers/SwapCharges';
import { SwapRatesInMT5 } from '../containers/SwapRatesInMT5';
import { StartTrading } from '../containers/StartTrading';
import { SwapPoints } from '../containers/SwapPoints';
//images
import bgImage from '../assets/images/banners/forex-swap-rates-banner.png';


export const SwapRates = () => {
    const defaultStyle = {
        composition: {
            margin: "center",
            alignment: "center",
            lgW: "lg:w-full"
        },
        header: {
            size: "5"
        },
        text: {

        },
        container: {
            h: "md:h-[46.5vw]",
            maxH: "max-h-[474px]"
        },
        image: {

        },
        button: {
            color: "white",
            textColor: "monstrade-blue",
            border: true,
            borderColor: "monstrade-blue"
        }
    }
    const content = {
        header: "Forex Swap Rates",
        text: "Monstrade understands that competitive swap rates are important to any trader.",
        button: "Start Trading"
    }
    return (
        <>
            <PricingContainer image={bgImage} defaultStyle={defaultStyle} content={content}/>
            <CompetitiveForexSwapRates />
            <AboutForexSwapRates />
            <WhatAreForexSwapRates />
            <ForexSwapWork />
            <CalculationOfSwapRates />
            <SwapCharges />
            <SwapRatesInMT5 />
            <SwapPoints />
            <StartTrading />
        </>
    )
}
