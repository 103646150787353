
const accountTypeContent = () => {
    return (
        [
            {
                id: 0,
                recomended: false,
                command: '',
                header: 'Classic Account',
                description: 'A spesific account type for \
                            trading through the Prime Account \
                            web platform and mobile app of the \
                            same name.',
                table: [
                    { column: 'First Deposit', value: '100 USD' },
                    { column: 'Execution Type', value: 'Market Execution' },
                    { column: 'Spreads', value: 'Floating from 0 points' },
                    { column: 'Instruments', value: 'Over 12,000 stocks, Indices Forex, ETFs, CFDs' },
                    { column: 'Bonuses', value: 'Not Available' },
                    { column: 'Platforms', value: 'MT5 Trader' }
                ]
            },
            {
                id: 1,
                recomended: true,
                command: 'CLIENTS’ CHOICE',
                header: 'Prime Account',
                description: 'A spesific account type for \
                        trading through the Prime Account \
                        web platform and mobile app of the \
                        same name.',
                table: [
                    { column: 'First Deposit', value: '100 USD' },
                    { column: 'Execution Type', value: 'Market Execution' },
                    { column: 'Spreads', value: 'Floating from 0 points' },
                    { column: 'Instruments', value: 'Over 12,000 stocks, Indices Forex, ETFs, CFDs' },
                    { column: 'Bonuses', value: 'Not Available' },
                    { column: 'Platforms', value: 'MT5 Trader' }
                ]
            },
            {
                id: 2,
                recomended: false,
                command: 'CLIENTS’ CHOICE',
                header: 'ECN Account',
                description: 'A spesific account type for \
                            trading through the Prime Account \
                            web platform and mobile app of the \
                            same name.',
                table: [
                    { column: 'First Deposit', value: '100 USD' },
                    { column: 'Execution Type', value: 'Market Execution' },
                    { column: 'Spreads', value: 'Floating from 0 points' },
                    { column: 'Instruments', value: 'Over 12,000 stocks, Indices Forex, ETFs, CFDs' },
                    { column: 'Bonuses', value: 'Not Available' },
                    { column: 'Platforms', value: 'MT5 Trader' }
                ]
            }
        ]
    )
}


export default accountTypeContent;