import React from 'react'

export const PublicHolidayHours = () => {
    const table1 = {
        header : ["Symbol", "Thursday 24th Nov", "Friday 25th Nov" ],
        content : [
            ["COCOA", "CLOSED", ""],
            ["CORN", "CLOSED", "Close 20:05" ],
            ["SOYBEANS", "CLOSED", "Close 20:05" ],
            ["WHEAT", "CLOSED", "Close 20:05" ],
            ["XTI/USD", "CLOSE 20:15", "Close 20:30" ],
        ]
    }
    const table2 = {
        header : ["Symbol", "Current Month", "Next Month", "Roll Date"],
        content : [
            ["WTI" , "Dec-22", "Dec-22", "17th Nov 22 (16th of Nov 22'EOD)"],
            ["USDX" , "Dec-22", "Mar-23", "16th Dec 22 (15th of Dec 22'EOD)"],
            ["VIX" , "Nov-22", "Dec-22", "16th Nov 22 (15th of Nov 22'EOD)"]
        ]
    }
    return (
        <div>
            <div className='my-16'>
                <h2 className='text-center mb-10 text-4xl font-bold' >Public Holiday Trading Hours !</h2>
                <p>
                    Forex trading hours are impacted by public holidays and other events.
                    The alternate trading hours vary across global financial markets due
                    to the fact that some countries have additional public holidays. In
                    Australia, this includes events such as Australia Day, ANZAC Day, Good
                    Friday, Easter Monday and Boxing Day.<br /><br />
                    Trading of specific instruments across <span className='font-bold' >Forex</span> and <span className='font-bold' >CFDs</span> are impacted as a
                    result. Traders should be aware that trading day hours may vary on MetaTrader
                    5. Please check the tables below which will be regularly updated in advance
                    of any upcoming holidays.
                </p>
            </div>
            <div className='mb-16' >
                <div className='text-center mb-6' >
                    <h3 className='text-2xl font-semibold' >Holiday Hours</h3>
                    <p className='text-xl text-gray-500' >(GMT +2)</p>
                </div>
                <table className='table-fixed w-full text-center' >
                    <thead className='h-[65px] text-xl ' style={{background: 'linear-gradient(270deg, #00C7FF -4.6%, #0052FF 101.41%)'}}>
                        { table1.header.map((item) => { return <th className='text-white' >{item}</th>})}
                    </thead>
                    <tbody className='font-semibold' >
                        {table1.content.map( (line, index) => {
                            return(
                                <tr className={`h-[65px] ${index % 2 === 0 ? '' : 'bg-mons-gray-1'}`} >
                                    {line.map((item) => { return <td>{item}</td>})}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='mb-10' >
                <div className='text-center mb-8' >
                    <h3 className='text-2xl' >Contract Rollover</h3>
                </div>
                <table className='table-fixed w-full text-center' >
                    <thead className='h-[65px] text-xl' style={{background: 'linear-gradient(270deg, #00C7FF -4.6%, #0052FF 101.41%)'}}>
                        { table2.header.map((item, index) => { return <th className={`text-white 
                                                                                    ${index === 0 ? 'rounded-l-2xl' : ''}
                                                                                    ${index+1 === table2.header.length ? 'rounded-r-2xl': '' }`} >{item}</th>})}
                    </thead>
                    <tbody className='font-semibold' >
                        {table2.content.map( (line, index) => {
                            return(
                                <tr className={`h-[65px] ${index % 2 === 0 ? '' : 'bg-mons-gray-1'}`} >
                                    {line.map((item) => { return <td>{item}</td>})}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
