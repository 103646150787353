import React from "react";
import accountTypeForexContent from "../features/content/accountTypeForex.content";
import AccountTypeCardSlider from "../components/Slider/AccountTypeCarousel";
import Button from "../components/Button"

const accountTypes = accountTypeForexContent()

const AvailableAccountTypes = () => {
    return(
        <>
            <div className="container-full banner-gradient">
                <div className="container-max mx-auto">
                    <div className="py-20 text-center text-white">
                        <p className="text-3xl">Available on the following account types</p>
                        <p className="text-base pt-6">
                        Monstrade offers to its clients several types of accounts for Forex investment 
                        and the clients can choose the one which is the most suitable for them.
                        </p>
                    </div>
                    <div className="text-center text-sm">
                        <p className="text-cyan-400">
                            <a href="#">All types of accounts</a>
                        </p>
                    </div>
                    <div className="py-24">
                        <AccountTypeCardSlider  accountTypes={accountTypes}/>
                    </div>
                </div>
                <div className="absolute w-full -mt-5">
                    <Button content="TRADE FOREX" color="bg-monstrade-button-green" py="py-2" px="px-6" isArrow={true} /> 
                </div>
            </div>
        </>
    )
}

export default AvailableAccountTypes;