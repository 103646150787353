import React from "react";
import bgImage from "../assets/images/whymonstrade.png"
import checkIcon from "../assets/images/monstrade-check.svg"

const content = [
    "Client Funds",
    "100+ Products ",
    "24/7  Customer Support",
    "Low Spreads",
    "Personal Account Managers",
    "Trading Experience",
]

const WhyMonstrade = () => {
    return (
        <>
            <div className="container-full md:h-[100vw] h-auto  md:max-h-[810px]  relative mx-auto">
                <div className="container-max h-full mx-auto relative">
                    <div className="md:grid md:grid-cols-2 grid-rows-2 pt-20">
                        <div className="flex">
                            <div className="mx-auto md:text-left text-center">
                                <div className="py-2" >
                                    <p className="text-2xl font-light" >Why Monstrade?</p>
                                </div>
                                <div className="py-2" >
                                    <p className="text-3xl" >It’s Safe, Simple and Fast!</p>
                                </div>
                            </div>
                        </div>
                        <div className="grid-rows-7 flex">
                            <div className="m-auto" >
                                {content.map((item, index) => {
                                    return (
                                        <div className="grid-rows-2 py-2 flex transition-opacity" key={index}>
                                            <div>
                                                <div className="p-3">
                                                    <img src={checkIcon} alt="" />
                                                </div>
                                            </div>
                                            <div className="flex" >
                                                <p className="text-xl m-auto" >{item}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="md:absolute bottom-0 z-[-1] w-[50vw] max-w-[750px] m-auto">
                        <img src={bgImage} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyMonstrade;