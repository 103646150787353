import React from "react";
import tradingStock from "../features/content/tradingStock.content";



const StockContentTable = () => {

    const content = tradingStock()

    return(
        <>
            <div className="container-full">
                <div className="container-max mx-auto pt-16">
                    <div className="text-center">
                        <p className="text-4xl font-light">Achieve your investment goals together with Monstrade</p>
                        <p className="py-8 text-gray-500">In Monstrade, you have an opportunity to invest in more than 300+ real stocks of companies in all over the world.</p>
                    </div>
                    <div className="grid grid-cols-4 pt-6">
                        <div>
                            <table>
                                <tbody>
                                    <td className="pr-4 text-right text-gray-700 text-sm">
                                        <tr className="h-[40px]"></tr>
                                        {content.meta.map((item) => {
                                            return(<tr className="border-b-2 h-8 border-t-gray-200">{item}</tr>)
                                        })} 
                                    </td>
                                </tbody>
                            </table>
                        </div>
                        
                            {content.data.map((item) => {
                            return(<>
                                <div>
                                    <table className="w-11/12">
                                        <tbody>
                                            <td className="bg-slate-50 text-sm">
                                                <tr className="text-xl h-[40px] text-gray-600 font-semibold">{item.header}</tr>
                                                {item.lines.map((line)=>{
                                                    return(<tr className="border-b-2 h-8 text-gray-700 border-t-gray-300">{line}</tr>)
                                                })} 
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                            </>)
                            })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default StockContentTable


