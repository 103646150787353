import React from "react";
import LandingBannerEconomicCalender from "../containers/LandingBanner.EconomicCalender";
import image from "../assets/images/banners/economic-calender-banner.png"
import ContentEconomicCalender from "../containers/Content.EconomicCalender";
import EconomicCalenderContainer from "../containers/EconomicCalendar.container";

const EconomicCalender = () => {
    
    return (
        <>
            <LandingBannerEconomicCalender image={image} />
            <EconomicCalenderContainer />
            <ContentEconomicCalender />
        </>
    )
}

export default EconomicCalender;