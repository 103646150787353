

const leverageContent = () => {
    return [
        {
            name: "FOREX, METALS",
            conditions : [],
            meta: [
                "Equity","Standart","Fixed","ECN"
            ],
            data: [
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: 1000,
                    },
                    standart : 3000,
                    fixed: 3000,
                    ecn: 3000
                },
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: 1000,
                    },
                    standart : 3000,
                    fixed: 3000,
                    ecn: 3000
                },
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: 1000,
                    },
                    standart : 3000,
                    fixed: 3000,
                    ecn: 3000
                },
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: 1000,
                    },
                    standart : 3000,
                    fixed: 3000,
                    ecn: 3000
                },
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: 1000,
                    },
                    standart : 3000,
                    fixed: 3000,
                    ecn: 3000
                },
            ]
        },
        {
            name: "INDICES, COMMODITIES, BONDS",
            conditions : [],
            meta: [
                "Equity","Leverage"
            ],
            data: [
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: 250000,
                    },
                    leverage : 100
                },
                {
                    equity : {
                        equityLow: 250000,
                        equityHigh: 500000,
                    },
                    leverage : 50
                },
                {
                    equity : {
                        equityLow: 500000,
                        equityHigh: 0,
                    },
                    leverage : 20
                }
            ]},
        {
            name : "CRYPTOCURRENCIES",
            conditions : [],
            meta: [
                "Equity","BTC/USD","Other Cryptocurrencies"
            ],
            data : [
                {
                    equity : {
                        equityLow: 0,
                        equityHigh: 250000,
                    },
                    btcusd : 10,
                    other : 5
                },
                {
                    equity : {
                        equityLow: 250000,
                        equityHigh: 0,
                    },
                    btcusd : 10,
                    other : 5
                }
            ]
        },
        {
            name : "STOCKS",
            conditions : ["Maximum leverage 1:10"],
            meta: [],
            data : []
        }
    ]
}

export default leverageContent;