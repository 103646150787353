import React from 'react';
import ArrowIcon from '../assets/icons/arrow.png'

const Button = (props) => {
    return(
        <center>
            <a href='/open-account' className={`${props.color} ${props.py} ${props.px} text-white font-bold rounded`}>
                {props.content} {props.isArrow ? <img className="ml-2 mb-1 inline" src={ArrowIcon} alt="arrowIco" /> : ''}
            </a>
        </center>
    )
    
}

export default Button;