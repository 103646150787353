import React from "react";
import tempImage from "../../assets/images/rectangle.png"

const ForexNewsCard = ({item:item}) => {
    return(
        <div>
            <div className="w-full mb-8">
                <div>
                    <img src={tempImage} alt="" />
                </div>
                <div className="pt-1" >
                    <p className="text-gray-400 text-sm">{item.date}</p>
                </div>
                <div className="mb-2" >
                    <p className="text-monstrade-blue font-semibold" >{item.category}</p>
                </div>
                <div className="mb-2" >
                    <h3 className="font-semibold text-lg leading-4" >{item.header}</h3>
                </div>
                <div className="mb-2" >
                    <p className="text-gray-400 text-sm leading-4" >{item.description}</p>
                </div>
            </div>
        </div>
    )
}

export default ForexNewsCard;