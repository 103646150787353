import React from 'react'
import { VideoListContainer } from '../containers/VideoListContainer'
import { BgWhiteBlueButton } from '../components/Buttons/BgWhiteBlueButton'
export const VideoTutorial = () => {
  return (
    <div>
      <VideoListContainer />
      <div className='container-full bg-mons-blue-1'>
        <div className='container-max flex mx-auto'>
          <div className='m-auto flex py-3' >
            <h3 className='text-white mr-5 text-3xl font-semibold my-auto' >Ready for trading?</h3>
            <BgWhiteBlueButton text={'START NOW'} link={'/'} />
          </div>
        </div>
      </div>
    </div>
  )
}
