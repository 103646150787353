import React from "react";

const ContentEconomicCalender = () => {
    return (
        <>
            <div className="container-full" >
                <div className="container-max m-auto">
                    <div className="py-12" >
                        <h2 className="text-monstrade-blue text-xl leading-10 text-semibold" >What is the Forex economic calendar, and how to use it in trading?</h2>
                        <br />
                        <p >The Forex and stock market economic calendar summarizes news and other
                            important publications to be published during trading sessions. These
                            are the fundamental data that affect the prices of small and large markets.
                            This is why the economic news calendar is one of the first-tier tools
                            for real time analysis if you decide to trade foreign exchange.
                        </p>
                        <br />
                        <h2 className="text-monstrade-blue text-xl leading-10 text-semibold">How to use the economic calendar?</h2>
                        <br />
                        <p >In addition to its unique importance for fundamental analysis and forecasting,
                            as mentioned above, the Forex live economic calendar serves as an indicator
                            for news trading in the Forex market. If you are engaged in exchange trading
                            and seek advice regarding opening positions, the calendar can serve as a reliable
                            source of information.For this purpose, there is a well-tried algorithm for using
                            the Forex calendar, suitable for all investors.
                        </p>
                        <br />
                        <ol className="list-decimal list-inside" >
                            <li className="pb-4" >At the beginning of a new trading day (or the day before), a trader opens the
                                economic calendar and notes the important macroeconomic news. Of paramount importance
                                is the news marked in the calendar with three exclamation points.
                            </li>
                            <li className="pb-4" >
                                In case you are experienced enough, they perform the analysis of previous macroeconomic
                                data, as well as the reaction of the main market players and how it reflects in the
                                dynamics of asset quotes. It is also important to get acquainted with the opinion of
                                market experts and more experienced traders, get general market commentary and advice
                                from independent experts on the Internet, including FXOpen blog.
                            </li>
                            <li className="pb-4"  >
                                Immediately after the news release and its evaluation in the economic calendar, the
                                trader reconciles the released data with the forecast, and makes a decision to invest
                                money according to the conclusions made. Please note that you need to carefully consider
                                your investment objectives under any circumstances.
                            </li>
                        </ol>
                        <br />
                        <ul className="list-disc font-extralight" >
                            <li className="pb-4" >In case the news is positive, i.e. its value is higher than the forecast median, a
                                decision can be made to buy the currency, the quotes of which are most sensitive to the
                                macroeconomic news content.
                            </li>
                            <li className="pb-4" >In case the news is negative, i.e. its value is lower than the forecast median a decision
                                can be made to sell the currency, the quotes of which are most sensitive to the macroeconomic news content.
                            </li>
                        </ul>
                        <br />
                        <p>
                            However, news trading is not the only way to use the economic calendar. Very often, published
                            news is of great importance not only for Forex trading, but also for the world economy.
                            There are events like changes in the level of discount rates made by central banks (regulators),
                            or the release of statistics on inflation, the number of employees, etc. Such data, if published,
                            is able to channel the quotes of Forex market assets, stock and commodity markets into long-term
                            trends. Such manifestations arise directly or indirectly, and fall into the domain of interest
                            of investors who can use the economic calendar for long-term forecasting and investments.
                        </p>
                        <br />
                        <h2 className="text-monstrade-blue text-xl leading-10 text-semibold" >What to pay attention to when using the Forex economic calendar?</h2>
                        <br />
                        <p>
                            The following data may be crucial when you explore the economic calendar:
                        </p>
                        <br />
                        <ul className="list-disc font-extralight" >
                            <li className="pb-4">The time of publication according to the trader's location.</li>
                            <li className="pb-4">Origin of the news (in which country the event occurred and what
                                is the source of publication). For example, if USD/CAD is one of
                                the pairs you trade, you should pay attention to the Canadian and
                                US economic statistics.
                            </li>
                            <li className="pb-4">The importance of the news, namely, the impact on the relevant
                                asset. If the impact is low, the price of the relevant currency is
                                likely to be unaffected. On the other hand, if important news is
                                published, there will be high volatility in the financial asset.
                            </li>
                            <li className="pb-4">
                                The nature and wording of the news will make it possible to judge
                                the nature of this event, be it Mario Draghi speech or the U.S.
                                unemployment statistics.
                            </li>
                            <li className="pb-4">
                                Results and forecast statistics: historical data will allow you to
                                assess the evolution of events and compare them with the market situation
                                at the moment, and maybe even take a different perspective on the initial
                                investment.
                            </li>
                        </ul>
                        <br />
                        <p className="font-semibold" >
                            Note that reliance on such information should be somewhat limited and not be the
                            only source of decision-making. Risks associated with foreign trading can never be
                            reduced to zero, no matter what analysis method you use. Always keep in mind the
                            amount you can afford to lose if you decide to open a trade according to news, and
                            that Forex economic calendar contents do not constitute investment advice.
                        </p>
                    </div>
                    <div className="flex w-full text-white pt-12" >
                        <div className=" w-full py-6 flex" >
                            <a href="/about/contact-us/contact" className="text-center bg-monstrade-blue hover:bg-monstrade-green text-lg w-full h-full py-5 rounded-md" ><span className="font-bold">Need help?</span> Speak with us</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContentEconomicCalender;