import React from "react";
import EconomicCalenderComponent from "../components/Calendars/EconomicCalendar.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EconomicEvents from "../components/Tables/EconomicEvents";

const EconomicCalenderContainer = () => {
    return (
        <>
            <div className="container-full">
                <div className="container-max mx-auto">
                    <div className="grid grid-cols-3 pt-10">
                        <div className="w-full flex col-span-2">
                            <div className="mr-6">
                                <button className="border-2 border-monstrade-gray rounded-sm" >
                                    <span className="mx-1">
                                        {/* <FontAwesomeIcon icon={["far", "fa-calendar"]} size="lg" className="text-white"/> */}
                                        <FontAwesomeIcon icon="fa-regular fa-calendar" />
                                    </span>
                                    <span className="mx-1">Sat, Oct 29th, 2022 - Tue, Nov 1st, 2022</span>
                                    <span className="mx-1">
                                        <FontAwesomeIcon icon="fa-solid fa-caret-down" />
                                    </span>
                                </button>
                            </div>
                            <div>
                                <button>
                                    <span className="mr-2">
                                        <FontAwesomeIcon icon="fa-regular fa-clock" />
                                    </span>
                                    <span className="font-semibold" >21:16 GMT +03:00</span>
                                    <span>
                                        <FontAwesomeIcon icon="fa-solid fa-angle-down" />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="w-full flex col-span-1 justify-end" >
                            <div className="flex" >
                                <div className="px-1">
                                    <button className="my-auto w-[40px] h-[40px]" >
                                        <FontAwesomeIcon icon="fa-solid fa-circle-info" size="xl" />
                                    </button>
                                </div>
                                <div className="px-1">
                                    <button className="w-[40px] h-[40px] bg-slate-300 p-1" >
                                        <FontAwesomeIcon icon="fa-solid fa-download" size="xl"/>
                                    </button>
                                </div>
                                <div className="px-1" >
                                    <button className="w-[40px] h-[40px] bg-slate-300 p-1" >
                                        <FontAwesomeIcon icon="fa-solid fa-gear" size="xl"/>
                                    </button>
                                </div>
                                <div className="px-1" >
                                    <button className="bg-[#304c70] h-[40px] font-semibold text-2xl text-white px-2" >Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EconomicCalenderComponent />
            <EconomicEvents />
        </>
    )
}

export default EconomicCalenderContainer;