import React from 'react'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
//images
import bgImage from '../assets/images/banners/contact-us-banner.png'
import { ContactInfo } from '../containers/ContactInfo'
export const ContactUs = () => {
    const landing = {
        h1: 'Trade with a Full Support System',
        p: 'No matter the question, we the answer. No matter the problem we have the solution. '+
            'Our award-winning team is the support system you need to build your trading career:',
        // btn: {
        //     text : 'Speak With Us',
        //     link : '/'
        // }
    }
  return (
    <div>
        <ContainerWithBGImage 
            image={bgImage}
            imageBgColor={'#0052FF'}
            landing={landing}/>
        <ContactInfo />
    </div>
  )
}
