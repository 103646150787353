import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import Iframe from 'react-iframe'

export const GoogleMapContainer = () => {
  const containerStyle = {
    width: '350px',
    height: '350px'
  };

  const center = {
    lat: -3.745,
    lng: -38.523
  };
  return (
    <div>
      <Iframe 
        url='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.3773876721275!2d21.412960315833924!3d41.99217567921336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13541439d105273d%3A0x6f8554f6e9ffa66f!2sKozara%2C%2068%2C%20Skopje%201000%2C%20North%20Macedonia!5e0!3m2!1sen!2str!4v1669657235203!5m2!1sen!2str' 
        styles={containerStyle}
        />
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2965.3773876721275!2d21.412960315833924!3d41.99217567921336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13541439d105273d%3A0x6f8554f6e9ffa66f!2sKozara%2C%2068%2C%20Skopje%201000%2C%20North%20Macedonia!5e0!3m2!1sen!2str!4v1669657235203!5m2!1sen!2str" width="600" height="450" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
      {/* <LoadScript googleMapsApiKey="AIzaSyCyMIyvddrUXrwhDxEr1cP_pe-qLX0VaKM">
      <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
        ></GoogleMap>
      </LoadScript>   */}
    </div>
  )
}
