import React from "react";

const AccountTransferContent = () => {
    return(
        <>
            <div className="p-8 text-gray-700">
                <p className="text-gray-400">September 12, 2022</p>
                <br/>
                <p>If you have open positions with another broker, simply transfer them to Monstrade and get the most attractive trading terms for maximum profit.</p>
                <br/>
                <p className="font-semibold">Switch to Monstrade and get the best trading conditions:</p>
                <ul className="list-disc">
                    <li>More than 500 financial instruments: currency pairs, stocks, bonds, metals, indices, commodities;</li>
                    <li>Spreads as low as 0.2 pips;</li>
                    <li>Order execution as fast as 0.03 sec.;</li>
                    <li>0% commission on deposits;</li>
                    <li>Two trading platforms: MT5;</li>
                </ul>
                <br/>
                <p className="font-semibold text-xl">Bonus terms and conditions:</p>
                <ul className="list-disc">
                    <li>the 15% bonus on every deposit over $100 is paid in the form of non-withdrawable credits, which are added to the balance and can be used in all trading operations;</li>
                    <li>the promotion is available for trading forex instruments on Standard and Fixed accounts, in MetaTrader 5 platform only;</li>
                    <li>the maximum amount of bonus funds per client – $5,000;</li>
                    <li>this bonus offer is not cumulative with any other Monstrade’ bonuses and promotions;</li>
                    <li>bonus for the reporting period will be canceled, if a client fully or partially withdraws his initial deposit, the above-mentioned bonus has been calculated from;</li>
                    <li>profit received from trading using the bonus funds can be withdrawn without limitations;</li>
                    <li>other bonus terms and conditions are governed by the Monstrade’ <a href="" className="underline text-monstrade-blue">general Bonus Terms and Conditions regulations. Open account Deposit account</a> </li>
                </ul>
            </div>
        </>
    )
}

export default AccountTransferContent;